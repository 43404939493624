import React from 'react';
import { Avatar ,Tooltip} from 'antd';

const AvatarGroup = ({ admins }) => {
  return (
    
      <Avatar.Group maxCount={4}>
        {admins.map((admin) => (

            <Tooltip  color='#86d694' title={admin.first_name} placement="top">
              <Avatar className="cursor" size='large' key={admin.id} src={admin.image|| require('../assets/images/userimg.png')} alt={admin.name} />
              <div style={{marginTop:-10, marginLeft:30 ,zIndex:999}} className='z-50 absolute rounded-full h-[10px] w-[10px] bg-lightgreen'></div>
          </Tooltip>
        ))}
      </Avatar.Group>

  );
};

export default AvatarGroup;