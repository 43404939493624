import React, { useState, useEffect } from "react";
import TabView from "../components/Tabview";
import Query from "../components/Query";
import VendorRequest from "../routecomponents/vendorequest";
import VendorApproved from "../routecomponents/vendorapproved";
import UserList from "../routecomponents/userlist";
import { useGetallQuery } from "../redux/user";
import { useLazyGetallQuery } from "../redux/user"; 

import CustomPagination from "../components/Pagination";
import CreateUser from "./CreateUserPage";
function UsersPage(){
  const [active, setActive] = useState(0); // Index of the active tab
  const [currentPage, setCurrentPage] = useState(1);
  const [search_param,setSearch] = useState();

  const [getall,{ data, isLoading }] = useLazyGetallQuery();


  const handleTabChange = (index) => {
    setActive(index);
    setCurrentPage(1); // Reset page when switching tabs
    // Fetch data for the selected tab
    getall({ scope:'users', page: currentPage, tab: tabs[index].key });
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const refetch=()=>{
    getall({ scope: "users", page: currentPage,tab:tabs[active].key,search:search_param });

  }

  useEffect(() => {
    // Fetch data whenever the active tab or page changes
   getall({ scope: "users", page: currentPage,tab:tabs[active].key,search:search_param });
  }, [search_param,currentPage]);
  const search=(e)=>{
    console.log(e.target.value,'kk')
    setSearch(e.target.value)
    }
console.log(data)
  const tabs=[
    {
        title:'Users List',
        content: <UserList refetch={refetch} data={data?data.users:[]  }/>
      },
  {
    title:'Create User',
    content: <CreateUser/>
  },

]

return(
    <><h2 className="update-title title">Users</h2>
   <TabView  show={active===1?false:true} children={<Query search={search} refetch={refetch}/>} tabs={tabs} setActive={setActive} />
<CustomPagination
         data={data}
         show={active===1?false:true}
         handlePageChange={handlePageChange}
       />
    </>
)
}

export default UsersPage