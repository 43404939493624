import { Avatar } from "antd";
import React from "react";
import bin from '../assets/svgs/bin.svg'
import edit from '../assets/svgs/edit.svg'
import { Button, Modal, Space } from 'antd';
import { toast } from 'react-toastify';

import { ExclamationCircleFilled } from '@ant-design/icons';
import InfoDrawer from "../components/InfoDrawer";
import { useDeleteMutation } from "../redux/user";

import EditDropdown from "./Editmenu";
import Checkbox from "../components/Checkbox";
import { formatDate, formatRailsDatetime, timeAgo, truncateText } from "../function";
const { confirm } = Modal;
const SentRow = ({ data, refetch }) => {


    const bg = {
        pending: '#00463C',
        failed: '#c63f4c',
        paid: '#86d694'
    }
    const [del] = useDeleteMutation()

    const [open, setOpen] = React.useState(false);
    console.log(data);


    const showDeleteConfirm = async (id) => {

        try {
            confirm({
                title: 'Are you sure delete this user?',
                icon: <ExclamationCircleFilled />,
                content: 'Are you sure you want to delete this user',
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk: async () => {
                    const { data } = await del({ id, scope: 'user' })
                    refetch()

                    // Display success toast
                    toast.success('User Deleted', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,

                    });

                },
                onCancel() {
                    console.log('Cancel');
                },
            });

        } catch (e) {
            console.error('Error during confirmation:', e);
        }
    };
    return (
        <div key={data.id} className="tred">
            <div className="flex-item" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: 20, overflowWrap: 'break-word' }} >{truncateText(data.title, 20)}</div>
            <div className="flex-item" style={{ whiteSpace: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>{truncateText(data.body, 20)}</div>
            <div className="flex-item" > {data.recipient.split(',').length > 2 ? data.recipient.split(',')[0] + ` +${data.recipient.split(',').length} others` : data.recipient}</div>
            <div className="flex-item "> {data.message_type}</div>
            <div className="flex-item" >
                {data.send_at ? formatRailsDatetime(data.send_at) : formatRailsDatetime(data.created_at)} </div>
            <div className="flex-item" >{data.views}</div>
        </div>

    );
};
export default SentRow;