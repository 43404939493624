import React, { useState, useEffect, useRef } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Input, Radio, Select, Typography, Avatar } from 'antd';
import { useGetallQuery } from '../redux/user';
import Checkbox from '../components/Checkbox';
import EditService from './EditService';
import { formatDateReadable } from '../function';
import { Button, Drawer, List } from 'antd';
import { useAddSubscriptionMutation } from '../redux/user';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useLazyGetallQuery } from "../redux/user";
import TextInput from '../components/Input';
import SelectInput from '../components/Select';
import userImage from '../assets/images/userimg.png';
import { useCreateMutation, useLazyBankQuery } from '../redux/user';
import { backendURL } from '../redux/http';
import axios from 'axios';
import FileUpload from '../components/MultiFileUpload';
import DocumentRenderer from '../components/DocumentRener';
import { DateInput } from '../function';
import FileDrawer from '../components/FileUpdateDrawer';
import OpeningDrawer from '../components/OpeningDrawer';
import Gallery from './GalleryPage';
const { Text } = Typography;

const EditPackage = ({ isOpen, setOpen, data, refetch }) => {
    const [hourOpen, sethourOpen] = useState(false)
    const [createUser] = useCreateMutation()
    const [fileOpen, setFileOpen] = useState(false)
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [getbanks] = useLazyBankQuery()
    const [serviceVisible, setServiceVisible] = useState(false)
    const [banks, setBanks] = useState([])
    const [imageSource, setImageSource] = useState(null);
    const [file, setImageFile] = useState()
    const [formInfo, setFormData] = useState(data);
    const imageInputRef = useRef(null);
    const formRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    const handleFileChange = (event) => {
        const files = event.target.files;
        setSelectedFiles(Array.from(files));
    };
    const handleSave = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.info('Updating package...', {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
        });
        try {

            const formData = new FormData();

            // Append user fields
            Object.entries(formInfo).forEach(([field, value]) => {
                formData.append(`package[${field}]`, value);
            });

            // Append email confirmation if true


            // Append files





            // Append additional fields like type and scope
      
            formData.append('id', data.id);
            formData.append('scope', "package");


            console.log(formInfo, formData); // Log FormData here

            // Call the createUser mutation with FormData
            const response = await axios.patch(`${backendURL}/admin/update`, formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                }
            );
            //refetch()
            // Reset form and dismiss loading toast on success
            if (imageInputRef.current) {
                imageInputRef.current.value = '';
            }
            if (formRef.current) {
                formRef.current.reset();
            }
            toast.dismiss(loadingToastId);
            refetch()
            // Handle successful creation response
            console.log(response.data);
            toast.success('Package updated successfully!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } catch (error) {
            toast.dismiss(loadingToastId);
            console.log(error)
            error.response.data.errors.map((err) => {


                toast.error(err, {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })

            console.log(error);
        }
    };



    const handleChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));

    };



    console.log(data)


    React.useEffect(() => {
        console.log(data)
    }, [selectedFiles]);
    return (
        <Drawer title={`${formInfo.name} was created on ${formatDateReadable(formInfo.created_at)}`} width={800} placement="right" closable={true} onClose={() => setOpen(!isOpen)} open={isOpen}>


            <form className='bg-bg w-100' ref={formRef}>
                <div className="flex gap-5">

                    <div className="flex-1">
                        <h2 className="mt-[50px] title-med">Package Name</h2>
                        <div className='flex gap-5 p-[0px]'>

                            <TextInput
                                className={'flex-1 input'}
                                placeholder={'First Name'}
                                onChange={(e) => handleChange('name', e.target.value)}
                                value={formInfo.name}
                            />

                        </div>
                       


                    
                        <h2 className="mt-[50px] title-med">Price</h2>
                        <TextInput
                            className={'flex-1 input'}
                            placeholder={'price'}
                            type={'number'}
                            onChange={(e) => handleChange('price', e.target.value)}
                            value={formInfo.price}

                        />
                   



                        <h2 className="mt-[50px] title-med">Discount</h2>
                        <TextInput
                            type={'number'}
                            className={'flex-1 input'}
                            placeholder={'Insert discount'}
                            onChange={(e) => handleChange('discount', e.target.value)}
                            value={formInfo.discount}

                        />

                        <h2 className="mt-[50px]  title-med">Description</h2>
                        <textarea
                            value={formInfo.benefits}
                            style={{ height: 200, padding: 20 }}
                            className={'flex-1 mb-[10px] input'}
                            placeholder={'Insert description'}
                            onChange={(e) => handleChange('benefits', e.target.value)}
                        />
                    </div>
                </div>
                <div className="flex gap-3 justify-end mt-[100px] mb-[100px]">
                    <div style={{ width: 100 }} className="action d-flex unapproved" onClick={handleSave}>
                        Update
                    </div>
                    <div style={{ width: 100, background: '#DAECD2', color: '#00463' }} onClick={() => setOpen(!isOpen)} className="action w-[50px] d-flex">
                        Cancel
                    </div>
                </div>
            </form>
        </Drawer>

    );
};

export default EditPackage;
