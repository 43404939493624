import React, { useState, useEffect } from "react";
import TabView from "../components/Tabview";
import Query from "../components/Query";
import VendorRequest from "../routecomponents/vendorequest";
import VendorApproved from "../routecomponents/vendorapproved";
import { useLazyGetallQuery } from "../redux/user"; 
import Loading from "../components/Loading";
import { Pagination,Affix } from "antd";
import CustomPagination from "../components/Pagination";
import CreateVendor from "./CreateVendor";
import InfoHeader from "../components/InfoHeader";


function VendorPage() {
  const [active, setActive] = useState(0); // Index of the active tab
  const [currentPage, setCurrentPage] = useState(1);
  const [getall,{ data, isLoading, refetch }] = useLazyGetallQuery();
  const [search_param,setSearch] = useState();

  const handleTabChange = (index) => {
    setActive(index);
    setCurrentPage(1); // Reset page when switching tabs
    // Fetch data for the selected tab
    getall({ scope:'vendors', page: currentPage, tab: tabs[index].key });
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // Fetch data whenever the active tab or page changes
   getall({ scope: "vendors", page: currentPage,tab:tabs[active].key,search:search_param });
  }, [refetch,search_param,currentPage]);
  const search=(e)=>{
    console.log(e.target.value,'kk')
    setSearch(e.target.value)
    }
  const tabs = [
    { key: 'all', title: 'All', content: <VendorApproved refetch={()=>handleTabChange(active)} data={data?.vendors||[]} /> },
    { key: 'unapproved', title: 'Request', content: <VendorRequest refetch={()=>handleTabChange(active)} data={data?.vendors||[]} /> },
    { key: 'approved', title: 'Approved', content: <VendorApproved refetch={()=>handleTabChange(active)}  data={data?.vendors||[]} /> },
    { key: 'create', title: '+ Create', content: <CreateVendor/> },
  ];




  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
                 <Affix offsetTop={100}><h2  style={{height:70,backgroundColor:' #F7F7F9'}} className="update-title"> Vendors</h2>
                 </Affix>

          <TabView show={active === 3 ? false : true} children={<Query search={search} refetch={()=>handleTabChange(active)} />} tabs={tabs} setActive={handleTabChange} />



       <CustomPagination
         data={data}
         show={active === 3 ? false : true}
         
         handlePageChange={handlePageChange}
       />
         
        </>
      )}
    </>
  );
}

export default VendorPage;
