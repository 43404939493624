import { Avatar } from "antd";
import React,{useState} from "react";
import bin from '../assets/svgs/bin.svg'
import edit from '../assets/svgs/edit.svg'
import InfoDrawer from "../components/InfoDrawer";
import { Button, Modal, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import EditDropdown from "./Editmenu";
import { formatDate, timeAgo } from "../function";
const { confirm } = Modal;
const SubcriptionRow= ({ data }) => {
  const [open, setOpen] = useState(false);

    console.log(data);

    
    const showDeleteConfirm = (id) => {
        confirm({
          title: 'Are you sure delete this task?',
          icon: <ExclamationCircleFilled />,
          content: 'Some descriptions',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk() {
            alert(id)
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };
    return (
       <div key={data.id}   className="tred" 
     > 
      

      <div className="flex-item" onClick = {()=> console.log(data.id)}>
       
          <div className="" >{data.username}</div>
          </div>  
         <div className="flex-item">{data.subscription_plan.name}</div>
        <div className="flex-item price">{data.subscription_plan.name=='Basic'? 'free': `₵${data.subscription_plan.price}`}</div>
          <div className="flex-item">{data.subscription_type?data.subscription_type:'limited'}</div>
        <div className="flex-item">{data.subscription_start_date? formatDate(data.subscription_start_date): 'basic'}</div>
        <div className="flex-item">{data.subscription_end_date? formatDate(data.subscription_end_date): 'basic'}</div>   
        <InfoDrawer scope={'SubscriptionPlan'} keys={[

  'name',
  'benefits',
  'price',
  'discount',
  'others',
]} userData={data.subscription_plan} open={open} setOpen={setOpen}/>

        </div>
    );
};

export default SubcriptionRow ;