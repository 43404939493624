import { ReactComponent as ChevronRight } from "../assets/svgs/chevron_right.svg";
import google from '../assets/svgs/google2.svg'
import  qrcode from '../assets/svgs/businessqr.png'
const ScanDesktop2=()=>{
    return(
        <div className="w-full h-[176px] flex rounded-[15px] items-center  bg-beige">
           <div className="ml-[30px] gap-[20px] flex flex-col">
           <a

           style={{height:57,background: "#00463C",fontWeight: 600}}
            className="join-waitlist-button"
          
          >
            Scan to download<ChevronRight className="ml-10"/> 
          </a>

          <img
        src={google}
        className=' h-[40px]'
        />
            </div> 


            <img
        src={qrcode}
        className='mr-[15px] h-[141px]  ml-auto'
        />
           
  
        </div>
    )
}

export default ScanDesktop2