import React from 'react';
import { Carousel } from 'react-bootstrap';

const VendorProfile = ({ vendor }) => {
  const {
    username,
    email,
    phone,
    name,
    description,
    image,
    opening_hours,
    service_categories,
    staff,
    ratings,
    distance,
    cover_url,
    avatar_url,
    other_images_urls
  } = vendor;

  return (
    <div className="container mt-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <img src={avatar_url || image} alt={`${username} profile`} className="rounded-lg w-full md:w-auto" />
        </div>
        <div>
          <div className="text-3xl">{username}'s Profile</div>
          <p>Email: {email}</p>
          <p>Phone: {phone}</p>
          <p>Description: {description}</p>
          <p>Location: {name ? name : 'empty'}</p>
        </div>
      </div>

      <h3 className="text-lg mt-10" style={{ color: 'var(--color-dg)' }}>
        Opening Hours
      </h3>
      <table className="table table-sm">
        <thead>
          <tr>
            <th>Day</th>
            <th>Opening Time</th>
            <th>Closing Time</th>
            <th>Is Opened</th>
          </tr>
        </thead>
        <tbody>
          {opening_hours.map((hour) => (
            <tr key={hour.id}>
              <td>{hour.day}</td>
              <td>{hour.opening_time}</td>
              <td>{hour.closing_time}</td>
              <td>{hour.opened ? 'Yes' : 'No'}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h3 className="text-lg" style={{ color: 'var(--color-dg)' }}>
        Service Categories
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {service_categories.length !=0 ?service_categories.map((category) => (
          <div key={category.id}>
            <div>
              <h4>{category.name}</h4>
            </div>

            <ul className="list-disc ml-4">
              {category.services.map((service) => (
                <li key={service.id}>{service.name} - Gh {service.price}</li>
              ))}
            </ul>
          </div>
        )):<div className='title-med my-5'>NO SERVICES</div>}
      </div>

      <h3 className="text-lg" style={{ color: 'var(--color-dg)' }}>
        Vendor Images
      </h3>
      <Carousel interval={null} className="max-w-xs md:max-w-2xl">
        <Carousel.Item>
          <img  style={{objectFit:'cover',height:500}} src={avatar_url} alt={`${username} avatar`} className="rounded-lg contain w-full" />
        </Carousel.Item>
        <Carousel.Item>
          <img style={{ objectFit: 'cover', height: 500}} src={cover_url} alt={`${username} cover`} className="rounded-lg contain w-full" />
        </Carousel.Item>
        {other_images_urls.map((imageUrl, index) => (
          <Carousel.Item key={index}>
            <img style={{ objectFit: 'cover', height: 500 }} src={imageUrl} alt={`Image ${index + 1}`} className="rounded-lg contain w-full" />
          </Carousel.Item>
        ))}
      </Carousel>

      <h3 className="text-lg mt-4" style={{ color: 'var(--color-dg)' }}>
        Staff
      </h3>
      <div className="flex flex-wrap">

        {staff.length !=0? staff.map((staffMember) => (
          <div key={staffMember.name} className="mb-3 mr-3">
            <h4>{staffMember.name}</h4>
            <img
              src={staffMember.image}
              alt={`${staffMember.name} avatar`}
              className="rounded-full w-20 h-20"
            />
          </div>
        )) : <div className='title-med mb-5'>NO STAFFS </div> }
      </div>
    </div>
  );
};

export default VendorProfile;
