import { Avatar } from "antd";
import React, { useState } from "react";
import bin from '../assets/svgs/bin.svg'
import edit from '../assets/svgs/edit.svg'
import VendorProfileContainer from "../components/VendorCont";
import { Button, Modal, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { keysToDisplay } from "../_data/keys";
import EditDropdown from "./Editmenu";
import { toast } from 'react-toastify';
import userImage from '../assets/images/userimg.png';

import { useDeleteMutation } from "../redux/user";
import EditVendor from "../pages/EditVendors";
const { confirm } = Modal;
const ApprovedRow = ({ data, refetch }) => {




  const [open, setOpen] = useState(false);
  const [del] = useDeleteMutation()

  // const showProfileModal = () => {
  //   setIsProfileVisible(true);
  // };

  // const hideProfileModal = () => {
  //   setIsProfileVisible(false);
  // };



  const showDeleteConfirm = async (id) => {
    try {
      confirm({
        title: 'Are you sure delete this vendor?',
        icon: <ExclamationCircleFilled />,
        content: 'Are you sure you want to delete this vendor',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          const { data } = await del({ id, scope: 'vendor' })
          refetch()
          // Display success toast
          toast.success('Vendor Deleted', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,

          });

        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } catch (e) {
      console.error('Error during confirmation:', e);
    }
  };
  return (
    <div key={data.id} className="tred"
    >

      <div className="flex-item cursor icont">
        <Avatar src={data.image||userImage} />{' '}
        <div className="" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>{data.username}</div>
      </div>
      <div className="flex-item">{data.staff ? data.staff.length : 'not set'}</div>
      <div className="flex-item">{data.phone ? data.phone : 'none'}</div>
      <div className="flex-item" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>{data.name ? data.name : 'none'}</div>
      <div className="flex-item">{data.subscription_plan ? data.subscription_plan.name : 'none'}</div>
      <div className="flex-item d-flex">
        <img onClick={() => showDeleteConfirm(data.id)} src={bin} />
        <img onClick={() => setOpen(!open)} src={edit} style={{ width: 30, marginLeft: 10 }} />
      </div>
      <EditVendor isOpen={open} refetch={refetch} setOpen={setOpen} data={data} />
    </div>
  );
};

export default ApprovedRow;

