import React, { useState,useEffect } from 'react';
import TabView from "../components/Tabview";
import Query from "../components/Query";
import VendorRequest from "../routecomponents/vendorequest";
import VendorApproved from "../routecomponents/vendorapproved";
import UserList from "../routecomponents/userlist";
import CouponList from "../routecomponents/couponslist";
import CustomPagination from "../components/Pagination";
import InfoHeader from "../components/InfoHeader";

import { useLazyGetallQuery } from "../redux/user"; 
import { Affix } from 'antd';
import CreateCoupon from './CreateCoupon';


function CouponsPage(){
  const [active, setActive] = useState(0); // Index of the active tab
  const [currentPage, setCurrentPage] = useState(1);
  const [getall,{ data, isLoading}] = useLazyGetallQuery();
  const [search_param,setSearch] = useState();

  const handleTabChange = (index) => {
    setActive(index);
    setCurrentPage(1); // Reset page when switching tabs
    // Fetch data for the selected tab
    getall({ scope:'coupons', page: currentPage, tab: tabs[index].key });
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
const refetch=async()=>{
  getall({ scope: "coupons", page: currentPage,tab:tabs[active].key,search:search_param });

}
  useEffect(() => {
    // Fetch data whenever the active tab or page changes
   getall({ scope: "coupons", page: currentPage,tab:tabs[active].key,search:search_param });
  }, [search_param,active,currentPage]);
  const search=(e)=>{
    console.log(e.target.value,'kk')
    setSearch(e.target.value)
    }

    const tabs=[
      {
          title:'Coupons List',
          content: <CouponList refetch={refetch} data={data?.coupons?data?.coupons:[]}/>
        },
    {
      title:'+Create Coupons',
      content:  <CreateCoupon />
    },
  
  ]

  const handleScroll = () => {
    const body = document.body;
    body.style.overflow = 'hidden';

    // Remove overflow hidden after a delay to allow for smooth scrolling
    setTimeout(() => {
      body.style.overflow = 'visible';
    }, 500);
  };


return(
    <>




<h2 className="update-title title">Coupons</h2>




   <TabView  show={active===1?false:true} children={<Query search={search} refetch={refetch}/>} tabs={tabs} setActive={setActive} />


   <CustomPagination
   show={active===1?false:true}
         data={data}
         handlePageChange={handlePageChange}
       />
    </>
)
}

export default CouponsPage