import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import SelectDropdown from './SelectDropDown';
Chart.register(CategoryScale);

const BarChart = ({ data, name, onChange }) => {

    return (
        <div style={{ position: 'relative', padding: 20, height: '100%', width: '100%' }}>

            <div style={{ marginTop: -20 }} className='flex mt[-20px]  items-center justify-between'>
                <h2 className='text-lightgreen'>{name}</h2>
                <SelectDropdown onChange={onChange} />
            </div>
            <div>
                <Bar
                    data={data}
                    options={{
                        responsive: true,
                        // Make the chart responsive
                    }}
                />
            </div>
        </div>
    );
};

export default BarChart;
