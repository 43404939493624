import React, { useState } from 'react';
import Query from './Query';
import { authApi } from '../redux/user';
import { Affix } from 'antd';

const TabView = ({ show,tabs,width=400,setActive ,children,right=false,top}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
    setActive(index)
  };

  return (
    <>
    <Affix offsetTop={100}>
    <div style={{display:'flex',marginTop: top&&top, backgroundColor:' #F7F7F9', paddingBottom:30}}>
          <div style={{ marginLeft: right && 'auto', height:70,background: 'white', borderRadius: 35, padding: 10 }}>
      <div className='' style={{ height:50, width:width, display: 'flex', justifyContent: 'center' ,alignItems:'center',alignContent:'center'}}>
        {tabs.map((tab, index) => (
          <TabButton
            key={index}
            isActive={activeTab === index}
            onClick={() => handleTabClick(index)}
          >
            {tab.title}
          </TabButton>
        ))}
      </div>

    </div>
    {
            show && children&& <div style={
            { marginLeft: 'auto', alignSelf: 'center' }
          }>
          {children}
        </div>
    }


    </div>
    </Affix>
    <div style={{ marginTop: 20 }}>
        {tabs[activeTab].content}
     
      </div></>
  );
};

const TabButton = ({ isActive, onClick, children }) => (
  <button
    style={{
      color: isActive ? '#ffffff' : '#86D694',
      textAlign: 'center',
       height:45,
      width: 200,
      borderRadius: 35,
      background: isActive  ? '#86D694' :"#ffff",
      border: 'none',
      outline: 'none',
    }}
    onClick={onClick}
  >
    {children}
  </button>
);

export default TabView;
