import React, { useState } from 'react';
import { Form, Input, Upload, Button, message } from 'antd';
import { CodepenSquareFilled, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { backendURL } from '../redux/http';

const ProfileForm = ({ user }) => {
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState();
 const name = JSON.parse(localStorage.getItem('data'))

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      if (values.name) {
        formData.append('username', values.name);
      }
      if (values.password) {
        formData.append('password', values.password);
      }
      if (imageUrl) {
        formData.append('blob', imageUrl);
      }
      formData.append('id', name.user.id);
      const response = await axios.post(`${backendURL}/admin/user`, formData);
      message.success('Profile updated successfully');
    } catch (error) {
      message.error('error');
    }
  };

  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageUrl(file);
      console.log(file)
    };
    return false;
  };

  return (
    <Form style={{marginTop:40}} form={form} onFinish={onFinish} initialValues={{ name: 'hhhh' }}>
      <Form.Item name="name" label="Name">
        <Input className='custom-input'  />
      </Form.Item>
      <Form.Item name="password" label="Password">
        <Input.Password className='custom-input' />
      </Form.Item>
      <Form.Item label="Avatar">
        <Upload beforeUpload={beforeUpload}>
          <Button icon={<UploadOutlined />}>Change Avatar</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button  className='custom-button' type="primary" htmlType="submit">
          Update Profile
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProfileForm;