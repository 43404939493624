import React from "react";
import { Avatar ,Empty} from "antd";
import { formatDate } from "../function";
const TransferStatus=({data})=>{
 
    return(
        <div>
        { data?
data?.map((item)=>{
   return <div className="user-details px-3">
        <div className="content font-weight-bold">{item.name}</div>

        <div className="content font-weight-bold">{item.bank_name||'Pamp'}</div>
        <div className="content">{item.account_number}</div>
        <div style={{color: '#86d694'}} className="content price">₵{parseFloat(item.amount).toFixed(2)}</div>
        <div className="content">{item.status }</div>
      </div>
})
      :<Empty/>  }
        </div>
        
    )
}

export default TransferStatus