
import React from "react";
import Table from "../tables/Table";
import TableHeadItem from "../tables/TableHead";
import { Affix } from "antd"

import PayoutRow from "../tables/PayoutRow";
import PayoutStatusRow from "../tables/PayoutStatusRow";

//import { useGetApprovedVendorsQuery } from "../redux/user";
const PayoutStatus=({data})=>{
    const theadData = ["Vendor",'Amount', "Method", "Reference", 'Date','Status','Action'];
//    const{ data,isLoading}= useGetApprovedVendorsQuery()
   // console.log(data&&data)
    return (
        <div className="table-head-container">
          <Affix offsetTop={200} className="">
        <div className="table-head">
          {theadData.map((h) => {
            return <TableHeadItem key={h} item={h} />;
          })}
        </div>
      </Affix>
        <div className="reqbody">
        {data.map((item) => {
                    
                    return ( <PayoutStatusRow key={item.id} data={item} />);
                })} 
        </div>
      </div>
      
    );
}

export default PayoutStatus