import React from "react";
import MailchimpFormContainer from "./MailchimpFormContainer";
import { ReactComponent as Client } from "../assets/svgs/client.svg";

const ClientWaitlist = () => {
  return (
    <section className="client-waitlist-wrapper container">
      <div className="mx-auto client-svg-wrapper">
        <Client className="client-svg mx-auto" />
      </div>
      <MailchimpFormContainer />
    </section>
  );
};

export default ClientWaitlist;
