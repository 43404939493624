import { ReactComponent as ChevronRight } from "../assets/svgs/chevron_right.svg";
import google from '../assets/svgs/google2.svg'
import  mobiledownload from '../assets/svgs/mobiledownload.svg'
import  mobiledownloaddark from '../assets/svgs/mobiledownload2.svg'
const ScanMobile=({business=false})=>{
    return(
        <div id="scanmobile" style={{backgroundColor: business? "#daecd2": "#86d694"   }} className="w-full h-[66px]  justify-around flex rounded-[10px] items-center ">
      <img
        src={google}
        className='h-[20px]'
        />    
<a href={business? 'https://bit.ly/pampvendorapp':'https://bit.ly/pampuserapp'}>
      <img
    
        src={business?mobiledownloaddark:mobiledownload}
        className='h-[30px]'
        />
</a>
         
       


  
        </div>
    )
}

export default ScanMobile