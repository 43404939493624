import React from "react";
import TabView from "../components/Tabview";
import Query from "../components/Query";
import VendorRequest from "../routecomponents/vendorequest";
import VendorApproved from "../routecomponents/vendorapproved";
import { useGetallQuery } from "../redux/user";
import Loading from "../components/Loading";
import SendNotiUser from "../components/SendNotiUser";
import SendNotiVendor from "../components/SendNotiVendors";
import Emails from "../components/Emails";
import SentMessages from "./SentMessage";


function SendNotificationsPage() {



  const tabs = [{
    title: 'Users',
    content: <SendNotiUser />
  },
  {
    title: 'Vendors',
    content: <SendNotiVendor />
  },
  {
    title: 'Records',
    content: <SentMessages />
  },
  ]
  const [active, setActive] = React.useState();

  return (
    <>
      {

        <><h2 className="update-title title">Notifications</h2>


          <TabView show={false} children={<Query />} tabs={tabs} setActive={setActive} />
        </>
      }



    </>
  )
}

export default SendNotificationsPage