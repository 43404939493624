import React from "react";
import girls from "../assets/images/girls.png";
import { ReactComponent as Boat } from "../assets/svgs/boat.svg";
import { ReactComponent as Cloud } from "../assets/svgs/cloud.svg";
import { ReactComponent as Birds } from "../assets/svgs/birds.svg";
import { ReactComponent as Dot } from "../assets/svgs/dot.svg";

const AboutPamp = () => {
  return (
    <section id="about-pamp" className="appBg pt-5">
      <Cloud className="mx-auto about-pamp-cloud-one hide-mobile " />
      <Dot className="mx-auto about-pamp-dot" />

      <div className="container ">
        <div className="row about-pamp-main">
          <div className="col-12 col-lg-6 px-0">
            <Cloud className="mx-auto about-pamp-cloud-two " />

            <img src={girls} className="img-fluid girls-image" alt="" />
          </div>

          <div className="col-12 col-lg-6 mb-5 about-temp-texts-wrapper">
            <Cloud className="mx-auto about-pamp-cloud-girls hide-mobile" />
            <Birds className="mx-auto about-pamp-birds" />

            <h2 className="maintext2 display-3 about-pamp-text-main">
              About
              <span className="break-text"></span>
              <span className="display- mb-0 pamp-text"> PAMP</span>{" "}
            </h2>
            <div className="about-pamp-texts">
              <p className="mb-4 pe-md-5 about-pamp-text ">
                Pamp is a client – focused and result driven Software as a
                service (SaaS) company that is into service appointment booking
                for customers.
              </p>

              <p className="mb-4 pe-md-5  pr-md-5 about-pamp-text ">
                We provide a broad – based business support service that won’t
                in any way put a hole in the pocket of our clients.
              </p>

              <p className="mb-4 pe-md-5  pr-md-5 about-pamp-text ">
                We offer standard and professional services to all our clients
                at local, state, national, and international levels.
              </p>
            </div>
            <Boat className="about-boat" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutPamp;
