import React, { useState } from 'react';
import file  from '../assets/svgs/file-upload.svg'
function FileUpload({handleFileChange,selectedFiles}) {
  

  return (
    <div className='flex mt-[20px] h-[170px] rounded-[30px] flex-col items-center  justify-center bg-white'>
      <label htmlFor="avatarInput" className="cursor-pointer">
        <img 
        src={file}
        />
        <input
          type="file"
          id="avatarInput"
          accept=".pdf, .jpg, .jpeg, .png, .docx"
          multiple  // Allow multiple file selection
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </label>
      {/* Display selected files */}
      <div>
        {selectedFiles && selectedFiles.length > 0 && (
          <ul className='list-disc pl-4'>
            {Array.from(selectedFiles).map((file, index) => (
              <li key={index} className='mt-1 text-lightgreen'>{file.name}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default FileUpload;
