import React, { useState, useEffect } from "react";
import TabView from "../components/Tabview";
import Query from "../components/Query";
import VendorRequest from "../routecomponents/vendorequest";
import VendorApproved from "../routecomponents/vendorapproved";
import UserList from "../routecomponents/userlist";
import ServiceList from "../routecomponents/serviceslist";
import { useLazyGetallQuery } from "../redux/user";
import Loading from "../components/Loading";
import CustomPagination from "../components/Pagination";
import CreateServices from "./CreateServices";
import InfoHeader from "../components/InfoHeader";


function ServicesPage() {
  const [search_param, setSearch] = useState();
  const [active, setActive] = useState(0); // Index of the active tab
  const [currentPage, setCurrentPage] = useState(1);
  const [getall, { data, isLoading }] = useLazyGetallQuery();
  console.log(data)
  const refetch = () => {
    getall({ scope: "services", search: search_param, page: currentPage });

  }
  const tabs = [


    {
      title: 'Service List',
      content: <ServiceList refetch={refetch} data={data?.services ? data.services : []} />
    },
    {
      title: '+ Create Service',
      content: <CreateServices />
    },

  ]

  
  useEffect(() => {
    // Fetch data whenever the active tab or page changes
    getall({ scope: "services", search: search_param, page: currentPage });
  }, [active, search_param, currentPage]);
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  const search = (e) => {
    console.log(e.target.value, 'kk')
    setSearch(e.target.value)
  }
  return (
    <><h2 className="update-title title">Services</h2>



      <TabView show={active === 1 ? false : true} children={<Query search={search} refetch={refetch} />} tabs={tabs} setActive={setActive} />
      <CustomPagination
        show={active === 1 ? false : true}
        data={data}
        handlePageChange={handlePageChange}
      />

    </>
  )
}

export default ServicesPage