import React, { useEffect, useState } from "react";
import large_landing_phone from "../assets/images/large_landing_phone.png";
import { ReactComponent as ChevronRight } from "../assets/svgs/chevron_right.svg";
import { ReactComponent as Tree } from "../assets/svgs/phone_tree.svg";
import { ReactComponent as Cloud } from "../assets/svgs/cloud.svg";
import { ReactComponent as Birds } from "../assets/svgs/birds.svg";
import { ReactComponent as Dot } from "../assets/svgs/dot.svg";
import { ReactComponent as LandingBubble } from "../assets/svgs/landing_bubble.svg";
import { ReactComponent as MobileBubble } from "../assets/svgs/mobile_bubble.svg";
import AboutPamp from "../components/AboutPamp";
import Business from "../components/Business";
import Everyone from "../components/Everyone";
import Footer from "../components/Footer";
import Menu from "../components/Menu";
import Navbar from "../components/Navbar";
import Services from "../components/Services";
import Waitlist from "../components/Waitlist";
import { Parallax } from 'react-scroll-parallax';
const LandingPage = () => {
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  let menuBtn;
  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  const handleShow = (data) => {
    if (menuBtn) {
      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        console.log("else");
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };
  return (
    <div>
      {show && <Menu show={show} handleShow={handleShow} />}

      <Navbar show={show} handleShow={handleShow} />
      
      <section className="appBg relative">
        <div className="container">
          <div className="row centerit split">
            <div className="col-12 col-lg-4 cloud-one-wrapper">
              <Cloud className="mx-auto" />
            </div>
            <div className="col-12 col-lg-4 cloud-one-wrapper"></div>
            <div className="col-12 col-lg-4 cloud-one-wrapper hide-mobile">
              <Cloud className="mx-auto" />
            </div>
          </div>
          <Birds className="landing-birds" />
          <div className="row centerit split">
            <div className="col-12 col-lg-6">
              <div className="booking-wrapper">
                <h1 className="display-1 mb-0 maintext bsm-text">BOOKING</h1>
                <h1 className="display-1 mb-0 maintext bsm-text service-text">
                  SERVICES
                </h1>
                <h1 className="display-2 mb-0 maintext bsm-text easy-text">MADE EASY</h1>
                <a href="/download" className="cta">
                  Download The App
                  <ChevronRight className="ml-20 chevron-right" />
                </a>
              </div>
              <Dot className="booking-dot hide-mobile" />
              <LandingBubble className="landing-bubble" />
            </div>

            <div className="col-12 col-lg-6">
              <Dot className="landing-phone-dot hide-mobile" />

              <img
                src={large_landing_phone}
                className="img-fluid landing-phone"
                alt=""
              />
              <MobileBubble className="mobile-bubble hidden" />
              <Tree className="phone-tree hide-mobile" />
            </div>
          </div>
        </div>
      </section>
      <AboutPamp />
      <Services />
      <Everyone />
      <Business />
      <Waitlist />
      <Footer />
    </div>
  );
};

export default LandingPage;
