import { ReactComponent as ChevronRight } from "../assets/svgs/chevron_right.svg";
import google from '../assets/svgs/google.svg'
import  qrcode from '../assets/svgs/qrcode.svg'
const ScanDesktop=()=>{
    return(
        <div className="h-[176px] flex rounded-[15px] items-center  bg-lightgreen">
           <div className="ml-[30px] gap-[20px] flex flex-col">
           <a

           style={{height:57,fontWeight: 600}}
            className="join-waitlist-button text-source-sans-pro "
            
          >
            Scan to download<ChevronRight className="ml-10"/> 
          </a>

          <img
        src={google}
        className=' h-[40px]'
        />
            </div> 


            <img
        src={qrcode}
        className='mr-[15px] h-[141px]  ml-auto'
        />
           
  
        </div>
    )
}

export default ScanDesktop