import React, { useState, useRef } from "react";
import LoginForm from "../components/LoginForm";

const LoginPage= () => {


  return (
    <div className="login-container">
      <div className="login-background">
        {/* Background images will be set using CSS */}
      </div>
      <div className="login-form">
        <div className="bag-image"></div>
       <LoginForm />
      </div>
    </div>
  );
};

export default LoginPage;
