import React from "react";
import Table from "../tables/Table";
import TableHeadItem from "../tables/TableHead";
import UserListRow from "../tables/UserlistRows";
import { Affix } from "antd"
import EmployeeList from "./EmployeeList";
import EmployeeStatusRow from "../tables/EmployeeStatusRow";
import SentMessages from "../pages/SentMessage";
import SentRow from "../tables/SentRow";

//import { useGetApprovedVendorsQuery } from "../redux/user";
const SentMessage = ({ data, refetch }) => {
    const theadData = ["Title", 'Body', "Recipient", "Type", 'Sent at', 'Views'];
  
    return (
        <div className="table-head-container">
            <Affix offsetTop={200} className="">
                <div className="table-head">
                    {theadData.map((h) => {
                        return <TableHeadItem key={h} item={h} />;
                    })}
                </div>
            </Affix>
            <div className="reqbody">
                {data.map((item) => {

                    return (<SentRow refetch={refetch} key={item.id} data={item} />);
                })}
            </div>
        </div>

    );
}

export default SentMessage