import React, { useState, useEffect } from "react";
import TabView from "../components/Tabview";
import Query from "../components/Query";
import VendorRequest from "../routecomponents/vendorequest";
import VendorApproved from "../routecomponents/vendorapproved";
import UserList from "../routecomponents/userlist";
import BookingList from "../routecomponents/bookingslist";
import BookingStatus from "../routecomponents/bookingstatus";
import { useGetallQuery } from "../redux/user";
import SubscriptionList from "../routecomponents/SubscriptionList";
import New_subscription from "./new_subscription";
import CustomPagination from "../components/Pagination";
import { useLazyGetallQuery } from "../redux/user"; 
import InfoHeader from "../components/InfoHeader";


function SubcriptionPage(){
  const [active, setActive] = useState(0); // Index of the active tab
  const [currentPage, setCurrentPage] = useState(1);
  const [getall,{ data, isLoading, refetch }] = useLazyGetallQuery();
  const [search_param,setSearch] = useState();

  const handleTabChange = (index) => {
    setActive(index);
    setCurrentPage(1); // Reset page when switching tabs
    // Fetch data for the selected tab
    getall({ scope:'subscriptions', page: currentPage, tab: tabs[index].key });
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // Fetch data whenever the active tab or page changes
   getall({ scope: "subscriptions", page: currentPage,tab:tabs[active].key,search:search_param });
  }, [refetch,search_param,currentPage]);
  const search=(e)=>{
    console.log(e.target.value,'kk')
    setSearch(e.target.value)
    }

  const tabs=[
    {
        title:'List',
        content: <SubscriptionList data={data?data.subs:[]} />
      },
  {
    title:'+ New Subscription',
    content:  <New_subscription/>
  },

]

return(
    <><h2 className="update-title title">Subscriptions</h2>

    <TabView  children={<Query search={search} refetch={refetch}/>}  show={active===1?false:true}  tabs={tabs} setActive={setActive} />

    <CustomPagination
show={active===1?false:true} 
         data={data}
         handlePageChange={handlePageChange}
       />
   
    </>
)
}

export default SubcriptionPage