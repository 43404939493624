import React, { Fragment, useState } from "react";
import CheckBoxComponent from "./Checkbox";


const PaymentPreference = ({ setPaymentPrefs, paymentPrefs }) => {


    const handlePaymentPrefChange = (pref) => {
        setPaymentPrefs((prevState) => ({
            ...prevState,
            [pref]: !prevState[pref]
        }));
    };

    return (
        <div style={{ marginLeft: 20, marginBottom: 20 }}>

            <CheckBoxComponent
                label="Momo"

                checked={paymentPrefs?.momo}
                onChange={() => handlePaymentPrefChange("momo")}
            />
            <CheckBoxComponent
                label="Card"
                checked={paymentPrefs?.card}
                onChange={() => handlePaymentPrefChange("card")}
            />
            <CheckBoxComponent
                label="Cash"
                checked={paymentPrefs?.cash}
                onChange={() => handlePaymentPrefChange("cash")}
            />





        </div>
    );
};




export default PaymentPreference;
