import { Avatar } from "antd";
import React ,{useState}from "react";
import bin from '../assets/svgs/bin.svg'
import edit from '../assets/svgs/edit.svg'
import { useDeleteMutation } from "../redux/user";
import { toast } from 'react-toastify';

import { Button, Modal, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import InfoDrawer from "../components/InfoDrawer";
import EditDropdown from "./Editmenu";
import { timeAgo } from "../function";
import EditService from "../pages/EditService";
const { confirm } = Modal;
const ServiceRow= ({ data,refetch }) => {
  const [del] = useDeleteMutation()

  const [open, setOpen] = useState(false);
    console.log(data);

    
    const showDeleteConfirm = (id) => {
        confirm({
          title: 'Are you sure delete this service?',
          icon: <ExclamationCircleFilled />,
          content: 'Are you?',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk: async()=> {
            const { data } = await del({ id, scope: 'service' })
            refetch()

            // Display success toast
            toast.success('service Deleted', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,

            });
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };
    return (
       <div key={data.id}  className="tred2" 
     > 
      
      <div className="flex-item" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }} >{data.service_category.vendor.username}</div>

        <div className="flex-item" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>{data.service_category.name  }</div>
        <div className="flex-item" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>{data.name}</div>
          <div className="flex-item">{data.time}</div>
        <div className="flex-item price">GH₵ {data.price}</div>
        <div className="flex-item">{timeAgo(data.updated_at)}</div>
        <div className="flex-item d-flex edit">
        <img onClick={()=>showDeleteConfirm(data.id)} src={bin}  />
        <img onClick={()=>setOpen(!open)} src={edit} style={{ width: 30, marginLeft: 10 }} />
        </div>   
        <EditService data={data} refetch={refetch} setOpen={setOpen} isOpen={open}/>

        </div>
    );
};

export default ServiceRow ;
