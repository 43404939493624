import React from "react";
import user from '../assets/images/users.png'


const TotalVendors=({data})=>{
    return( <div className="userdetcont">
    <div className="d-flex">
    <img src={user} ></img>
    <div className="user-title">Vendors</div>
</div>
<div className="innertext1">{data}</div>
<div className="innertext2">Total Vendors</div>
</div>)
}

export default TotalVendors 