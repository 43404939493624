import { Avatar } from "antd";
import React, { useState } from "react";
import bin from '../assets/svgs/bin.svg'
import edit from '../assets/svgs/edit.svg'
import { Button, Modal, Space,Input } from 'antd';
import { toast } from 'react-toastify';
import userImage from '../assets/images/userimg.png';
import { ExclamationCircleFilled } from '@ant-design/icons';
import InfoDrawer from "../components/InfoDrawer";
import { useDeleteMutation, useActionMutation, } from "../redux/user";

import EditDropdown from "./Editmenu";
import EditUser from "../pages/EditUser";
import UserProfile from "./userProfile";


import Influencers from "../pages/Influencer";
const { confirm } = Modal;
const UserListRow = ({rates, data ,refetch,Influencer=false}) => {
  const [del]= useDeleteMutation()
  const [open, setOpen] = React.useState(false);
    console.log(data);
  const [approve, { isLoading }] = useActionMutation()
  const [isOpen, setIsOpen] = React.useState(false);
  const [rate, setRate] = useState()
const [isApproved, setIsApproved] = React.useState(false);
  const [isProfileVisible, setIsProfileVisible] = React.useState(false);
  const showProfileModal = () => {
    setIsProfileVisible(true);
  };

  const hideProfileModal = () => {
    setIsProfileVisible(false);
  };
  const hideModal = () => {
    setIsOpen(false);
  };
  const handleApprove = async (value) => {
    setIsApproved(value)
    if (!rate) {
      setIsOpen(true)
    } else 
    try {
     await approve({
        user_id: data.id,
        approved: value,
        rate,
        type:'influencer',
      })
      setIsOpen(false)

        toast.success('Influencer approved successfully!', {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              });
      refetch()
    }
    catch (e) {
        toast.error('Error approving influencer', {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              });
      console.error(e)
    }
   
  };


  const handleReject = async (value) => {
    setIsApproved(value)
   
      try {
        await approve({
          user_id: data.id,
          approved: value,
          rate,
          type: 'influencer',
        })
        setIsOpen(false)

        toast.success('Influencer rejected successfully!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        refetch()
      }
      catch (e) {
        toast.error('Error rejecting influencer', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.error(e)
      }

  };

    const showDeleteConfirm = async(id) => {

      try{ confirm({
         title: 'Delete\ user?',
         icon: <ExclamationCircleFilled />,
         content: 'Are you sure you want to delete this user',
         okText: 'Yes',
         okType: 'danger',
         cancelText: 'No',
         onOk: async()=> {
          const {data} = await del({id,scope:'user'})
          refetch()
          
           // Display success toast
   toast.success('User Deleted', {
       position: 'top-right',
       autoClose: 3000,
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,

     });

         },
         onCancel() {
           console.log('Cancel');
         },
       });

     }catch(e){
       console.error('Error during confirmation:', e);
     }
     };
    return (
       <div key={data.id}   className="tred"> 
      
        <div className="flex-item icont" onClick={showProfileModal}>
          <Avatar src={data.image || userImage}/>{' '}
          <div className="" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }} >{data.username}</div>
          </div>
        <div className="flex-item" style={{ whiteSpace: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>{data.email}</div>
        <div className="flex-item">{data.phone}</div>
        <div className="flex-item flex-wrap" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>
  {data.address ? data.address : 'none'}
</div>

<div className="flex-item"  style={{ }}>
          {data.influencer_rate ? data.influencer_rate : 'N/A'}
      </div>
        {Influencer ? <div className="flex-item d-flex ">
         <div onClick={()=>handleApprove(true)} className="action d-flex unapproved">Approve</div>
          <div onClick={() => handleReject(false)} className="action decline d-flex">Decline</div>      
        </div>: <div className="flex-item d-flex edit">
        <img onClick={()=>showDeleteConfirm(data.id)} src={bin}  />
       { <img onClick={()=>setOpen(!open)} src={edit} style={{ width: 30, marginLeft: 10 }} />}
        </div>  }
        <EditUser refetch={refetch} setOpen={setOpen} isOpen={open} data={data}/>
        <Modal
          title="User Profile"
          visible={isProfileVisible}
          onCancel={hideProfileModal}
          footer={[
            <Button key="back" onClick={hideProfileModal}>
              Close
            </Button>
          ]}
          width={800}
        >
          {/* Render Vendor Profile Details Here */}
          <div>

         <UserProfile user={data}/>
          </div>
        </Modal>

        <Modal
          title="Set Influencer rate"
          open={isOpen}
          onCancel={hideModal}
          footer={[
            <Button loading={isLoading} className="unapproved" key="back" onClick={()=>handleApprove(isApproved)}>
              continue
            </Button>
          ]}
          width={400}
        >
          <h6 className="mt-[20px] text-darkgreen  ">Enter the rate</h6>
          <Input
            className={'flex-1 rounded-xxl  w-[50%] active:border-lightgreen border rounded-[20px]  p-2 h-[40px] '}
            placeholder={'rate'}
            value={rate}
            onChange={(e) => setRate(e.target.value)}

          />
        </Modal>
        </div>
    );
};
export default UserListRow;