import React from 'react';
import { Toast } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


function AlertCont(){
  const notify = () => toast("Wow so easy !");

  return (
    <div>
      <ToastContainer />
    </div>
  );
}
export default AlertCont