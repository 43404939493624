import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import PartnerForm from "./PartnerForm";

const PartnerMailchimpFormContainer = () => {
  const postUrl = `https://trypamp.us10.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_PARTNER_U}&id=${process.env.REACT_APP_MAILCHIMP_PARTNER_ID}`;

  return (
    <MailchimpSubscribe
      url={postUrl}
      render={({ subscribe, status, message }) => (
        <PartnerForm
          status={status}
          message={message}
          onValidated={(formData) => subscribe(formData)}
        />
      )}
    />
  );
};

export default PartnerMailchimpFormContainer;
