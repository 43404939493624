import TabView from "./Tabview";
import React from "react";
import UserUpdate from "./UserUpdateCont";
import VendorUpdate from "./VendorUpdate";


function UpdateCont({data}){
    const tabs=[{
        title:'Request',
        content: <UserUpdate data={data? data.vendors.last_8:[]}/>
      },
      {
        title:'Users',
        content: <UserUpdate data={data?data.users.last_8:[]} />
      }]
    return(
        <><h2 className="update-title mt-[90px]">Latest Updates</h2><TabView tabs={tabs} /></>
    )
}

export default UpdateCont