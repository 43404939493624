const privacyData= [
    {
      title: "Introduction",
      content: [
        "At Pampdigital (referred to as 'Pamp', 'we', 'us' and 'our'), we are committed to protecting the privacy of our users. If you are in Ghana or the rest of the world, we are the data controller, unless otherwise stated. Please read the following privacy policy that explains how we collect, manage and protect your personal information.",
        "This is the Privacy (and Cookies) Notice ('Notice') for the website www.trypamp.com , and all Pamp affiliate sites ('Sites'), or our applications ('Apps') (together 'Services').",
      ],
    },
    {
      title: "Contacting us",
      content: [
        "If you have any questions, comments and requests regarding this Notice you can get in touch with us via our Contact Us page, or via email at support@trypamp.com.",
      ],
    },
    {
      title: "What information do we collect & how do we use it?",
      content: [
        "This Privacy Notice will apply to you if:",
        "you visit and browse our Sites and Apps.",
        "you book appointments with our Vendors through us (we'll refer to you as a 'Client').",
        "you are self-employed and/or you work for a business on the Pamp platform (we'll refer to you and/or the business as a 'Vendor' or 'Partner').",
        "We will only use the information we collect about you if there is a reason for doing so, and if that reason is permitted under data protection law. We say what these reasons usually are in each section below, and we explain each one under 'What do each of these legal reasons mean?'.",
      ],
    },
    {
      title: "If you visit our services",
      content: [
        "We use your information to provide you with our Services. If you visit any of our Services, whether you're just browsing or you have an account, we will automatically collect information from you each time you use our Sites.",
        "This includes technical information, information about your visit and (if you opt-in) location data:",
        "Technical information may include the phone number, Internet Protocol (IP) address, login information, browser type and version, browser plug-in types and versions, device IDs, social log-in ID/email address, time zone setting, operating system and platform, hardware version, device settings (e.g. language and time zone), file & software names and types (associated with your device and/or the Services), battery & signal strength, information relating to your mobile operator or Internet Service Provider (ISP).",
        "Information about your visit may include the full Uniform Resource Locators (URL), clickstream to, through and from our Site (including date and time), pages and services you viewed or searched for, demographic information (including age and gender), page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), traceable campaign links (e.g. in emails, or via tracking URLs), methods used to browse away from the page, and any phone number used to call our customer service number or social media handle used to connect with our customer service team and our social media accounts.",
        "Location data includes country location (based on your full or partial IP address and/or Google Analytics information) which we use to provide location services (if you ask or permit us to), so that we can deliver content, advertising or other services that are dependent on knowing where you are, like checking for fraudulent transactions.",
        "Location data may be collected in combination with device ID, so we can recognise your mobile browser or device when you return to the Service.",
        "Delivery of location services will involve us checking any of the following:",
        "- the coordinates (latitude/longitude) of your location,",
        "- your current country or region, by referencing your current IP address against public sources, and/or",
        "- your Identifier for Advertisers (IFA) code for your Apple device, or the Android ID for your Android device, or a similar device identifier.",
        "You can opt-in and out of location sharing by changing your device settings",
        "We use this automatically collected information to",
        "- understand how individuals use our Site and App, and how we can improve it;",
        "- ensure content from our site is presented in the most effective manner for you and for your devices; and",
        "- provide you with the information, products and services that you request from us or we think you may be interested in.",
        "Reasons: legitimate interests | consent",
      ],
    },
    {
      title: "If you contact or engage with us",
      content: [
        "If you contact or engage with us, we will collect your contact information, and the other communications information you provide.",
        "Contact information includes basic contact information you provide:",
        "- email address,",
        "- and anything else you include, for example:",
        "  - first and last name,",
        "  - phone number(s),",
        "  - social media handle (for example, if you engage with us on social media).",
        "Communications information includes your correspondence with us, for example if you get in touch with our Customer Service team about a booking or to report a problem with our Site or App. This includes:",
        "- emails,",
        "- texts, in-app messaging & other digital messaging,",
        "- calls,",
        "- letters,",
        "- any in-person conversations you have with us.",
        "We use this contact and communications information to",
        "- Contact you if you have asked us to do, including to respond to your queries, troubleshoot problems, and help with any issues you may have with our Services.",
        "- Provide you with information you might request about our Services.",
        "- Put you in touch with Vendors you ask us to share your information with.",
        "- Provide you with technical and other service updates (for example, if we update our Terms of Use and Terms of Service).",
        "Reasons: contract with you | legitimate interests | consent",
      ],
    },
    {
      title: "If you use our services to book an appointment",
      content: [
        "When you create a Pamp account using any of our Services, we may collect your:",
        "- first and last name;",
        "- date of birth",
        "- gender",
        "- email address;",
        "- address;",
        "- mobile phone number(s),",
        "- your photo, if uploaded (optional).",
        "If you choose to use your Google, Facebook, or Apple account to sign into Pamp, we will get an access token that enables this sign-in process (granting us access to your first and last name, email, user ID and (apart from Apple) your profile photo).",
        "We assign unique user IDs or other account-level IDs to your Pamp account.",
        "As you use your account, we may collect information about your:",
        "- purchase history, including service orders or appointment bookings, or voucher purchase and use;",
        "- favourites (e.g. your favourite salon);",
        "- account settings;",
        "- social login information;",
        "- securely saved payment methods.",
        "We may also collect information, if you provide it, about"
      ]
    },
    {
        title: "If you work for a Vendor",
        content: [
          "If you work for our Vendor (e.g. as an employee, contractor, or worker), we may collect contact and company information from you:",
          "Contact information may include:",
          {
            type: "bullets",
            content: [
              "first and last name,",
              "role title,",
              "company name,",
              "email address,",
              "contact number,",
              "log-in details,",
              "KYC and ID verification results (we do not keep any underlying documents).",
            ],
          },
          "Company information may include:",
          {
            type: "bullets",
            content: [
              "company address,",
              "business type (e.g. salon, spa, gym),",
              "consultation form content,",
              "historical and future appointments,",
              "payment processing data (we don't see this, only our payment processors do),",
              "reporting and analytics,",
              "Client data and promotions;",
              "pictures and marketing materials.",
            ],
          },
          "We also process other information on behalf of your company that is not personal data (for example, financial data).",
          "We use this contact and company information to manage the Vendor's account with us, including to",
          {
            type: "bullets",
            content: [
              "maintain the Vendor's account,",
              "provide Vendor customer support, including technical support for our suite of business tools,",
              "enable the Vendor to use our suite of business tools, including managing staff and appointment scheduling,",
              "enable the Vendor to make transactions and manage payments,",
              "enable the Vendor to list on the Pamp's marketplace",
              "may contact Vendor staff to enable them to create a Vendor-specific account with appropriate authorisations, and to manage those accounts.",
            ],
          },
          "Reasons: contract with you | legitimate interests",
          "The Vendor can access and manage your Pamp account details.",
          "Vendors may also control what information Clients can see about the Vendor, including order or booking information, staff names and certain profile information, offers, ratings and reviews (which may talk about staff members), prices and discounts, appointment availability, and related data.",
          "The Vendor you work for is the data controller for this information. If you have any questions, please contact the Vendor you work for.",
        ],
      },
      {
        title: "If we send you offers, updates or other marketing material from us",
        content: [
          "We may collect contact information (as explained above), like your name and email address.",
          "We may also collect marketing preferences, which are our records of what information you would or would not like to receive from us, and if you have opted out of any direct marketing.",
          "We use this contact and marketing preference information to",
          {
            type: "bullets",
            content: [
              "send you offers, updates, promotions, newsletter(s), insights and other marketing material.",
              "send you with personalized marketing, including offers, features, services, and Vendors we think you may be interested in.",
              "If you work for a company we are interested in Vendoring with, we may contact you about our Services and opportunities with Pamp.",
              "Contact you to ask for feedback, including through surveys and other marketing research.",
            ],
          },
          "You can opt-out of further marketing at any time by selecting the “unsubscribe” link at the end of all our promotional updates.",
          "Reasons: legitimate interests | consent",
        ],
      },
      {
        title: "What do each of these legal reasons mean?",
        content: [
          "We must have a relevant legal justification, called a 'lawful basis' for each way in which we use your personal information.",
          "Lawful bases may include consent, a contract with you (as a data subject), compliance with our legal obligations and our specified legitimate interests.",
          "Consent",
          "We'll use your personal information to send you promotional or marketing content (for example, our insight updates or newsletters), if you have given us consent to us doing so, where required by law. We may also send direct marketing based on our legitimate interests (see below).",
          "You can opt-out of further marketing at any time by selecting the “unsubscribe” link at the end of all our promotional updates and marketing to you.",
          "We also rely on consent for some of the cookies we use (see our Cookies Notice for more detail).",
          "Contract",
          "We collect, store and process your personal information if it is necessary for performing a contract you have with us (for example, our Terms of Use and Terms of Service), or where you have asked us to take specific steps before entering into that contract. This includes notifying you about changes to our Services. This does not include any agreement we have with a Vendor, whether you are a Booker or Vendor staff – this is based on our legitimate interests (see below).",
          "Legal obligation",
          "We may need to process any of your personal information to comply with our legal obligations, including under applicable Ghana law, and/or any court orders. This may include compliance with know-your-client and anti-money laundering rules.",
          "Legitimate interests",
          "We may process your personal information if it is necessary for our legitimate interests or the legitimate interests of a third party, provided those interests are not outweighed by your rights and interests.",
          "Our legitimate interests include:",
          {
            type: "bullets",
            content: [
              "Administering, improving and expanding our Site and services",
              "Keeping our records updated.",
              "Gaining insights on how you use our Services.",
              "Gaining your feedback and reviews,",
              "Delivering, developing and improving our Services.",
              "Enhancing, customising or modifying our Services and communications.",
              "Implementing and improving our security measures",
              "Growing our business and informing our marketing strategy.",
              "Marketing and promoting our Services to a company you work for or provide services to.",
              "measuring or understanding the effectiveness of advertising we serve to you and others and delivering relevant advertising to you (including when you visit other websites).",
              "Fulfilling agreements with another organisation",
              "Complying with or enforcing any agreement Pamp has (or that you may have, or example any extra Vendor-specific terms) with a Vendor you have purchased services from as a Client.",
              "Complying with or enforcing any agreement we may have with a company you work for or provide services to (for example, if you work at a Vendor).",
            ],
          },
          "In each case, these legitimate interests are only valid if they are not outweighed by your rights and interests. If you would like further information about how we assess our legitimate interests, please contact us at support@trypamp.com.",
          "We also use different types of cookies on our Site – we explain this in the Cookies Notice.",
        ],
      },
      {
        title: "Who do we share your information with?",
        content: [
          "If you are a Client",
          "We will share your information with our Vendors if you have asked us to (for example, if you want to connect with or make a booking with one of our Vendors). We may share your contact and account information, and they will receive any other information you provide through Pamp (for example, completing a Vendor's consultation form). Vendors may use this information to manage your communications and bookings with them.",
          "If you leave a public review for a Vendor, your rating, review, review date, and first name and last initial will be published on Pamp. Other Pamp users and members of the public may be able to see your reviews.",
          "If you work for a Vendor",
          "Pamp users may rate the Vendor you work at or for.",
          "We may publish limited Vendor information (including individual and business public reviews and ratings) to other websites, for example so it can be included in search results or advertising (including online and offline advertising and promotional media, including, but not limited to Google, Google AdWords, Facebook Ads, Twitter, email distribution lists, and third-party business Vendors and affiliates).",
          "If Pamp processes your information as a Client, Vendor staff, or otherwise",
          "We may also share your information with:",
          {
            type: "bullets",
            content: [
              "any member of our company group, which means our ultimate holding company and its subsidiaries, and/or our subsidiaries who may support our processing of personal information under this Notice.",
              "our service providers, organisations who process your personal data on our behalf and in accordance with our instructions and the data protection law.",
              {
                title: "This includes supporting the services we offer through the Site/App, in particular those providing:",
                type: "bullets",
                content: [
                  "website and data hosting services",
                  "distributing communications we send",
                  "supporting or updating marketing lists",
                  "customer support",
                  "facilitating feedback on our services",
                  "digital invoicing and payment processing providers who provide secure payment processing services",
                  "providing IT support services from time to time.",
                ],
              },
              "These organisations (which may include third party suppliers, agents, sub-contractors and/or other companies in our group) will only use your information to the extent necessary to perform their support functions.",
              "if we run surveys, competitions, promotional campaigns, offers or other occasional activities and you opt-in, we may share certain information with our commercial Vendors (for example, if you chose to enter into a prize draw we run with a third party). We will provide more detailed privacy information at the time.",
              "our auditors, legal advisers and other professional advisers;",
              "if we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer.",
              "any person to whom disclosure is necessary to enable us to protect our rights, property, or safety, our clients, or other third parties, and to enforce our rights under this Notice or under any agreement (for example, our Terms of Use and Terms of Service) with you. This includes exchanging information with other companies and organisations for the purposes of detecting and preventing fraud and cyber-crime.",
              "if required to do so by court order or if we are under a duty to disclose your information in order to comply with (and/or where we believe we are under a duty to comply with) any legal obligation. This includes exchanging information with law enforcement agencies, regulators, or other similar government bodies.",
            ],
          },
        ],
      },
      {
        title: "Where do we store your information?",
        content: [
          "Pamp operates in Ghana and other African countries. We generally don't share personal data between group companies.",
          "We may transfer your personal information outside Ghana:",
          {
            type: "bullets",
            content: [
              "in order to store it;",
              "in order to enable us to provide our Services to you;",
              "in order to facilitate the operation of our businesses, where it is in our legitimate interests and we have concluded these are not overridden by your rights; and",
              "where we are legally required to do so.",
            ],
          },
          "We will put legal protections in place to safeguard personal data transfers in compliance with data protection laws.",
          "We may transfer your personal information outside Ghana, including to the key organisations listed here.",
          "We update our Vendors and service providers as we grow, and will update this Notice regularly. For more information about how we currently transfer and protect data, please contact support@trypamp.com.",
        ],
      },
      {
        title: "How do we protect your information?",
        content: [
          "All information you provide to us is securely stored. Our app and web app use secure end-to-end encryption to protect your information. All connections into our platform are secured using industry standard security and encryption.",
          "All data we capture is stored in secured databases and data storage systems with strict access limitations. All data access requests are logged and monitored in accordance with any threat detection policies.",
          "Unfortunately, the transmission of information via the internet is not completely secure. We do our best to protect your personal information, but we cannot guarantee the security of your data transmitted to us – even if there is a special notice that a particular transmission (for example, credit card information) is encrypted. Any transmission is at your own risk. Once we have received your information, we use strict procedures and security features to try to prevent unauthorized access.",
        ],
      },
      {
        title: "Payment processing",
        content: [
          "Payments made on our Services are made through our payment gateway provider, Paystack. You will be providing credit or debit card mobile money information directly to Paystack, which operates a secure server to process payment details, encrypting your credit/debit card information and authorizing payment. Paystack can view pseudonymized cardholders and card details. In addition, Paystack stores the following card information:",
          {
            type: "bullets",
            content: [
              "card brand,",
              "card holder name,",
              "card's last four digits,",
              "card expiry month and expiry year.",
            ],
          },
          "Information which you supply to Paystack is not within our control and is subject to Paystack's own privacy policy and terms.",
        ],
      },
      {
        title: "External sites",
        content: [
          "Pamp may, from time to time, contain links to external sites. If you follow a link to any of these websites, please note that these websites have their own privacy policies. Pamp does not accept any responsibility or liability for these policies or any content on external sites and does not necessarily endorse the views expressed within them. Pamp has no control over the availability of any of these websites. Please check each site's policies before you submit any personal information to these websites.",
        ],
      },
      {
        title: "How long is your information kept for?",
        content: [
          "We will generally process personal information:",
          {
            type: "bullets",
            content: [
              "for as long as it is necessary for the purpose(s) for which it was originally collected (for example, holding it on behalf of a Vendor, or for as long as you have an account with us), and",
              "for up to six years after that to identify any issues and resolve any legal proceedings.",
            ],
          },
          "We may retain your personal data for a longer period in the event of a complaint, if we reasonably believe there is a prospect of legal proceedings, or we are aware of pending or ongoing legal proceedings. In some circumstances, we may keep data longer if applicable law says we have to.",
          "If you have opted to receive insights, newsletters, or other promotional material, but later decide to opt out from receiving these updates, or object to any other processing of your personal information, we may keep a record of your opt-out or objection so we can respect your preferences.",
          "We may keep aggregated, anonymized or pseudonymized data – for example, for reporting and analytics – for longer periods.",
        ],
      },
      {
        title: "Aggregated data",
        content: [
          "In some circumstances, we will anonymize your personal data (so that it can no longer be associated with you and you cannot be re-identified). This anonymized data (for example, aggregated statistics) is no longer personal data, and we may keep and use this anonymized information indefinitely without further notice to you.",
          "We use this to help us provide, develop and improve our services, including to:",
          {
            type: "bullets",
            content: [
              "better understand how people use our Services,",
              "provide our Vendors and Clients with information about our Services, including user statistics (e.g. total numbers, broad demographics statistics), and",
              "develop useful insights and improvements to the Services.",
            ],
          },
        ],
      },
      {
        title: "What rights do you have with your personal information?",
        content: [
          "In certain circumstances, you have the following rights:",
          {
            type: "bullets",
            content: [
              "to be provided with a copy of your personal information held by us;",
              "to request the correction or deletion of your personal information held by us;",
              "to request that we restrict the processing of your personal information (while we verify or investigate your concerns with this information, for example);",
              "to object to the further processing of your personal information, including the right to object to marketing;",
              "to request that your provided personal data be moved to a third party, and",
              "to withdraw consent.",
            ],
          },
          "If you wish to exercise any of these rights in relation to the personal information we hold about you, you can contact us at support@trypamp.com. If you have any concerns, you have the right to lodge a complaint with a data protection supervisory authority.",
          "The Information Commissioner's Office (ICO) is the supervisory authority in Ghana and can provide further information about your rights, an organisation's obligation in relation to your personal information, as well as deal with any complaints that you may have. You can visit their website at www.dataprotection.org.gh.",
          "If you are outside Ghana, you can find your local data protection authority here.",
        ],
      },
      {
        title: "Updating this Privacy notice",
        content: [
          "This Notice was last updated on 1st November 2023.",
          "We may update this Notice from time to time and will post any changes on this page.",
          "If we make any substantive changes, we will notify you through email, or website pop-ups within our Site/App.",
        ],
      },
      // ... (continuation of the data structure)
      

      
      
      
]

export default privacyData