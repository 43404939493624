import React from "react";
import Table from "../tables/Table";
import TableHeadItem from "../tables/TableHead";
import { Affix } from "antd"

import WalletRow from "../tables/WalletRow";

//import { useGetApprovedVendorsQuery } from "../redux/user";
const Wallets=({data,refetch})=>{
    const theadData = ["Vendor",'Balance', "Currency", "Status", 'Updated','Action'];
//    const{ data,isLoading}= useGetApprovedVendorsQuery()
   // console.log(data&&data)
    return (
        <div className="table-head-container">
           <Affix offsetTop={200} className="">   
             <div className="table-head">
     
          {theadData.map((h) => {
            return <TableHeadItem key={h} item={h} />;
          })}
        </div>
        </Affix>
        <div className="reqbody">
        {data.map((item) => {
                    
                    return ( <WalletRow key={item.id} refetch={refetch} data={item} />);
                })} 
        </div>
      </div>
      
    );
}

export default Wallets