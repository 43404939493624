import React from 'react';
import business from '../assets/svgs/businesDownload.svg'
import suitcase from '../assets/svgs/suitcase.svg'
import ScanDesktop from './ScanDesktop';
import ScanDesktop2 from './ScanDesktop2';
import ScanMobile from './ScanMobile';
import mobusi from '../assets/images/Receive.png'

const data = [
  { color: 'green-500', text: 'List your services.' },
  { color: 'blue-500', text:  (<>
  Manage your booking
<br />calendar.
</>)},
  { color: 'yellow-500', text:  (<>
Keep track of your 
  <br />payments.
  </>) },
  { color: 'purple-500', text: 'And more.' },
];

const BusinessDownload = () => {
  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

  return (
    <div style={{}}  className='relative  p-[10px] md:p-[20px] rounded-[20px] flex-1'>
      <div id='dcont'  className="mt-10 h-[433px] bg-darkgreen  md:h-[730px] relative mx-auto rounded-[30px] p-[20px]  " style={{width:'95%'}}>
        <h3 className='text-white text-[26px] md:text-[40px] text-source-san-pro pt-[40px] ml-[10px] md:ml-[50px]'>For Business</h3>
        <ul className="list-none pt-[20px] md:ml-[10px] ml-[-25px]  md:pt-[50px]">
          {data.map((item, index) => (
            <li key={index} className="flex  sm:mb-2 md:mb-[20px] animate-fade-in">
              <div className={`rounded-full mt-[5px] md:mt-[10px]  bg-dotgreen h-2 w-2 md:h-3 md:w-3 mr-3`}></div>
              <div className='text-white text-[13px] md:text-[24px] text-source-san-pro'>{item.text}</div>
            </li>
          ))}
        </ul>
      
        <div id='appsvg' className='absolute top-[0px] md:top-[0px] right-[10px]'>
        <img
        src={isMobile?mobusi:business}
        className='h-[480px] w-[180px] md:w-[290px] md:h-[690px]  '
        />
</div>
<img
        src={suitcase}
        className='ml-[60px] mb-[20px] h-[35px] md:h-[67px]'
        />
       
        <div className='w-full pr-[10px] pl-[30px]  ml-[20px] absolute bottom-[15px] md:bottom[20px] right-[10px]'>
              <div id='desk2' className='hidden desk '>
              <ScanDesktop2/>

              </div>
             <div id='mobile2' className='mobile'>
             <ScanMobile business={true}/>
              </div>  
        </div>
      </div>
   
    </div>
  )
}

export default BusinessDownload;
