const partnerTermsOfBusiness = [
    {
        title: "Introduction",
        content: [
            "Our summary uses capitalised terms which are defined in the Partner Terms of Business. We've summarised some key terms here, but the full version is authoritative."
        ]
    },
    {
        title: "Key Terms",
        content: [
            "Pampdigital Platform means our Pampdigital app(s).",
            "Pampdigital Services means the Pampdigital services set out at [www.trypamp.com/pricing](https://www.trypamp.com/pricing).",
            "Pampdigital Business Software means the Pampdigital Partner app.",
            "Pampdigital Booking Services means appointment booking services we offer through our Pampdigital Platform.",
            "Pampdigital Payment Services means payment services we offer through our Pampdigital Platform.",
            "We are responsible for letting people make Pampdigital Bookings with Online Payments. You appoint us as your commercial agent under these Partner Terms of Business so we can do this for you.",
            "For Pampdigital Bookings with Online Payments, we will collect payments from Clients on your behalf. When a Client pays Pampdigital, this discharges that Client's debt to you.",
            "For Pay with Cash In-Store Bookings, we will collect 20% deposit as down payment for you and the 80% will be collected in-store (cash).",
            "We will send you an Invoice Statement once a month, which will set out what we owe you, what you owe us (e.g. Service Fees and any other applicable fees or charges) and the resulting balance (whether you owe us, we owe you or the balance is settled).",
            "If we owe you, we will pay this amount to your bank account.",
            "If you owe us, we will charge the credit/debit card you supplied on the Pampdigital Business App.",
            "If the balance is settled, we will explain on the Invoice Statement that nothing further is needed.",
            "If you have any issues with paying your outstanding balance within the required timeframe, please get in touch as soon as possible. We may pause or stop access to the Pampdigital Business App or to the Pampdigital Platform if you are behind on payments."
        ]
    },
    {
        title: "What is covered in these terms",
        content: [
            "These are the Pampdigital Partner Terms of Business (which is also referred to as the Agreement). Pampdigital and Partner may each be referred to as a “vendor”, or together as \"parties\".",
            "We provide a booking platform called the Pampdigital Platform (Pamp Partner), where you can advertise your Partner Services to a range of potential customers. As part of the Pampdigital Platform, Partners have a licence to use our business management software which comes with a broad range of features.",
            "We offer Pampdigital Booking Services, which enables you to manage Client calendar bookings, including with multiple staff and locations.",
            "We also offer additional services, which include more advanced features, including appointment booking software, payment services, marketing and messaging support and professional services.",
            "You are responsible for the Partner Services which you provide to Clients, and the contract for those Partner Services is between you and the Client. We are in no way liable to Clients for the Partner Services they receive from you.",
            "You have a number of obligations to Pampdigital in return for receiving the Pampdigital Services. These are set out in detail in the full version, but some important ones are as follows:",
            "You agree to process and supply the Partner Services to the highest industry standards.",
            "You must ensure that you have all licenses, consents, permits, qualifications, authorities & insurances that are required for you to perform the Partner Services.",
            "In respect of Pay with Cash In-Store Bookings, Pampdigital is solely a technology provider and does not act as a commercial booking agent. Clients and Partners can cancel Pay with Cash In-Store Bookings within the 72hr cancellation period, however if the client and partner cancels the appointment on the same day it would be labelled as late cancellation and the client or/and partner will lose the 20% deposit and no contract is created between the Client and the Partner until the appointment takes place.",
            "Confirmation and reminder emails will be sent to Clients via the Pampdigital Business App on behalf of the Partner for each Pampdigital Booking. However, the Partner is responsible for providing the Partner Services to the Client and handling any cancellations or rescheduling directly with the Client or via the Pampdigital Business App itself. Pampdigital has no further involvement in the Pay with Cash In-Store Bookings process and solely provides the technology to facilitate Pay with Cash In-Store Bookings to be made.",
            "You must ensure that all information supplied to your Online Partner Profile is accurate, not misleading and legally compliant.",
            "Your cancellation and rescheduling terms regarding Pampdigital Bookings must comply with Pampdigital’s Terms of Service and applicable consumer law.",
            "You are responsible for the Partner Services which you provide to Clients, and the contract for those Partner Services is between you and the Client. We are in no way liable to Clients for the Partner Services they receive from you.",
            "You have a number of obligations to Pampdigital in return for receiving the Pampdigital Services. These are set out in detail in the full version, but some important ones are as follows:",
            "You must at all times provide the Partner Services as set out in these Partner Terms."
        ]
    },
    {
        title: "How you can reach us",
        content: [
            "If you want to talk to us, please get in touch with our team, and we'd be happy to help:",
            "Email: partners@trypamp.com"
        ]
    },
    {
        title: "Age requirements",
        content: [
            "You must be at least the age of majority or older in your jurisdiction of residence in order to use the Pampdigital Services. If you live in a country or area that limits the use of the Pampdigital Services because of your age or restricts the ability to enter into contracts such as this one due to age, you must abide by such age limits, and you must not use the Pampdigital Services, or only do so with your parent or legal guardian’s permission. Please have your parent, or legal guardian read these terms with you.",
            "If you are a parent or legal guardian, and you allow your child or dependant to use these Pampdigital Services, then these terms apply to you, and you are responsible for your child’s or dependant's activity on the Pampdigital Platform."
        ]
    },
    {
        title: "Your relationship with Pampdigital",
        content: [
            "The following terms define your relationship with Pampdigital and give you permission to use all Pampdigital Services if you agree to the following terms, which explain how the Pampdigital business works and how we partner with you."
        ]
    },
    {
        title: "What you can expect from us",
        content: [
            "Pampdigital provides Pampdigital Services to enable you to manage Client calendar bookings, including but not limited to: multiple staff and locations.",
            "In consideration of Partner's payment of the Service Fees and the Partner performing all of its obligations under this Agreement, Pampdigital shall provide the applicable Pampdigital Services to the Partner, as further described in this section.",
            "The Partner understands and acknowledges that Pampdigital is a commercial booking agent and does not provide the Partner Services to the Client. The contract for the Partner Services is between the Partner and the Pampdigital Client, and as a result, it is the Partner's responsibility, if the Partner is registered for indirect taxes, to charge indirect tax on the total value of the Pampdigital Booking and to provide a tax receipt to the Client, if requested. Pampdigital only charges indirect tax to the Partner on the Service Fees, in consideration for the provision of the Pampdigital Services.",
            "In the event of fraudulent or alleged fraudulent activities by the Partner or if Pampdigital is required by law, court order, governmental instruction, arbitrational decision or by its cancellation policy to make a refund of all or part of a Pampdigital Booking, Pampdigital reserves the right to claim a repayment from the Partner of any amount required to be repaid by Pampdigital to the Client and for any Bank Charges relating thereto."
        ]
    },
    {
        title: "How Partners obtain the licence for the use of the Pampdigital services",
        content: [
            "Subject to payment of Service Fees (if applicable), the Partner may use the Pampdigital Services to process Pampdigital Bookings (or direct bookings by Partner Clients) of Partner Services for and on behalf of itself only.",
            "As part of the licence terms for the Partner to use the Pampdigital Services (including, as applicable, the Pampdigital Business App, the Pampdigital Platform, and the Partner Profile), the Partner agrees not to do any of the following:",
            {
                type: "bullets",
                content: [
                    "reproduce, duplicate, copy, sell, resell or exploit the whole or any part of the Pampdigital Services;",
                    "allow any third party (including group companies of the Partner) to use or access the Pampdigital Business App without express prior written permission from Pampdigital (which may be denied or granted on such terms as Pampdigital, in its sole discretion, may determine);",
                    "send unsolicited emails, SMS or other electronic forms of marketing to Clients via the Pampdigital Business App (or otherwise send any direct marketing which does not comply with applicable Data Protection Legislation); or",
                    "disclose, share or resell any login details or passwords for the Services."
                ]
            }
        ]
    },
    {
        title: "How we work with third-party and affiliate websites and applications",
        content: [
            "Pampdigital may publish or promote any information acquired under this Agreement, including calendar availability, business information, service descriptions and prices, on any third-party websites or mobile applications, particularly through the application program interfaces (APIs). Pampdigital may supply affiliates and other third parties with other APIs to promote particular Partner Services. Pampdigital is not obliged to promote all Partner Services using these third-party and affiliate websites."
        ]
    },
    {
        title: "How we access and use the content of our Partners",
        content: [
            "The Partner hereby grants Pampdigital, free of charge, a non-exclusive, royalty-free, irrevocable and worldwide right and licence (or sublicense as applicable) to use, reproduce, distribute, sublicense, communicate and make available content that Partners publish on the Pampdigital Services and for any other purposes which are necessary for Pampdigital or required by Pampdigital to exercise its rights and perform its obligations under this Agreement.",
            "The Partner warrants that the Partner owns the Partner Content and/or is otherwise entitled to grant the foregoing licence.",
            "Pampdigital may sublicense, make available, disclose and/or offer the Partner content to Affiliates and third parties. In no event shall Pampdigital be liable to the Partner for any acts or omissions on the part of any third-party platforms. The sole remedy available to the Partner in respect of such third-party platforms is to:",
            {
                type: "bullets",
                content: [
                    "request Pampdigital (which has the right and not the obligation) to disable and disconnect with such third-party platforms in respect of the Partner; or",
                    "terminate this Agreement, in accordance with the Suspending or terminating your access to Pampdigital section."
                ]
            },
            "The Partner hereby grants Pampdigital the right to:",
            {
                type: "bullets",
                content: [
                    "remove, edit, cut down or otherwise amend content supplied by the Partner that is published on the Partner Profile or elsewhere on the Pampdigital Platform, including where such content does not, in Pampdigital's reasonable opinion, comply with the warranties identified in this section, or is otherwise in breach of the terms of this Agreement; and",
                    "use search engine optimisation services, pay-per-click advertising, and other mechanisms that embody, incorporate or quote (in whole or part) the trading name of the Partner or any brands used in connection with the Partner Services."
                ]
            },
            "To manage the Pampdigital Services (including the Pampdigital Platform, Partner Profile and any Partner Content), Pampdigital may, at its absolute discretion and from time to time, amend the format, content, and style of Partner page descriptions, photos, and service menus."
        ]
    },
    {
        title: "What we expect from our Partners",
        content: [
            "The Partner understands and accepts that the Pampdigital Services (specifically, Partner Profile, as updated by Pampdigital from time to time) are labelled 'Powered by Pampdigital', contain Pampdigital branding (including logos, trademarks and images), and will give Clients the opportunity to opt-in to receiving email marketing from the Partner and from Pampdigital;",
            "Pampdigital will provide the Partner with a user account and password, which allows the Partner to access the Pampdigital Business App. The Partner shall safeguard and keep the user account details and password confidential and safely stored and shall not disclose them to any person other than those who need to have access to the Pampdigital Business App and who are aware of the Partner's obligations to keep those details secure. Partner is solely responsible for managing access to its user account, including by its employees, contractors and other staff. Partner shall change the password in the event anyone with access to the user account leaves the Partner's business or no longer requires access. The Partner shall immediately notify Pampdigital of any suspected security breach or improper use, including any use which would breach this Agreement, Pampdigital's reasonable instructions given from time to time and/or applicable law.",
            "In consideration of receiving the Pampdigital Services, the Partner agrees to pay all applicable Service Fees to Pampdigital and to provide all Pampdigital Bookings and process and supply the Partner Services to the highest industry standards and in line with any specific terms and conditions set out in this Agreement, and particularly in this section."
        ]
    },
    {
        title: "Pampdigital services",
        content: [
            "Pampdigital booking services",
            "Pampdigital provides Pampdigital Services to make it easy for Clients to book appointments via the app with you and other participating partners. The Pampdigital Booking Services include a consolidated list of Partner Services, and Clients may discover, review, and book these Partner Services using the Pampdigital Booking Services.",
            "You may elect to offer certain Partner Services for sale through the Pampdigital Booking Services. At any time, you can update which Partner Services you would like to sell through the Pampdigital Booking Services.",
            "You can make these changes through your Partner Account, and the changes will automatically be updated on the Pampdigital Platform.",
            "When a Client makes a Pampdigital Booking, the Partner Terms of Sale of the Pampdigital Booking will be made available to the Client and will be sent to the Client and the Partner by Pampdigital electronically. The Client will be required to agree to the Partner Terms of Sale before confirming a Pampdigital Booking.",
            "You appoint Pampdigital as your agent to accept Pampdigital Bookings made by Clients.",
            "In respect of all Pampdigital Bookings with online payments, the Partner appoints Pampdigital as its agent to collect and process payments (including any applicable taxes) on behalf of the Partner. The Partner remains fully responsible and liable for the provision and supply of Partner Services to Clients. Pampdigital is solely a technology provider with respect to Pay with Cash In-Store Bookings."
        ]
    },
    {
        title: "How we promote our Partners",
        content: [
            "Pampdigital shall promote the Pampdigital Booking Services following this Agreement and subject to the restrictions set forth herein. To maximise the exposure of your business online and the number of appointments scheduled through the Pampdigital Booking Services, Pampdigital may, at its sole discretion, enter into agreements with third-party websites and businesses to optimise any current or future listings for your business to take advantage of search engine optimisation, search engine marketing, lead generation or social networking opportunities, and perform any other actions Pampdigital deems appropriate to increase traffic and use of the Pampdigital Booking Services.",
            "Pampdigital may promote certain Partner Services through a variety of online and offline channels, including advertising, promotion mediums, social media accounts, search engines, Google, Google AdWords, keyword advertising, Facebook Ads, Twitter, email distribution lists, the trypamp.com homepage, email newsletters, third-party marketplaces, business partner websites or mobile applications, or through Pampdigital's affiliates and their networks. Such promotional efforts may include information about the Partner's business, including its business name, scheduling information, menu, service descriptions, pricing, staff names, staff titles, location, photos, and other data relating to the Partner's business.",
            "In addition, from time to time, Pampdigital may provide Clients with additional information on Partners subject to the limitations of this Agreement."
        ]
    },
    {
        title: "How to ensure the online partner profile stands out on Pampdigital",
        content: [
            "Content standards",
            "Partners are responsible for ensuring that the content listed on the Pampdigital Platform is accurate, complete and not misleading at all times and are reflective of Partner Services, pricing, staff availability, and other content that the Partner offers. It is the Partner's obligation to transfer this information to Pampdigital by keeping this information on their Partner Account on the Pampdigital Business App (including calendar availability) up-to-date at all times. Pampdigital has the right to suspend or terminate any Partner Account if the Partner fails to provide this information to Pampdigital.",
            "Partner profile",
            "The Partner may create a Partner Profile to promote their Partner Services, which contains buttons connecting Clients to the Partner Services. A Partner Profile may contain a 'Book Now' button for appointment bookings.",
            "Partners are responsible for maintaining accurate and complete information in their Partner Profile (including up to date service information), and where photographs are used, Partners are responsible for ensuring that (i) photos uploaded onto their Partner Profile accurately reflect their services and place of business, and (ii) its use of such photographs complies with applicable law (including Data Protection Legislation).",
            "Partners may manage and modify the contents of their Partner Profile through the 'Booking' section of the Pampdigital Business App."
        ]
    },
    {
        title: "Partner profile transparency and consent",
        content: [
            "The Partner acknowledges and agrees that its Online Partner Profile should not contain any contact details, direct references or links to the Partner or its website, app, platform, tool or other devices, websites, apps, platforms, tools, or other devices of third parties.",
            "The Partner understands and accepts that the Partner must ensure that it has obtained and recorded the consent of each of its employees and contractors to be advertised on the Pampdigital Platform and to the Clients. These details include, but are not limited to, details such as name, photograph, expertise, availability and services offered. If an employee or contractor withdraws this consent, then the Partner must remove their details from the Pampdigital Booking Services in a timely manner."
        ]
    },
    {
        title: "Partner service fulfilment",
        content: [
            "The Partner shall fulfil their obligation to provide Partner Services for all Pampdigital Bookings. In particular, the Partner must honour the Partner Terms of Sale of Pampdigital Bookings for their Partner Services, which includes the Deposit Policy, Cancellation Policy and No-Show Policy that were in place when the Pampdigital Booking was made.",
            "The Partner must provide all Pampdigital Bookings and may only decline to provide Pampdigital Bookings in exceptional circumstances. Clients shall receive from the Partner’s Partner Services described in the Pampdigital Booking for the price indicated on the Pampdigital Booking. Partners may not impose any extra or additional fees or charges that contradict or otherwise alter the Partner Terms of Sale of any services unless expressly agreed by the Client. If a Client receives Partner Services that are less than the value of the amount paid for the service received , they may be entitled to a refund by the Partner equal to the difference between the value of the service received and the value of the Pampdigital Booking."
        ]
    },
    {
        title: "Payment terms with Clients",
        content: [
            "Clients using the Pampdigital Booking Services may settle the cost of their Pampdigital Booking through their choice of payment method accepted by Pampdigital, including via the Pampdigital Payment Services. Partner agrees that payment made by Clients to Pampdigital in respect of the Pampdigital Booking absolves the Client of their payment liability, and Partner shall not seek further payment from the Client in connection with that Pampdigital Booking. For Pay with Cash In-Store Bookings, Partners may accept any payment method they would otherwise accept in their ordinary course of business."
        ]
    },
    {
        title: "How to handle cancellation of appointments",
        content: [
            "In respect of Pampdigital Bookings, the Partner is obliged to comply with the cancellation and rescheduling policy set out in the Pampdigitals Terms of Service. In summary and regards to Pampdigital Bookings:",
            "- a Partner may specify a time (e.g. 4, 24 or 48 hours) before when an appointment is scheduled to start (the 'Cancellation Period'), after which cancelling an appointment through the Pampdigital Booking Services will not be possible, and cancellation by a Client will incur a cancellation fee (20% deposit).",
            "- if a Client cancels an appointment through the Pampdigital Booking Services before the Cancellation Period, the Client will not be liable to pay any fees (20% deposit) to the Partner, and neither the Client nor the Partner shall have any further obligation towards each other in regard to that Pampdigital Booking;",
            "- if a Client cancels an appointment through the Pampdigital Booking Services during the Cancellation Period, the Client will be obliged to pay the fee (20% deposit) outlined in the Cancellation Policy that was in effect at the time of their Pampdigital Booking. In this case, Pampdigital will collect cancellation fees from the Client through the Pampdigital Payment Services for later remittance to the Partner;",
            "- the Partner agrees to collect cancellation fees for Pampdigital Bookings only through the Pampdigital Payment Services. If the Partner collects cancellation fees through the Pampdigital Payment Services, the Client will be exempt from all further penalty or liability to the Partner, and the Partner may not impose additional fees or penalties to the Client in connection with that Pampdigital Booking;",
            "- at their sole discretion, Partners may choose not to enforce their Cancellation Policy on a case-by-case basis, even if the Cancellation Policy was included in the Partner Terms of Sale of the Pampdigital Booking. If a Partner cancels an appointment of a Pampdigital Booking through the Pampdigital Business App (as opposed to a Client cancelling an appointment of a Pampdigital Booking through the Pampdigital Booking Services), the Partner will waive the cancellation fees. Cancelling a Pampdigital Booking without imposing the Cancellation Policy will conclude the contract between the Partner and Client in connection with that Pampdigital Booking;",
            "- Pampdigital may modify their Cancellation Policy (including the Cancellation Period and the amount they wish to charge for cancellations) through the Pampdigital Business App. The updated cancellation terms will be included in the Partner Terms of Sale for all subsequent Pampdigital Bookings with that Partner. Changes to a Partner's Cancellation Policy will not affect previous bookings, and a Partner may not enforce Cancellation Policies retroactively if they differ from the Partner Terms of Sale to which the Client consented at the time of making a Pampdigital Booking;",
            "- a Client may choose to reschedule an appointment made through a Pampdigital Booking app. If the Client re-schedules their appointment in accordance with the Cancellation Policy (i.e. the reschedule occurs before the Cancellation Penalty Period), the Partner will accept the updated time as an amendment to the Partner Terms of Sale of the Pampdigital Booking;",
            "- the Partner may not impose cancellation fees for Pay with Cash In-Store Bookings.",
            "If Partner is eligible for and has enabled Pay with Cash In-Store Bookings:",
            "- Pampdigital is solely a technology provider and does not act as a commercial booking agent. No contract is created between the Client and the Partner until the appointment takes place for Pay with Cash In-Store Bookings;",
            "- the Partner may handle cancellations or rescheduling directly with the Client; and",
            "- Pampdigital has no further involvement in the Pay with Cash In-Store Booking process and solely provides the technology to allow Pay with Cash In-Store Bookings to be made."
        ]
    },
    {
        title: "How to handle disputes and claims from Clients",
        content: [
            "The Partner shall be directly responsible to the Client for any failure to fulfil the Client's expectations or any other legal liability regarding the Partner Services.",
            "The Partner shall use its best endeavours to provide top quality Partner Services to all Clients and promptly deal with any sales enquiries, matters or issues relating to Pampdigital Bookings or potential Pampdigital Bookings, including dealing with Client complaints.",
            "Pampdigital shall refer any Client complaints it receives to the Partner, and the Partner shall acknowledge all complaints and respond to the relevant Client within 48 hours of the Partner's receipt of a complaint, whether the complaint has come directly from the Client or via Pampdigital.",
            "The Partner shall make all efforts to resolve any complaints within 14 days and must notify Pampdigital (via partners@trypamp.com or such other method as Pampdigital may notify the Partner of from time to time) of any correspondence between the Partner and the Client relating to the complaint and generally keep Pampdigital apprised of its progress and the status of the complaint.",
            "The Partner acknowledges and accepts that the Pampdigital Platform includes a reviewing platform (which may not be opted out from), upon which Clients may post publicly viewable reviews about their experiences with Pampdigital and with the Partner (particularly about the Partner Services). The Partner further acknowledges that a selection of reviews from preceding months will also be made available on the Partner Profile (if applicable).",
            "The Partner may not attempt to manipulate the reviewing platform, including by submitting reviews for their own Partner Services or by offering a financial or other reward for submitting reviews for their Partner Services, or by submitting inaccurate reviews about other services on the Pampdigital Platform.",
            "The Pampdigital Platform may from time to time contain negative reviews and/or feedback from Clients, which is outside Pampdigital's control. The Partner may choose to reply to reviews about the Partner. Any content the Partner posts in response to user reviews must be polite, professional and non-threatening or confrontational, and it may be subject to review by Pampdigital (and may be removed or amended in Pampdigital's sole discretion). Pampdigital is not obliged to monitor any Client reviews or Partner's responses. The Partner shall have no right to any remedy (including any right to terminate this Agreement) as a result of any reviews or other public communications of Clients naming or referring to the Partner.",
            "If the Partner, acting reasonably, feels that any public communications of Clients are defamatory or harmful to the reputation of the Partner or any person or in some other way is a violation of any person's legal rights, the Partner may flag and report such communications to Pampdigital. In such case, Pampdigital shall review the same and, in its sole discretion, take any action it deems necessary or desirable (including, for example, removing or amending such defamatory content from the Pampdigital Platform)."
        ]
    },
    {
        title: "Pampdigital payment services",
        content: [
            "Pampdigital offers Partners the Pampdigital Payment Services to make it easy for Partners to collect, process, and refund payments from Clients (e.g. via Pampdigital Bookings with Online Payments).",
            "By using the Pampdigital Payment Services in any of our Pampdigital Payment enabled geographies, you agree to be bound by the relevant payment processor's terms and condition and must be compliant at all times to the terms and acquiring addendums as set out at the following URL: Pampdigital Payment Services.",
            "Payment processing details:",
            "- The withdrawal fee for a successful transfer is standardised: GHC 3 for MoMo (mobile money) and GHC 15 for a bank transfer.",
            "- Pampdigital reserves the right to vary such payment processing fees from time to time."
        ]
    },
    {
        title: "Pampdigital messaging services",
        content: [
            "Pampdigital sends automated SMS texts for new appointments, upcoming appointment reminders, rescheduled appointments, thank you for visiting, cancelled appointments, did not show up and any other SMS texts on behalf of the Partner through the Pampdigital subscription plan.",
            "Pampdigital has the right to increase the prices of SMS texts at any time by providing advance notice to the Partner.",
            "Pampdigital may engage third parties to provide Messaging Services. This includes:",
            "- For SMS services, Twilio Inc. (trading as 'Twilio'), (as set out at Twilio Terms of Service, as updated from time to time); and",
            "- For email, Google Inc. (trading as 'Google'), (as set out at [https://google.com/legal/tos](https://google.com/legal/tos) as updated from time to time)",
            "in each case whose terms apply to the relevant Messaging Services which you should read in full."
        ]
    },
    {
        title: "Marketing Services",
        content: [
            "Partner messages regarding their services, including new services, promotional updates, store updates, and any other necessary marketing details, will be sent by Pampdigital.",
            "In the event that a Client notifies Pampdigital that it does not wish to receive further marketing materials from the Partner and/or its Affiliates, Pampdigital shall promptly (and in any event no later than 48 (forty-eight) hours from being so informed) update the Client's settings accordingly through the Pampdigital Business Software.",
            "No implied conditions, warranties or other terms, including any implied terms relating to satisfactory quality or fitness for any purpose, will apply to the Messaging and Marketing Services or to anything supplied or provided by Pampdigital under these Additional Terms: Marketing Services. In particular, Pampdigital does not warrant that the operation of any Service will be uninterrupted, contaminant-free or error-free or that they will meet the Client's requirements.",
            "Pampdigital is solely responsible for ensuring its use of the Messaging and Marketing Services complies with applicable Data Protection Legislation, including with respect to obtaining and recording any consents which may be required by applicable law and promptly complying with any opt-out requests."
        ]
    },
    {
        title: "Partner promo codes",
        content: [
            "Pampdigital may generate Promo Codes on behalf of the partner to share with third parties and share on social media platform(s).",
            "The Partner warrants that it is responsible for the management of any Promo Codes Pampdigital generates and will ensure that all promotions available through the Partner's profile through the Pampdigital Platform.",
            "Promo code rules:",
            "- The Partner must provide, at a minimum, the following information when sharing any Promo Codes with third parties:",
            "  1. How to use the Promo Code (e.g. enter at checkout)",
            "  2. Start date",
            "  3. Expiry date",
            "  4. Any restrictions or limitations (e.g. age, date or geographical restrictions)",
            "  5. Any limitations of availability (e.g. can only be used once)",
            "  6. Any significant conditions or information attached to the use of the Promo Code",
            "- Known as the 'Promo Code Rules'. For more information, please contact us via [partners@trypamp.com](partners@trypamp.com) for guidance on using Promo Codes.",
            "Pampdigital reserves the right to withdraw the use of Promo Codes from its offering of services or require the Partner to withdraw a Promo Code generated without incurring any liability, including without limitation for any fraudulent activity, causing technical issues, subject to tampering, breached or likely to breach any of the guidelines or any applicable local regulations, or likely to bring Pampdigital and its brand into disrepute."
        ]
    },
    {
        title: "Pampdigital professional services",
        content: [
            "Partners may sign-up and create their profile on the Pampdigital Platform through Pampdigital's onboarding tool or request to purchase Professional Services from Pampdigital.",
            "All subscription payments for Professional Services must be paid for in advance. Without prejudice to any other right or remedy available to Pampdigital, Pampdigital may, at any time without notice, set off any payments due to the Partner against payments due to Pampdigital from the Partner for professional subscription services.",
            "Pampdigital may, at its discretion, accept or reject a request to purchase Professional Services. The Partner acknowledges and agrees that any Professional Services purchased and added to the Partners account are non-refundable (including but not limited to on termination of the service or the Agreement).",
            "The price of the Professional Services shall be notified to the Partner at the time of purchase.",
            "The Partner and Pampdigital will mutually agree on a schedule, including any initial introduction call or further calls, virtual or in-person meetings; any date deadlines; the data to be migrated, any social media and / or website links to be included, and the Professional Services to be provided.",
            "Pampdigital may outsource its provision of Professional Services to third parties, in whole or in part."
        ]
    },
    {
        title: "Pampdigital photography services",
        content: [
            "Pampdigital may provide photography services, including filming and photographing premises and staff, creating panoramic images or virtual tours (including augmented/virtual reality tours), and creating associated written content and materials ('Photography Services'). Pampdigital will deliver any Developed Materials within five (5) business days.",
            "Pampdigital may, at its discretion, provide Photography Services directly or may engage third-party vendors to provide Photography Services.",
            "You may submit a request for Photography Services (including photo quantities) to Pampdigital. Pampdigital will then provide an Order Confirmation documenting the order specifics (including as applicable photo quantities, image resolutions, and pricing). The Order Confirmation may also provide other terms applicable to your purchase, such as any third-party vendor terms."
        ]
    },
    {
        title: "Intellectual property in photography services",
        content: [
            "All Intellectual Property Rights and all other rights in all intellectual property, works, services, documentation, information, data and other material of any kind acquired or created by or on behalf of Pampdigital for the Partner, commissioned by the Partner or made to the Partner's specification, or conceived or created in the course of or in relation to the provision of the Photography Services ('Developed Materials') shall be owned by Pampdigital, and the Partner assigns to Pampdigital, or shall procure the assignment to Pampdigital of, all such rights (whether presently existing or to be created in the future), and if moral rights exist, the Partner waives or shall procure the waiver of, such moral rights.",
            "Pampdigital may, in its absolute discretion, select which Developed Materials will form part of the Online Partner Profile (or otherwise be used on or for the Pampdigital Services), including whether to use some, all or none of the Developed Materials and how to display or otherwise use Developed Materials on the Online Partner Profile or Pampdigital Services in order to maximise the value of the Partner Services, Pampdigital aims to ensure that the Developed Materials are of suitably high quality.",
            "Pampdigital shall permit the Partner to use the Developed Materials for the promotion of the Partner's business through the Pampdigital Services."
        ]
    },
    {
        title: "Partner's photoshoot obligations",
        content: [
            "The Partner may opt to book Photography Services via the Pampdigital Services for a specific time slot (a 'Photo Booking'); Photo Bookings may be made up to three (3) months in advance. Partner must pay the Photography Service fee in full at the time the Photo Booking is made.",
            "The Partner is responsible for providing correct and reasonable requirements for and checking the Developed Materials. If the Partner is not wholly satisfied with any Developed Materials, it must inform Pampdigital (and, if applicable, Pampdigital's third-party party vendor) within three (3) Business Days, including a list or description of the inadequacies, defects, deficiencies or other problems in the Developed Materials that led to the refusal. If no written refusal of deliverable is given Pampdigital within this three (3) Business Day period, such Deliverable shall be deemed accepted. Pampdigital shall then have ten (10) Business Days following the Partner's notice of refusal to correct any such problems and deliver corrected Developed Materials to the Partner for its review and acceptance as set forth above.",
            "The Partner is responsible for:",
            "- obtaining any licences, consents, release forms, permits or other requirements, as required; and",
            "- ensuring the premises at which the Photography Services will be performed are clean, tidy and in a suitable presentable state.",
            "For more information and guidelines regarding photography, please email [partners@trypamp.com](partners@trypamp.com)"
        ]
    },
    {
        title: "Photoshoot cancellation and re-scheduling",
        content: [
            "The Partner may cancel or re-schedule a Photo Booking with at least twenty-four (24) hours prior written notice. If a Photo Booking is cancelled or rescheduled, then the Partner shall incur an additional cost of 50% of the initial Photography Service fee, payable before the re-scheduled Photography Service being provided.",
            "A Photo Booking may not be cancelled and/or re-scheduled more than twice; if any Photo Booking is cancelled and/or re-scheduled more than twice, then the Partner shall incur an additional cost of 100% of the initial Photography Service fee.",
            "If the Partner cancels or re-schedules a Photo Booking on less than twenty-four (24) hours prior written notice, the Partner will not be refunded for the Photo Booking and is not entitled to re-schedule the Photo Booking.",
            "The Photography Services provider will wait thirty (30) minutes for the Client’s representative at the photo shoot site. After thirty (30) minutes, the Photography Services provider may leave the photoshoot site, and the Partner will not be refunded for the Photo Booking and is not entitled to re-schedule the Photo Booking.",
            "If the Photography Services provider does not arrive within thirty (30) minutes of the agreed Photo Booking time, the Partner may re-schedule a new Photo Booking at a mutually agreed time without incurring additional cost."
        ]
    },
    {
        title: "Pampdigital training services",
        content: [
            "Pampdigital may, on Partner's request (as part of the Professional Services, or at any time during its use of the Pampdigital Services), provide (or procure that a third party provides) training sessions to Partner regarding how to use the Pampdigital Services, as further described below:",
            "Pampdigital and Partner will agree on the Partner venue(s) to receive training, training session(s) per venue, and training time(s), method, and, if applicable, place to deliver the training, subject to Pampdigital's final decision (and following Pampdigital's training best practice, as set out at Pamp’s online help centre;",
            "Each training session will be up to one (1) hour and may be delivered virtually or in-person, as agreed between Pampdigital and the Partners (a 'Training Session');",
            "Pampdigital will notify Partner of any applicable fee for each Training Session ('Training Session fee') if such Training Session is not provided as part of the Professional Services;",
            "Each training session may be attended by up to ten (10) of Partner's staff unless otherwise agreed with Pampdigital;",
            "the Partner agrees that it shall comply with any third party provider's terms and conditions relating to the training sessions; and within 45 days of the date Partner accepts these Additional Terms: Training, Partner agrees that it shall book and attend a Training Session.",
            "If the Pampdigital training provider does not arrive within thirty (30) minutes of the agreed Training Session time, the Partner may re-schedule a new Training Session at a mutually agreed time without incurring additional cost."
        ]
    },
    
];



export default partnerTermsOfBusiness