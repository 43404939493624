import { Avatar } from "antd";
import React, { useState } from "react";
import { Button, Modal, Space, Input } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useActionMutation, useLoginMutation } from "../redux/user";
import EditDropdown from "./Editmenu";
import VendorProfileContainer from "../components/VendorCont";
const { confirm } = Modal;
const RequestRow = ({ data ,refetch}) => {
  const [approve,{isLoading}]= useActionMutation()
const [rate,setRate]=useState()
  const [isProfileVisible, setIsProfileVisible] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);


    console.log(data);

    const showProfileModal = () => {
      setIsProfileVisible(true);
    };
  
    const hideProfileModal = () => {
      setIsProfileVisible(false);
    };
    const handleApprove = async(value) => {
    
  await      approve({
          vendor_id: data.id,
          approved: value,
          type: 'vendor',
          rate
        })
        refetch()
      };
  
    return (
       <div key={data.id}   className="tred" 
     > 
      

      <div className="flex-item cursor icont" onClick = {()=>showProfileModal(!isProfileVisible)}>
          <Avatar src={data.image}/>{' '}
          <div className="" >{data.username}</div>
          </div>
          <div className="flex-item">{data.staff?data.staff.length :'not set'}</div>
        <div className="flex-item">{data.phone?data.phone:'none'}</div>
          <div className="flex-item">{data.address?data.address:'none'}</div>
        <div className="flex-item">{data.subscription_plan?data.subscription_plan.name:'none'}</div>
        <div className="flex-item d-flex ">
         <div onClick={()=>handleApprove(true)} className="action d-flex unapproved">Approve</div>
         <div onClick={()=>handleApprove(false)} className="action decline d-flex">Decline</div>      
        </div>   
        
        <Modal
        title="Vendor Profile"
        visible={isProfileVisible}
        onCancel={hideProfileModal}
        footer={[
          <Button key="back" onClick={hideProfileModal}>
            Close
          </Button>
        ]}
        width={800} 
      >
        {/* Render Vendor Profile Details Here */}
        <div>
        
       <VendorProfileContainer id={data.id}/>
        </div>
      </Modal>


       
        </div>
        
    );
};

export default RequestRow;