import { Avatar } from "antd";
import React, { useState } from "react";
import bin from '../assets/svgs/bin.svg'
import edit from '../assets/svgs/edit.svg'
import InfoDrawer from "../components/InfoDrawer";
import { toast } from 'react-toastify';
import { Button, Modal, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useDeleteMutation } from "../redux/user";
import EditDropdown from "./Editmenu";
import EditCoupon from "../pages/EditCoupon";
const { confirm } = Modal;
const CouponListRow = ({ data, refetch }) => {
  const [open, setOpen] = useState(false);
  const [del] = useDeleteMutation()


  console.log(data);


  const showDeleteConfirm = async (id) => {

    try {
      confirm({
        title: 'Are you sure delete this coupon?',
        icon: <ExclamationCircleFilled />,
        content: 'Are you sure you want to delete this coupon',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          const { data } = await del({ id, scope: 'coupon' })


          // Display success toast
          toast.success('Coupon Deleted', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,

          });
          data?.message === 'deleted' && refetch()
        },
        onCancel() {
          console.log('Cancel');
        },
      });

    } catch (e) {
      console.error('Error during confirmation:', e);
    }
  }
  return (
    <div key={data.id} style={{ height: 100 }} className="tred2"
    >

      <div className="flex-item" onClick={() => console.log(data.id)}>{data.code}</div>
      <div className="flex-item">{data.amount}</div>
      <div className="flex-item">{data.description}</div>
      <div className="flex-item">{data.use_count ? data.use_count + '/' + data.limit : 0 + '/' + data.limit}</div>
      <div className="flex-item">{data.expiry}</div>
      <div className="flex-item"><div className="no" style={{ backgroundColor: !data.enabled ? '#CD3D49' : null }}>{data.enabled ? 'True' : 'false'}</div></div>
      <div className="flex-item d-flex edit">
        <img onClick={() => showDeleteConfirm(data.id)} src={bin} />
        <img onClick={() => setOpen(!open)} src={edit} style={{ width: 30, marginLeft: 10 }} />
      </div>

      <EditCoupon isOpen={open} refetch={refetch} data={data} setOpen={setOpen} />

    </div>
  );
};

export default CouponListRow