const termsOfUse = [{
    title: "Introduction",
    content: [
      "These are the terms and conditions of use for Pampdigital website and app (Terms) and all affiliated websites and apps owned and operated by Pampdigital (Pamp, we, us, and our), including all subdomains, subdirectories, mobile sites, and mobile applications. We are a company registered in Ghana. Our registered company number is BN613990822, and our registered office is at Lily Close, Greater Accra.",
      "Your use of the Site and mobile app will be subject to these terms, and by using the site and mobile app, you agree to be bound by them.",
      "These terms and conditions of use only apply to your use of our site and app. These terms and conditions of use DO NOT apply to the third-party goods and services which are available for booking and purchases on our site and app. The online ordering and booking-related services (including payments, vouchers, and bookings) we make available through the app are provided subject to our Terms of Service. Our third-party partners fulfill these services (including the provision of your selected treatments) (the Partner Services).",
      "We may change these Terms from time to time by changing them on the Site. Your continued use of the site and app following the posting of changes will mean that you accept and agree to the changes. As long as you comply with these Terms of Use, Pamp grants you a personal, non-exclusive, non-transferable, limited privilege to enter and use the site and app. These Terms were last updated on 1st September 2022.",
    ],
  },
  {
    title: "Feedback and Information",
    content: [
      "Please submit any feedback you have about these Terms or any questions concerning the Site and app by email to [support@trypamp.com](mailto:support@trypamp.com)."
    ],
  },
  {
    title: "Privacy",
    content: [
      "If you use our site or app, Pamp’s Privacy Policy applies and forms a part of these Terms of Use. Unfortunately, the transmission of information via the internet is not completely secure. We do our best to protect your personal information, but we cannot guarantee the security of your data transmitted to us – even if there is a special notice that a particular transmission (for example, credit card information) is encrypted. Any transmission is at your own risk."
    ],
  },
  {
    title: "Access to the site and app",
    content: [
      "It is your responsibility to ensure your equipment (computer, laptop, netbook, tablet, or other mobile device) meets all the necessary technical specifications to enable you to access and use the site and app and is compatible with the site and app.",
      "You must ensure that any registration details you provided are accurate. If you choose, or you are provided with, a log-on ID (such as a username and password or other identifier) as part of our security procedures, you must treat such information as confidential and must not reveal it to anyone else. You are responsible for all activities that occur under your log-on ID and must notify us immediately of any unauthorized use or other security breach of which you become aware. We reserve the right to disable any log-on ID, at any time, if in our opinion you have failed to comply with any of the provisions of these Terms or if any details you provide for the purposes of registering as a user prove to be false."
    ],
  },
  {
    title: "Age requirements",
    content: [
      "You must be at least the age of majority or older in your jurisdiction of residence to use the Pamp Platform.",
      "If you live in a country or area that limits the use of the Pamp Platform due to age or restricts the ability to enter into contracts such as this one based on age, you must abide by such age limits and must not use the Pamp Platform or Pamp Services unless you have your parent or legal guardian’s permission.",
      "Please have your parent or legal guardian read these terms with you. If you are a parent or legal guardian and you allow your child or dependent to use these Pamp Services, then these terms apply to you, and you are responsible for your child’s or dependent's activity on the services."
    ],
  },
  {
    title: "Accounts, passwords, and security",
    content: [
      "You do not need an account to access the site, but you need an account to access the Pamp app.",
      "Each individual may only have one account on our app, whether it's your own account or part of a shared account type, like a family account. Your account is non-transferrable and may not be sold, accessed by, combined, or otherwise shared with anyone else.",
      "We may immediately terminate your account if we determine that you have breached any part of these Terms, including:",
      {
        type: "bullets",
        content: [
          "allowing any third party to access or use your account;",
          "committing fraud or falsifying information in connection with your use of our site and app or in connection with your account on our site or app;"
        ],
      },
      "If we or you terminate your account, then we may, without limitation:",
      {
        type: "bullets",
        content: [
          "cancel any pending, current, or future promotional account credits and any unredeemed vouchers in your account.",
          "disallow or prevent you from joining under a new account unless we formally invite you to do so."
        ],
      },
      "We reserve the right to hold you liable for any and all damages caused by your conduct, to pursue legal action through relevant local and national law enforcement authorities, and to notify your internet service provider of any fraudulent activity we associate with you or your use of our site and app."
    ],
  },
  {
    title: "What you are allowed to do",
    content: [
      "Unless you are a Partner (a selected third-party provider of goods and services, who offers their goods and services for sale on the site and app), you may only use the Site and app for non-commercial use and only in accordance with these Terms.",
    ],
  },
  {
    title: "Your promises to us",
    content: [
      "Use only our authorised app, on the right device:",
      {
        type: "bullets",
        content: [
          "You may only access the Pamp Services using authorised means. It is your responsibility to check to ensure you download the correct App for your device. Pamp is not liable if you do not have a compatible mobile device or if you download the wrong version of the App for your mobile device.",
          "Pamp reserves the right to terminate the Pamp Services and the use of the Site and app if you are using the Site and app with an incompatible or unauthorised device.",
        ],
      },
      "Comply with the law and these terms, and use the site and app appropriately:",
      {
        type: "bullets",
        content: [
          "You must only use the site and app and anything available from the site and app for lawful purposes (complying with all applicable laws and regulations), in a responsible manner, and not in a way that might damage our name or reputation or that of any of our affiliates.",
          "By using the site and app, you agree that:",
          {
            type: "bullets",
            content: [
              "You will only use the site and app for your sole, personal use (unless you are a Partner) and will not resell it to a third party;",
              "You will not authorise others to use your account (except as permitted as part of a family account);",
              "You will not assign or otherwise transfer your account to any other person or legal entity;",
              "You will not use an account that is subject to any rights of a person other than you without appropriate authorisation;",
              "You will not use the site and app for unlawful purposes, including but not limited to sending or storing any unlawful material or for fraudulent purposes;",
              "You will not use the site or app to cause nuisance, annoyance or inconvenience;",
              "You will not impair the proper operation of the network;",
              "You will not try to harm the site or app in any way whatsoever;",
              "You will not copy, or distribute the App or other Pamp content without written permission from Pamp;",
              "You will keep your account password (or any identification we provide you which allows access to the site and app) secure and confidential;",
              "You will provide us with whatever proof of identity we may reasonably request;",
              "You will only use our site and app through an internet connection you are authorised to use;",
              "You are aware that when Pamp Services may require receiving or sending messages by SMS (if available in your jurisdiction), standard messaging charges (if any) will apply;",
              "You will not use the site and app with an incompatible or unauthorised device;",
              "You will comply with all applicable laws including, secondary legislation of the area in which you are present while using the site and app.",
            ],
          },
          "Pamp reserves the right to immediately terminate the Pamp Services and the use of the site and app if you do not comply with any of the above rules.",
        ],
      },
    ],
  },
  {
    title: "What you are not allowed to do",
    content: [
      "Except to the extent expressly set out in these Terms, you are not allowed to:",
      {
        type: "bullets",
        content: [
          "'scrape' content or store content of the site or app on a server or other storage device connected to a network or create an electronic database by systematically downloading and storing all of the content of the site or app;",
          "remove or change any content of the site and app or attempt to circumvent security or interfere with the proper working of the site and app or the servers on which it is hosted; or create links to the site from any other website or 'frame' or 'mirror' the Site and app on any other server or wireless or Internet-based device, without our prior written consent.",
          "launch an automated program or script, including, but not limited to, web spiders, web crawlers, web robots, web ants, web indexers, bots, viruses or worms, or any program which may make multiple server requests per second, or unduly burdens or hinders the operation and/or performance of the site and app;",
          "licence, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make available to any third party the site or app in any way;",
          "modify or make derivative works based upon the site and app;",
          "send spam or otherwise duplicative or unsolicited messages of any kind in violation of applicable laws;",
          "send or store infringing, obscene, threatening, libellous, or otherwise unlawful or tortious material, including material harmful to children or anything which would violate third party privacy rights;",
          "send or store material containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents or programs;",
          "interfere with or disrupt the integrity or performance of the site and app, the site or the data contained therein; or",
          "attempt to gain unauthorised access to the Site and app, the site and app or its related systems or networks.",
        ],
      },
      "Pamp will have the right to investigate and prosecute violations of any of the above to the fullest extent of the law. Pamp may involve and cooperate with law enforcement authorities in prosecuting users who violate these Terms. You acknowledge that Pamp has no obligation to monitor your access to or use of the site and app or to review or edit any content on the site and app, but has the right to do so for the purpose of operating the site and app, to ensure your compliance with these Terms (or any other terms between you and Pamp), or to comply with applicable law or the order or requirement of a court, administrative agency or other governmental body. Pamp reserves the right, at any time and without prior notice, to remove or disable access to any content that Pamp, at its sole discretion, considers to be in violation of these Terms of Service or otherwise harmful to the Pamp Services.",
      "If you breach any part of these Terms, all your rights under these Terms will terminate immediately.",
    ],
  },
  {
    title: "User content",
    content: [
      "The site and app may, from time to time, allow you to upload user-generated content and may also allow you to communicate that content, either to selected recipients or in public areas, such as review sections (collectively User Content Areas).",
      "We do not control the material submitted to User Content Areas (collectively User Submissions), nor are User Content Areas actively moderated. You are solely responsible for the content of your User Submissions as submitted by you and acknowledge that all User Submissions express the views of their respective authors, and not our views.",
      "If you participate in any User Content Areas, you must keep all User Submissions relevant to the purpose of the User Content Area and the nature of any topic.",
      "User Submissions must not:",
      {
        type: "bullets",
        content: [
          "contain, transmit, distribute, link to or otherwise make available, or advertise or promote any content that infringes any intellectual property rights or data protection, privacy or other rights of any other person, is defamatory or in breach of any contractual duty or any obligation of confidence, is obscene, sexually explicit, threatening, abusive, harassing, inciteful of violence or hatred, blasphemous, discriminatory (on any ground), liable to cause anxiety, alarm or embarrassment, knowingly false or misleading, or that does not comply with all applicable laws and regulations or is otherwise objectionable ('Prohibited Content');",
          "impersonate any person or entity or otherwise misrepresent your relationship with any person or entity;",
          "infringe any third party's intellectual property rights;",
          "contain, transmit or distribute any unsolicited or unauthorised advertising, marketing or promotional material (including paid or otherwise sponsored reviews if the payment or sponsorship is undisclosed in the material) or other form of solicitation (spam); or",
          "transmit or distribute any virus and/or other code that has contaminating or destructive elements.",
        ],
      },
      "You agree that by submitting content, feedback or other information to Pamp, including any User Submission, you grant us and our affiliates a perpetual, irrevocable, worldwide, non-exclusive, royalty-free and fully sub-licensable right and licence to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform and display such User Submission (in whole or part) and/or to incorporate it in other works in any form, media or technology, and you waive any moral rights you may have in, or to be identified as the author, of such User Submission.",
      "Whilst we do not pre-screen User Submissions, we reserve the right, in our sole discretion, to delete, edit or modify any User Submission submitted by you and/or to close any topic, at any time without notice to you.",
      "Complaints about the content of any User Submission must be sent to support@trypamp.com and must contain details of the specific User Submission giving rise to the complaint.",
    ],
  },
  {
    title: "Intellectual property",
    content: [
      "Pamp alone (and its third party licensors, where applicable) shall own all right, title and interest, including all related intellectual property rights, in and to the site and app (including text, graphics, software, photographs and other images, videos, sound, trademarks and logos), and any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by you or any other party relating to the Pamp Services, site and app.",
      "These Terms, and our Terms of Service, do not constitute a sale and do not convey to you any rights of ownership in or related to the Pamp Services or the site and app, or any intellectual property rights owned by Pamp. Pamp's name, logo, and the product names associated with the Pamp Services are trademarks of Pampdigital, its affiliated companies or third parties, and no right or licence is granted to use them.",
      "Except as expressly set out here, nothing in these Terms gives you any rights in respect of any intellectual property owned by us or our licensors and you acknowledge that you do not acquire any ownership rights by downloading content from the site and app.",
      "In the event you print off, copy or store pages from the site or app (only as permitted by these Terms), you must ensure that any copyright, trademark or other intellectual property right notices contained in the original content are reproduced.",
      "You may not submit to Pamp or to our site or app any material protected by copyright, trademark or other proprietary right without the express permission of the owner of that right, and you will be solely responsible for any loss or damages resulting from any infringement.",
    ],
  },
  {
    title: "Content",
    content: [
      "We may change the format and content of the Site and app from time to time. You agree that your use of the site and app is on an 'as is' and 'as available' basis and at your sole risk.",
      "Whilst we try to make sure that all information contained on the site and app (other than any user-generated content) is correct, it is not intended to amount to authority or advice on which reliance should be placed. You should check with us or the relevant information source before acting on any such information.",
    ],
  },
  {
    title: "External links",
    content: [
      "The site and app may, from time to time, include links to external sites, which may include links to third party offers and promotions. We include these to provide you with access to information, products or services that you may find useful or interesting. We are not responsible for the content of these sites or for anything provided by them and do not guarantee that they will be continuously available. The fact that we include links to such external sites does not imply any endorsement of or association with their operators or promoters.",
    ],
  },
  {
    title: "Disclaimers",
    content: [
      "Except to the extent provided in our Terms of Service, we make or give no representation or warranty as to the accuracy, completeness, currency, correctness, reliability, integrity, quality, fitness for purpose or originality of any content of the site and app and, to the fullest extent permitted by law, all implied warranties, conditions or other terms of any kind are hereby excluded and we accept no liability for any loss or damage of any kind incurred as a result of you or anyone else using the site and app or relying on any of its content.",
      "While we try to keep the site and app safe and secure, we cannot and do not guarantee that any content of the site and app will be free from viruses and/or other code that may have contaminating or destructive elements. It is your responsibility to implement appropriate IT security safeguards (including anti-virus and other security checks) to satisfy your particular requirements as to the safety and reliability of content.",
      "We may, from time to time and without notice, restrict access to certain features, parts or content of the site and app, or the entire site and app, to users who have registered with us.",
      "We cannot guarantee the continuous, uninterrupted or error-free operability of the site and app. There may be times when certain features, parts or content of the site and app, or the entire site and app, become unavailable (whether on a scheduled or unscheduled basis) or are modified, suspended or withdrawn by us, in our sole discretion, without notice to you. You agree that we will not be liable to you or to any third party for any unavailability, modification, suspension or withdrawal of the Site, or any features, parts or content of the site and app.",
    ],
  },
  {
    title: "Governing law, dispute resolution",
    content: [
      "These Terms, and any dispute between you and us regarding these terms or the site and app, shall be governed and dealt with by Ghanaian law. Except where excluded by applicable law (including where such restriction would be unfair), any claim under these Terms of Use must be brought within one (1) year after the cause of action arises, or such claim or cause of action is barred. Claims made under the separate Terms of Service for goods and services are not subject to this limitation.",
      "No recovery may be sought or received for damages other than out-of-pocket expenses, except that the prevailing party will be entitled to costs and attorneys’ fees.",
      "In the event of any controversy or dispute between Pamp and you arising out of or in connection with your use of the site and app, the parties shall attempt, promptly and in good faith, to resolve any such dispute. If we are unable to resolve any such dispute within a reasonable time (not to exceed thirty (30) days), then either party may submit such controversy or dispute to mediation. If the dispute cannot be resolved through mediation, then the parties shall be free to pursue any right or remedy available to them under applicable law.",
    ],
  },
  {
    title: "Geographic restrictions",
    content: [
      "Pamp is based in Ghana, where it administers and operates the Pampdigital site and app. Other Pamp sites and applications may be administered and operated from other locations around the world.",
      "Although the Site is accessible worldwide, not all features, products or services discussed, referenced, provided or offered through or on the site and app are available to all persons or in all geographic locations, or appropriate or available for use in other countries. Pamp may, in its sole discretion, limit the use of any feature, product or service to any person or geographic area. Any offer for any feature, product or service made on the Site is void where prohibited.",
      "If you choose to access the site and app from outside Ghana, you acknowledge you have chosen to do so independently and you are solely responsible for complying with applicable local laws.",
    ],
  },
  {
    title: "Updating this Terms of Use",
    content: [
      "This Notice was last updated on 1st November 2023.",
      "We may update this document from time to time and will post any changes on this page.",
    ],
  }
        

];
  
  export default termsOfUse