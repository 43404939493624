import dayjs from "dayjs";
import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import TotalUsers from "../components/TotalUsers";
import TotalVendors from "../components/TotalVendors";
import TotalBookings from "../components/TotalBooking";
import TotalCategories from "../components/Totalcategories";
import TotalEarnings from "../components/TotalEarning";
import UpdateCont from "../components/UpdateCont";
import { useLazyGetallQuery } from "../redux/user"; import AdminCharges from "../components/AdminCharges";
import { Affix } from "antd";
import InfoHeader from "../components/InfoHeader";
import { useNavigate } from "react-router-dom";
import Chart from "../components/Chart";
import BarChart from "../components/BarChart";
function Dashboard() {
  const navigate= useNavigate()
  const containerAnimation = useAnimation();
  const [getall,{ data ,isLoading,isError}] = useLazyGetallQuery();

  useEffect(() => {
    if(isError){
      localStorage.clear();


    }
   
      getall({ scope: 'all' });
  
      // Add more complex animations for each component
      containerAnimation.start("visible");

  }, [containerAnimation, data, getall, isLoading,isLoading]);

  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const childVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
  };
  const chartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
    datasets: [
      {
        label: 'Pamp Report',
        backgroundColor: "#F9B015",
        borderColor: '#F9B015',
        barThickness: 15,
        borderRadius: 40,
        borderSkipped: false,
        data: [65, 59, 80, 81, 56, 55, 40],

      }
    ],

  };
  return (
    <>
      {isLoading ? (
        <div>Loading</div>
      ) : (
        <>
      <InfoHeader
      title={'Dashboard'}
      
      />
        <motion.div
                      className="dashboard-layout "
                      variants={containerVariants}
                      initial="hidden"
                      animate={containerAnimation}
                  >
                      <motion.div
                          className="user-cont"
                          variants={childVariants}
                      >


                          <TotalUsers data={data?.users.size} />
                      </motion.div>
                      <motion.div
                          className="vendor-cont"
                          variants={childVariants}
                      >
                          <TotalVendors data={data?.vendors.size} />
                      </motion.div>
                      <motion.div
                          className="update-cont"
                          variants={childVariants}
                      >
                          <UpdateCont data={data} />
                      </motion.div>
                      <motion.div
                          className="revenue-cont"
                          variants={childVariants}
                      >
                          <TotalEarnings data={data?.revenue} />
                      </motion.div>
                      <motion.div
                          className="bookings-cont"
                          variants={childVariants}
                      >
                          <TotalBookings data={data?.bookings.size} />
                      </motion.div>
                      <motion.div
                          className="categories-cont"
                          variants={childVariants}
                      >
        <div className="small-cont3" style={{color:'#C1E6BA'}} ></div>
                          <TotalCategories title={'Categories'} sub_title={'Total Categories'} data={data?.categories.size} />
                      </motion.div>
                      <motion.div
                          className="earnings-cont"
                          variants={childVariants}
                      >
                <TotalCategories sub_title={'Influencers'} title='Total influencers' data={data?.influencers?.size||0} />

                      </motion.div>
                  </motion.div></>
      )}
    </>
  );
}

export default Dashboard;
