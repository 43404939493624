import React, { useState, useEffect, useRef } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Input, Radio, Select, Typography, Avatar, Drawer } from 'antd';
import { useGetallQuery } from '../redux/user';
import Checkbox from '../components/Checkbox';
import { useAddSubscriptionMutation } from '../redux/user';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useLazyGetallQuery } from "../redux/user";
import TextInput from '../components/Input';
import SelectInput from '../components/Select';
import userImage from '../assets/images/userimg.png';
import { useCreateMutation } from '../redux/user';
import { backendURL } from '../redux/http';
import axios from 'axios';
import { DateInput } from '../function';
const { Text } = Typography;

const EditService = ({ isOpen, setOpen, data,vendor_id, refetch }) => {
 
    const [formInfo, setFormData] = useState(data);
    const imageInputRef = useRef(null);
    const formRef = useRef(null);
  useEffect(() => {
      setFormData({ ...data, vendor_id, scope: 'category' });
    }, [data]);


    const handleSave = async () => {
        const loadingToastId = toast.info('Updating service category...', {
            position: 'top-right',
            autoClose: false, // Do not auto close loading toast
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
        });
        try {

            
            const response = await axios.patch(backendURL + "/admin/update", formInfo, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`

                },
            });
          
            // Hard reset form
            if (formRef.current) {
                formRef.current.reset();
            }
            refetch()
            toast.dismiss(loadingToastId);
            console.log(response.data)
            toast.success('Service updated successfully!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            // Handle successful creation
        } catch (error) {
            toast.dismiss(loadingToastId);
            toast.error('Something went wrong!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };


    const handleChange = (field, value,id) => {
        setFormData((prevData) => ({ ...prevData, services: prevData.services.map((service)=>{
            if(service.id === id){
                return {...service, [field]: value}
            }
            return service
        }
        ) 
        }));

    };

    const handleNameChange = (value) => {
        setFormData((prevData) => ({ ...prevData, name: value }));
    }


    return (
        <Drawer title='Update Service' width={600} placement="right" closable={true} onClose={() => setOpen(!isOpen)} open={isOpen}>


            <form ref={formRef}>

               
           
                <div className="">
                       <h2 className="mt-[50px] title-med">Category Name</h2>
                    <TextInput
                        className={'flex-1 input'}
                        placeholder={'Category'}
                        value={formInfo.name}
                        onChange={(e) => handleNameChange(e.target.value)}

                      
                    />
                   {formInfo?.services?.map((service)=>{
                       return (

                           <div className="flex gap-5 border-t border-lightgreen">

                               <div className="flex-1 ">



                                   <h2 className="mt-[50px] title-med">Name</h2>

                                   <TextInput

                                       className={'flex-1 input'}
                                       placeholder={'Name'}
                                       onChange={(e) => handleChange('name', e.target.value, service.id)}
                                       value={service.name}
                                   />
                                   <h2 className="mt-[50px] title-med">Time</h2>
                                   <TextInput
                                       className={'flex-1 input'}
                                       placeholder={'Duration'}
                                       type={'number'}
                                       onChange={(e) => handleChange('time', e.target.value, service.id)}
                                       value={service.time}
                                   />





                               </div>
                               <div className="flex-1">

                                   <h2 className="mt-[50px] title-med">Price</h2>
                                   <TextInput
                                       type={'number'}
                                       className={'flex-1 input'}
                                       value={service.price}
                                       placeholder={'Price'}
                                       onChange={(e) => handleChange('price', e.target.value, service.id)}

                                   />



                                  
                               </div>
                           </div>
                   )
                   }
                   
                   
                   ) }
                </div>
             
                <div className="flex gap-3 justify-end mt-[140px] ">
                    <div style={{ width: 100 }} className="action d-flex unapproved" onClick={handleSave}>
                        Update
                    </div>

                </div>
            </form>
        </Drawer>
    );
};

export default EditService;
