import React from "react";
import { ReactComponent as PalmTree } from "../assets/svgs/palm-tree.svg";
import { ReactComponent as Birds } from "../assets/svgs/birds.svg";
import { ReactComponent as Cloud } from "../assets/svgs/cloud.svg";
import { ReactComponent as Dot } from "../assets/svgs/dot.svg";
import { ReactComponent as Instagram } from "../assets/svgs/insta_green.svg";
import { ReactComponent as Twitter } from "../assets/svgs/twitter_green.svg";
import { ReactComponent as Facebook } from "../assets/svgs/facebook_green.svg";
import { ReactComponent as In } from "../assets/svgs/in_green.svg";
import { ReactComponent as TikTok } from "../assets/svgs/tiktok_green.svg";
import { ReactComponent as Youtube } from "../assets/svgs/youtube_green.svg";
import { ReactComponent as Arrow } from "../assets/svgs/contact_us_arrow.svg";
import { ReactComponent as Email } from "../assets/svgs/email.svg";

const ContactUs = () => {
  return (
    <section className="contact-us-wrapper">
      <Birds className="contact-us-bird-one" />
      <Cloud className="contact-us-cloud-one" />
      <Cloud className="contact-us-cloud-two hide-mobile" />
      <Dot className="contact-us-dot-one" />
      <div className="contact-us-inner-wrapper">
        <p className="talk-us-text">Talk to us</p>
        <p className="email-wrapper">
          <Email className="mr-2" />
          <span>hello@trypamp.com</span>
        </p>
        <p className="connect-text">Connect and stay updated </p>
        <p className="connect-text">with us on all our social media pages</p>
        <div className="contact-us-links">
          <a
            className="contact-us-link"
            href="https://www.instagram.com/trypamp"
            target="_blank"
          >
            <Instagram className="mr-5 cursor-pointer" />
          </a>
          <a
            className="contact-us-link"
            href="https://twitter.com/trypamp"
            target="_blank"
          >
            <Twitter className="mr-5 cursor-pointer" />
          </a>
          <a
            className="contact-us-link"
            href="https://www.facebook.com/trypamp"
            target="_blank"
          >
            <Facebook className="mr-5 cursor-pointer" />
          </a>
          <a
            className="contact-us-link"
            href="https://www.linkedin.com/company/trypamp"
            target="_blank"
          >
            <In className="mr-5 cursor-pointer" />
          </a>
          <a
            className="contact-us-link"
            href="https://www.tiktok.com/@trypampgh"
            target="_blank"
          >
            <TikTok className="mr-5 cursor-pointer" />
          </a>
          <a
            className="contact-us-link"
            href="https://www.youtube.com/channel/UCFhbT66WEK6johvi7GGzliQ"
            target="_blank"
          >
            <Youtube className="mr-5 cursor-pointer" />
          </a>
        </div>
      </div>
      <Arrow />
    </section>
  );
};

export default ContactUs;
