import React, { useState, useEffect } from "react";
import TabView from "../components/Tabview";
import Query from "../components/Query";
import VendorRequest from "../routecomponents/vendorequest";
import VendorApproved from "../routecomponents/vendorapproved";
import UserList from "../routecomponents/userlist";
import BookingList from "../routecomponents/bookingslist";
import BookingStatus from "../routecomponents/bookingstatus";
import { useLazyGetallQuery } from "../redux/user"; 
import InfoHeader from "../components/InfoHeader";


import CustomPagination from "../components/Pagination";


function BookingsPage(){
  const [active, setActive] = useState(0); // Index of the active tab
  const [currentPage, setCurrentPage] = useState(1);
  const [getall,{ data, isLoading, refetch }] = useLazyGetallQuery();
  const [search_param,setSearch] = useState();
data&& console.log(data,'bookinh')
  const handleTabChange = (index) => {
    setActive(index);
    setCurrentPage(1); // Reset page when switching tabs
    // Fetch data for the selected tab
    getall({ scope:'bookings', page: currentPage, tab: tabs[index].key });
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // Fetch data whenever the active tab or page changes
   getall({ scope: "bookings", page: currentPage,tab:tabs[active].key,search:search_param });
  }, [refetch,search_param,currentPage]);
  const search=(e)=>{
    console.log(e.target.value,'kk')
    setSearch(e.target.value)
    }
  const tabs=[
    {
        title:'Booking List',
        content: <BookingList data={data?data.bookings:[]} />
      },
  {
    title:'Booking Status',
    content:  <BookingStatus data={data?data.bookings:[]}/>
  },

]

return(
  <>
<InfoHeader
      title={'Bookings'}
      
      />    
 
    <TabView  show={true} children={<Query search={search} refetch={refetch}/>} tabs={tabs} setActive={setActive} />

    <CustomPagination
         data={data}
         handlePageChange={handlePageChange}
       />
    </>
)
}

export default BookingsPage