import { Avatar } from "antd";
import React from "react";
import bin from '../assets/svgs/bin.svg'
import edit from '../assets/svgs/edit.svg'
import InfoDrawer from "../components/InfoDrawer";
import { bookingAttributes } from "../_data/keys";

import { Button, Modal, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import EditDropdown from "./Editmenu";
import { formatDate } from "../function";
const { confirm } = Modal;
const BookingStatusRow = ({ data }) => {
  console.log(data.date)

  function getCurrentTime(date) {
    const now = new Date(date);
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedTime = `${hours}:${padZero(minutes)} ${ampm}`;
    return formattedTime;
  }

  function padZero(number) {
    return number < 10 ? '0' + number : number;
  }
  const [open, setOpen] = React.useState(false);
  console.log(data);


  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure delete this task?',
      icon: <ExclamationCircleFilled />,
      content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        alert(id)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  return (
    <div key={data.id} className="tred2"
    >

      <div className="flex-item" onClick={() => console.log(data.id)}>{data?.user.username}</div>
      <div className="flex-item">{data?.vendor.username}</div>
      <div className="flex-item">{formatDate(data?.date)}</div>
      <div className="flex-item">{getCurrentTime(data?.time)}</div>
      <div className="flex-item amount price">₵ {parseFloat(data?.amount_paid).toFixed(2)}</div>
      <div className="flex-item price">₵ {parseFloat(data?.total).toFixed(2)}</div>
      <div className="flex-item d-flex edit">
        <img onClick={() => showDeleteConfirm(data.id)} src={bin} />
        <img onClick={() => setOpen(!open)} src={edit} style={{ width: 30, marginLeft: 10 }} />
      </div>
      <InfoDrawer keys={bookingAttributes} scope={'Booking'} userData={data} open={open} setOpen={setOpen} />

    </div>
  );
};

export default BookingStatusRow;