import React from "react";


const TextInput=({onChange, disabled,style, onBlur, placeholder,className,type,value})=>{
    return(
        <input
        onBlur={onBlur}
        
        value={value}
  type={type}
  style={style}
  disabled={disabled}
    className={`custom-input ${className}`} 
        placeholder={placeholder}
        onChange={onChange}
     />
    )

}

export default TextInput
