import React from "react";
import Table from "../tables/Table";
import TableHeadItem from "../tables/TableHead";
import UserListRow from "../tables/UserlistRows";
import { Affix } from "antd"
import EmployeeList from "./EmployeeList";
import EmployeeStatusRow from "../tables/EmployeeStatusRow";

//import { useGetApprovedVendorsQuery } from "../redux/user";
const EmployeeStatus=({data,refetch})=>{
    const theadData = ["Name",'Position', "Amount", "Status", 'Updated'];
  //    const{ data,isLoading}= useGetApprovedVendorsQuery()
   // console.log(data&&data)
    return (
        <div className="table-head-container">
            <Affix offsetTop={200} className="">
        <div className="table-head">
          {theadData.map((h) => {
            return <TableHeadItem key={h} item={h} />;
          })}
        </div>
        </Affix>
        <div className="reqbody">
        {data.map((item) => {
                    
                    return ( <EmployeeStatusRow refetch={refetch} key={item.id} data={item} />);
                })} 
        </div>
      </div>
      
    );
}

export default EmployeeStatus