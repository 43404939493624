import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PartnersWaitlist from "../components/PartnersWaitlist";
import { ReactComponent as Partner } from "../assets/svgs/partner.svg";
import { ReactComponent as Arrow } from "../assets/svgs/arrow.svg";
import Menu from "../components/Menu";

// import Menu from "../components/Menu";
const PartnersWaitlistPage = () => {
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  let menuBtn;
  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  const handleShow = (data) => {
    console.log("here", data);
    if (menuBtn) {
      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        console.log("else");
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };
  return (
    <div className="appBg  overflow-x-hidden">
      {show && <Menu handleShow={handleShow} />}
      <Navbar show={show} handleShow={handleShow} />
      <div className="relative become-partner-wrapper ">
        <Partner className="mx-auto become-partner  " />
        <div className="partner-arrow-wrapper">
          <Arrow className="mx-auto partner-arrow hide-mobile" />
        </div>
      </div>
      <PartnersWaitlist />
      {/* <div className="relative"> */}
      <Arrow className=" mx-auto  form-arrow hide" />
      {/* </div> */}
      <div className="appTextBg mt-30 relative">
        <Footer style="partner-footer" />
      </div>
    </div>
  );
};

export default PartnersWaitlistPage;
