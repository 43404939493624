import React, { useState } from "react";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setuser } from "../redux/login";
import axios from "axios";
import { Modal, Button, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { backendURL } from "../redux/http";
import TextInput from "./Input";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [email, setEmail] = useState();
  const [otp, setOtp] = useState();

  const [password, setPassword] = useState();
  const [error, setError] = useState(null);
  const [otp_error, setOtpErr] = useState(null)
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const verify = async () => {
    setOtpErr('')
    try {
      const { data } = await axios.post(`${backendURL}/verify_otp`, {
        otp,
        email
      });

      setOtpErr(data.message)
      setIsOpen(false)
      loginHandler()

    }
    catch (e) {
      console.log(e)
      setOtpErr(e.response.data.message)
    }

  }

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setOtpErr('')
    setIsOpen(false);
  };


  const loginHandler = async (e) => {
    e?.preventDefault();
    setLoading(true);

    try {
      const { data } = await axios.post(`${backendURL}/pamp-member/login`, {
        pamp_member: { email, password },
      });

      dispatch(setuser(data));
      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("token", data.token);

      navigate("/admin/dashboard");

      setError(null); // Clear any previous errors on successful login
    } catch (error) {
      setError(error.response.data.message);
      if (error.response.data.message == 'Invalid device') {
        setOtpErr('')
        setOtp('')
        setIsOpen(!isOpen)
      }
      console.error("Login failed", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form action="#" onSubmit={loginHandler}>
      {/* Error message */}
      <div className="error">{error}</div>
      <h2 style={{ marginBottom: 20 }}>Sign In</h2>

      {/* Email input */}
      <input
        type="email"
        id="email"
        name="email"
        className="form-field"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />



      {/* Password input with eye icon */}
      <div className="password-input-container">
        <input
          type={showPassword ? "text" : "password"}
          id="password"
          name="password"
          className="form-field password-input"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {/* Eye icon to toggle password visibility */}
        {showPassword ? (
          <EyeInvisibleOutlined
            className="toggle-password-icon"
            onClick={() => setShowPassword(false)}
          />
        ) : (
          <EyeOutlined
            className="toggle-password-icon"
            onClick={() => setShowPassword(true)}
          />
        )}
      </div>

      {/* Loading spinner or login button */}
      {loading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <button type="submit" className="lgbtn">
          Login
        </button>
      )}

      <Modal
        title="Device Verification"
        open={isOpen}
        onCancel={hideModal}
        footer={[
          <Button className="unapproved" key="back" onClick={verify}>
            Verify
          </Button>
        ]}
        width={400}
      >
        <h6 className="mt-[20px] text-darkgreen  ">Enter the code sent to your email</h6>


        <p className="text-danger">{otp_error}</p>
        <Input
          className={'flex-1 rounded-xxl  w-[50%] active:border-lightgreen border rounded-[20px]  p-2 h-[40px] '}
          placeholder={'code'}
          value={otp}
          onChange={(e) => setOtp(e.target.value)}

        />
      </Modal>
    </form>
  );
};

export default LoginForm;
