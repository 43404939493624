import React from 'react';

const PaystackPayment = ({ publicKey, email, amount, currency, reference, onSuccess, onClose, button,customOnClick }) => {
    React.useEffect(() => {
        // Create a script element
        const script = document.createElement('script');
        script.src = 'https://js.paystack.co/v1/inline.js';
        script.async = true;
    
        // Append the script to the head of the document
        document.head.appendChild(script);
    
        // Cleanup: Remove the script when the component is unmounted
        return () => {
          document.head.removeChild(script);
        };
      }, []);

      const handleButtonClick = async() => {
        // Your custom logic before Paystack payment
        console.log('Executing custom logic before Paystack payment');
    
        // Call your custom onClick function
        const shouldProceedWithPaystack = await customOnClick();
        console.log(shouldProceedWithPaystack ,'kkk')
        // If customOnClick logic fails, do not proceed w
    if (!shouldProceedWithPaystack) {
        console.log(shouldProceedWithPaystack ,'kkk')
      // If customOnClick logic fails, do not proceed with Paystack logic
      return;
    }
    
        // Now call the Paystack payment logic
        payWithPaystack();
      };


  const payWithPaystack = () => {
    const handler = window.PaystackPop.setup({
      key: publicKey,
      email: email,
      amount: amount * 100,
      currency: currency,
      ref: reference,
      callback: onSuccess,
      onClose: onClose,
    });

    handler.openIframe();
  };

  return (
    <div>
      {button ? (
        React.cloneElement(button, { onClick: handleButtonClick })
      ) : (
        <button type="button" onClick={handleButtonClick}>
          Pay with Paystack
        </button>
      )}
    </div>
  );
};

export default PaystackPayment;
