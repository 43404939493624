import React, { useState,useEffect } from 'react';
import { Radio, Select, Typography } from 'antd';
import { useGetallQuery } from '../redux/user';
import Checkbox from '../components/Checkbox';
import { useAddSubscriptionMutation } from '../redux/user';
import { Alert } from 'react-bootstrap';
import { useLazyGetallQuery } from "../redux/user"; 

const { Text } = Typography;

const NewSubscription = () => {
  const [addsub]=useAddSubscriptionMutation()
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [activate, setActivate] = useState(false);
  const [loading, setLoading] = useState(false);
  // Fetch vendors and plans using RTK Query
  const [getVendors,{ data: vendors,}] = useLazyGetallQuery();
  const [getPlans,{ data: plans,}] = useLazyGetallQuery();
  const paymentStatusOptions = ['Paid', 'Pending', 'Failed', 'Refunded'];
;
const handleSave = async () => {
  const { date, name } = calculatePriceAndRenewalDate(selectedPlan, selectedDuration);
  setLoading(true);
  if(!selectedVendor||!name||!selectedDuration[0]){
  alert('Please fill all relevant infomation')
  setLoading(false);
  return
  }
  try {
  

    // Perform API post with the selected data
  const {data} = await addsub({
      vendor_id: selectedVendor,
      name: name,
      type: selectedDuration[0].toLowerCase(),
      paymentStatus,
      activate,
      end_date: date,
    });
console.log(data)
  alert(data.message)
  } catch (error) {
    alert(error.error)
  } finally {
    setLoading(false);
  }
 
}

useEffect(() => {
  // Fetch data whenever the active tab or page changes
 getVendors({ scope: "list",type:'vendors'});
getPlans({scope: 'list',type:'packages'})
}, []);
  function calculatePriceAndRenewalDate(id, type) {
    const plan = plans.find(plan => plan.id === id);
    if (!plan) {
      return { price: null, renewalDate: null }; // Plan not found
    }
  
    let price = parseFloat(plan.price);
  
    if (type === 'Yearly') {
      price *= 12; // Calculate the yearly price
    }
  
    if (plan.discount !== null) {
      const discountPercentage = parseFloat(plan.discount);
      const discountAmount = (discountPercentage / 100) * price;
      price -= discountAmount;
    }
  
    let renewalDate = new Date();
  
    if (type === 'Yearly') {
      renewalDate.setFullYear(renewalDate.getFullYear() + 1); // Set renewal date to next year
    } else {
      renewalDate.setMonth(renewalDate.getMonth() + 1); // Set renewal date to next month
    }
  
    return  {date:renewalDate.toISOString(),name:plan.name} ;
  }

  const handleDurationChange = (value) => {
    setSelectedDuration(value);
  };

  const handleActivateChange = () => {
    setActivate(!activate);
  };



  return (
    <div className="flex gap-5">

      <div className="flex-1">
        <h2 className="mt-[50px] title-med">Vendor</h2>
        <Select
          showSearch
          className="input"
          bordered={false}
          placeholder="Select a vendor"
          optionFilterProp="children"
          onChange={(value) => setSelectedVendor(value)}
        >
          {vendors?.map((vendor) => (
            <Select.Option key={vendor.id} value={vendor.id} label={vendor.username}>
              {vendor.name}
            </Select.Option>
          ))}
        </Select>

        <h2 className="mt-[50px] title-med">Subscription Plan</h2>
        <Select
          showSearch
          className="input"
          bordered={false}
          placeholder="Select a plan"
          optionFilterProp="children"
          onChange={(value) => setSelectedPlan(value)}
        >
          {plans?.map((plan) => (
            <Select.Option key={plan.name.id} value={plan.id} label={plan.name}>
              {plan.name}
            </Select.Option>
          ))}
        </Select>

        <h2 className="mt-[50px] title-med">Duration</h2>
        <div className="flex gap-3 ml-[2rem] mt-[1rem]">
          <Checkbox label={'Monthly'} onChange={() => handleDurationChange(['Monthly'])} checked={selectedDuration.includes('Monthly')} />
          <Checkbox label={'Yearly'} onChange={() => handleDurationChange(['Yearly'])} checked={selectedDuration.includes('Yearly')} />
        </div>
      </div>
      <div className="flex-1">
        <h2 className="mt-[50px] title-med">Payment Status</h2>
        <Select
          showSearch
          className="input w-100"
          bordered={false}
          placeholder="Select payment status"
          optionFilterProp="children"
          onChange={(value) => setPaymentStatus(value)}
        >
           {paymentStatusOptions.map((plan,id) => (
            <Select.Option key={id} value={plan} label={plan}>
              {plan}
            </Select.Option>
          ))}
        </Select>
        <div className="ml-activa d-flex mt-[50px] gap-3 content-center items-center">

          <Checkbox label={'Activate'} onChange={handleActivateChange} />
        </div>
        <div className="flex gap-3 justify-end mt-[180px]">
          <div style={{ width: 100 }} className="action d-flex unapproved" onClick={handleSave}>
          {loading ? 'Saving...' : 'Save'}
          </div>
          <div style={{ width: 100, background: '#DAECD2', color: '#00463' }} className="action w-[50px] d-flex">
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSubscription;
