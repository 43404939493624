import React from "react";
import user from '../assets/images/user.png'

const EarningsCont=({data,img,bg,title,titleColor,subtitleColor,dataColor})=>{
    return(
        <div className="userdetcont" style={{backgroundColor: bg? bg:'white'}}>
            <div className="d-flex gap-3">
            <div>{img}</div>
            <div className="user-title" style={{color: titleColor?titleColor:'white'}}>{title}</div>
        </div>
            <div className="innertext1" style={{ color: dataColor ? dataColor : 'white' }}>₵{parseFloat(data).toFixed(2)}</div>
        <div  style={{color: subtitleColor?subtitleColor:'white'}} className="innertext2">Total Earnings</div>
        </div>

    )
}

export default EarningsCont