import React from 'react';
import { useGetVendorQuery } from '../redux/user';
import VendorProfile from '../pages/VendorProfile';

const VendorProfileContainer = ({id}) => {
  const { data, isLoading, isError,refetch } = useGetVendorQuery(id);

 React.useEffect(()=>{
     refetch()
 },[]) 
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading vendor data</div>;
  }

  return (
    <div>
      {data && (
        <VendorProfile vendor={data} />
      )}
    </div>
  );
};

export default VendorProfileContainer;
