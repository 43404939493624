import React, { useState, useEffect, useRef } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Input, Radio, Select, Typography, Avatar } from 'antd';
import { useGetallQuery } from '../redux/user';
import Checkbox from '../components/Checkbox';
import EditService from './EditService';
import { formatDateReadable } from '../function';
import { Button, Drawer, List } from 'antd';
import { useAddSubscriptionMutation } from '../redux/user';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useLazyGetallQuery } from "../redux/user";
import TextInput from '../components/Input';
import SelectInput from '../components/Select';
import userImage from '../assets/images/userimg.png';
import { useCreateMutation, useLazyBankQuery } from '../redux/user';
import { backendURL } from '../redux/http';
import axios from 'axios';
import FileUpload from '../components/MultiFileUpload';
import DocumentRenderer from '../components/DocumentRener';
import { DateInput } from '../function';
import FileDrawer from '../components/FileUpdateDrawer';
import OpeningDrawer from '../components/OpeningDrawer';
import Gallery from './GalleryPage';
const { Text } = Typography;

const EditVendor = ({ isOpen, setOpen, data, refetch }) => {
  const [hourOpen, sethourOpen] = useState(false)
  const [createUser] = useCreateMutation()
  const [fileOpen, setFileOpen] = useState(false)
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [getbanks] = useLazyBankQuery()
  const [serviceVisible, setServiceVisible] = useState(false)
  const [banks, setBanks] = useState([])
  const [imageSource, setImageSource] = useState(null);
  const [file, setImageFile] = useState()
  const [formInfo, setFormData] = useState(data);
  const imageInputRef = useRef(null);
  const formRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles(Array.from(files));
  };
  const handleSave = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.info('Updating vendor...', {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
    });
    try {

      const formData = new FormData();

      // Append user fields
      Object.entries(formInfo).forEach(([field, value]) => {
        formData.append(`vendor[${field}]`, value);
      });

      // Append email confirmation if true


      // Append files





      // Append additional fields like type and scope
      formData.append('model', 'Vendor');
      formData.append('id', data.id);
      formData.append('scope', "vendor");


      console.log(formInfo, formData); // Log FormData here

      // Call the createUser mutation with FormData
      const response = await axios.patch(`${backendURL}/admin/update`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
        }
      );
      refetch()
      // Reset form and dismiss loading toast on success
      if (imageInputRef.current) {
        imageInputRef.current.value = '';
      }
      if (formRef.current) {
        formRef.current.reset();
      }
      toast.dismiss(loadingToastId);
      // refetch()
      // Handle successful creation response
      console.log(response.data);
      toast.success('Vendor updated successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      toast.dismiss(loadingToastId);
      console.log(error)
      error.response.data.errors.map((err) => {


        toast.error(err, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })

      console.log(error);
    }
  };



  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));

  };



  console.log(data)


  React.useEffect(() => {
    console.log(data)
  }, [selectedFiles]);
  return (
    <Drawer title={`${formInfo.username} joined on ${formatDateReadable(formInfo.created_at)}`} width={800} placement="right" closable={true} onClose={() => setOpen(!isOpen)} open={isOpen}>


      <form className='bg-bg w-100' ref={formRef}>
        <div className="flex gap-5">

          <div className="flex-1">
            <h2 className="mt-[50px] title-med">Business Name</h2>
            <div className='flex gap-5 p-[0px]'>

              <TextInput
                className={'flex-1 input'}
                placeholder={'First Name'}
                onChange={(e) => handleChange('username', e.target.value)}
                value={formInfo.username}
              />

            </div>
            <h2 className="mt-[50px] title-med">Role</h2>
            <SelectInput
              className={'input'}
              defaultValue={formInfo.role}
              onChange={(e) => handleChange('role', e)}

              placeholder={'Select Role'}
              data={[
                {
                  name: 'vendor',
                  id: 1
                },
                {
                  id: 2,
                  name: 'extra'
                },


              ]}
            />


            <h2 className="mt-[50px] title-med">Date Joined</h2>
            <TextInput
              className={'flex-1 input'}
              disabled={true}
              placeholder={'date vendor joined pamp'}
              type={'date'}
              value={DateInput(formInfo.created_at)}
              onChange={(e) => handleChange('phone', e.target.value)}
     

            />
            <h2 className="mt-[50px] title-med">Phone Number</h2>
            <TextInput
              className={'flex-1 input'}
              placeholder={'Phone'}
              onChange={(e) => handleChange('phone', e.target.value)}
              value={formInfo.phone}

            />
            <h2 className="mt-[50px] title-med">Top Services</h2>
            <TextInput
              className={'flex-1 input'}
              placeholder={'01/10/2024'}
              type={'text'}
              value={formInfo.top_services}
              onChange={(e) => handleChange('top_services', e.target.value)}

            />



            <h2 className="mt-[50px] title-med">Address</h2>
            <TextInput
              className={'flex-1 input'}
              placeholder={'Insert location'}
              onChange={(e) => handleChange('name', e.target.value)}
              value={formInfo.name}

            />

            <h2 className="mt-[50px]  title-med">Description</h2>
            <textarea
              value={formInfo.description}
              style={{ height: 200, padding: 20 }}
              
              className={'flex-1 mb-[10px] input'}
              placeholder={'Insert description'}
              onChange={(e) => handleChange('description', e.target.value)}


            />
          </div>
          <div className="flex-1">
            <div className='mb-[65px]' >
              <h2 className="mt-[50px] title-med">Email</h2>
              <TextInput
              disabled={true}
                className={'flex-1 input'}
                placeholder={'Email'}
                onChange={(e) => handleChange('email', e.target.value)}
                value={formInfo.email}

              />

            </div>



            <h2 className="mt-[50px] title-med">Approved</h2>
            <SelectInput
              defaultValue={formInfo.approved.toString()}

              className={'input'}

              onChange={(e) => {
                handleChange("approved", e)

              }

              }
              placeholder={'Select'}
              data={[
                {
                  name: 'true',
                  id: 1
                },
                {
                  id: 2,
                  name: 'false'
                },
              ]} />



            <h2 className="mt-[50px] title-med">Phone Confirmed</h2>
            <SelectInput
              defaultValue={formInfo.phone_confirmed.toString()}

              className={'input'}

              onChange={(e) => {
                handleChange("phone_confirmed", e)

              }

              }
              placeholder={'Select'}
              data={[
                {
                  name: 'true',
                  id: 1
                },
                {
                  id: 2,
                  name: 'false'
                },
              ]} />



            <h2 className="mt-[50px] title-med">Verification Badge</h2>
            <SelectInput
              defaultValue={formInfo.verification_badge.toString()}
              className={'input'}
              onChange={(e) => {
                handleChange("verification_badge", e)
              }

              }
              placeholder={'Select'}
              data={[
                {
                  name: 'true',
                  id: 1
                },
                {
                  id: 2,
                  name: 'false'
                },
              ]} />



            <h2 className="mt-[50px] title-med">Subcription Start Date</h2>
            <TextInput
              className={'flex-1 input'}
              placeholder={'01/10/2024'}
              type={'date'}
              value={DateInput(formInfo.subscription_start_date)}
              onChange={(e) => handleChange('subscription_start_date', e.target.value)}

            />

            <h2 className="mt-[50px] title-med">Subcription End Date</h2>
            <TextInput
              className={'flex-1 input'}
              placeholder={'01/10/2024'}
              type={'date'}
              value={DateInput(formInfo.subscription_end_date)}
              onChange={(e) => handleChange('subscription_end_date', e.target.value)}

            />
            <h2 className="mt-[50px] title-med">Business Categories</h2>
            <div className='flex flex-wrap gap-[10px]'>
              {
              formInfo.categories.map((category, index) => {
                return <div style={{ width: 100 }} className="action d-flex unapproved" >
                    {category.name}
                   </div>
                })
              }
            </div>


          </div>



        </div>





        <div className="flex gap-3 justify-end mt-[100px] mb-[100px]">
          <div style={{ width: 100 }} className="action d-flex unapproved" onClick={handleSave}>
            Update
          </div>
          <div style={{ width: 100, background: '#DAECD2', color: '#00463' }} onClick={() => setOpen(!isOpen)} className="action w-[50px] d-flex">
            Cancel
          </div>
        </div>

        <div style={{}}>
          <div style={{ paddingInline: 30, fontWeight: 700, paddingTop: 20 }}>
            <div className="font-source-sans-pro text-darkgreen text-[20px] text-bold">Vendor Services</div>
          </div>
          <List
            dataSource={data.service_categories}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                onClick={() =>{
                  setSelectedCategory(item)
                  setServiceVisible(!serviceVisible)
                } }
                style={{ cursor: 'pointer', borderBottomColor: '#B0EBBD', }}
              >
                <List.Item.Meta
                  style={{ paddingInline: 30, }}
                  avatar={!item.read ? <div className="mt-[6px] rounded-full w-[10px] h-[10px] bg-lightgreen"></div> : <></>}
                  title={<div style={{ fontWeight: 700 }} className="text-source-sans-pro text-darkgreen  text-[15px]">{item.name}</div>}
                  description={<div className="text-source-sans-pro text-[12px]">{}</div>}
                />
              </List.Item>
            )}
          />
        </div>


        <button onClick={(e) => {
       
          e.preventDefault()
          sethourOpen(!hourOpen)
        }

        } className='w-[220px] ml-5 text-white h-[40px] mt-5 rounded-[20px] bg-lightgreen'>Availability and Preference  >
        </button>
        <button onClick={(e) => {

          e.preventDefault()
          setDrawerVisible(!drawerVisible)
        }

        } className='w-[220px] ml-5 text-white h-[40px] mt-5 rounded-[20px] bg-lightgreen'>Vendors Gallery  >
        </button>
        
      </form>
      <EditService refetch={refetch} vendor_id={data.id} data={selectedCategory} isOpen={serviceVisible} setOpen={setServiceVisible} />
      <OpeningDrawer refetch={refetch} data={data} open={hourOpen} SetOpen={sethourOpen} />
      <Gallery refetch={refetch} setDrawerVisible={setDrawerVisible} drawerVisible={drawerVisible} data={data}/>
    </Drawer>

  );
};

export default EditVendor;
