import React from "react";

const Services = () => {
  const services = [
    "Spa",
    "Hair Salon",
    "Beauty Salon",
    "Nail Salon",
    "Gym & Fitness",
    "Eyebrows & Lashes",
    "Weight Loss",
    "Massage",
    "Personal Trainer",
    "Waxing Salon",
    "Facials",
    "Tattoo & Piercing",
    "Therapy Center",
    "& More",
  ];
  return (
    <section className=" appSecondaryBg  ">
      <div className="container ">
        <h3 className="services-title">10+ services you <span className="break-text"></span> can book on Pamp</h3>
        <div className="service-sm  w-fit mx-auto">
          <div className="service-flex-sm">
            <span className="service-item-sm">Spa</span>
            <span className="service-item-sm">Hair Salon</span>
            <span className="service-item-sm">Beauty Salon</span>
          </div>

          <div className="service-flex-sm ">
            <span className="service-item-sm">Massage</span>
            <span className="service-item-sm">Personal Trainer</span>
            <span className="service-item-sm">Waxing Salon</span>
          </div>

          <div className="service-flex-sm ">
            <span className="service-item-sm">Weight Loss</span>
            <span className="service-item-sm">Tattoo &amp; Piercing</span>
            <span className="service-item-sm">Nail Salon</span>
          </div>

          <div className="w-full service-flex-sm">
            <div className="grid grid-cols-[120px_100px] w-fit  mx-auto  ">
              <span className="service-item-sm">Therapy Center</span>
              <span className="service-item-sm-nb">&amp; More</span>
            </div>
          </div>
        </div>

        <div className="services-wrapper service-md mt-10 py-10">
          <div className="service-flex">
            <span className="service-item">Spa</span>
            <span className="service-item">Hair Salon</span>
            <span className="service-item">Beauty Salon</span>
          </div>

          <div className="service-flex ">
            <span className="service-item">Nail Salon</span>
            <span className="service-item">Gym &amp; Fitness</span>
            <span className="service-item">Eyebrows &amp; Lashes</span>
          </div>

          <div className="service-flex">
            <span className="service-item">Weight Loss</span>
            <span className="service-item">Massage</span>
            <span className="service-item">Personal Trainer</span>
          </div>
          <div className="service-flex">
            <span className="service-item">Waxing Salon</span>
            <span className="service-item">Facials</span>
            <span className="service-item">Tattoo &amp; Piercing</span>
          </div>
          <div className="service-flex ">
            <span className="service-item-nb"></span>
            <span className="service-item">Therapy Center</span>
            <span className=" service-item-nb">&amp; More</span>
            <span className="service-item-nb"></span>
          </div>
        </div>

        <div className="servives-xs hidden">
          {services.map((ele, i) => {
            return (
              <span key={i} className="service-item-sm">
                {ele}
              </span>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
