import React, { useState } from "react";
import { Checkbox } from "antd";
import Table from "../tables/Table";
import TableHeadItem from "../tables/TableHead";
import EmployeeListRow from "../tables/EmployeeListRow";
import { Affix } from "antd"

const EmployeeList = ({ data, refetch,selectedEmployees,setSelectedEmployees }) => {
    const [selectAll, setSelectAll] = useState(false); // State to determine if all items are selected

    const theadData = ["Name", 'Position', "Bank", "Acc Number", 'Salary', 'Action'];

    // Function to toggle employee selection
    const toggleEmployeeSelection = (employeeId) => {
        if (selectedEmployees.includes(employeeId)) {
            setSelectedEmployees(selectedEmployees.filter(id => id !== employeeId));
        } else {
            setSelectedEmployees([...selectedEmployees, employeeId]);
        }
    };

    // Function to handle select all checkbox
    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedEmployees([]);
        } else {
            const allEmployeeIds = data.map(item => item.id);
            setSelectedEmployees(allEmployeeIds);
        }
        setSelectAll(!selectAll);
    };

    return (
        <div className="table-head-container">
            <Affix offsetTop={200} className="">
                <div className="table-head">
                    {theadData.map((h) => (
                        <TableHeadItem
                            key={h}
                            show
                            item={h}
                            checked={selectAll}
                            onChange={handleSelectAll}
                        />
                    ))}
                </div>
            </Affix>
            <div className="reqbody">
                {data.map((item) => (
                    <EmployeeListRow
                        key={item.id}
                        data={item}
                        refetch={refetch}
                        selected={selectedEmployees.includes(item.id)} // Pass whether the employee is selected
                        toggleSelection={toggleEmployeeSelection} // Pass down the function to toggle selection
                    />
                ))}
            </div>
        </div>
    );
};

export default EmployeeList;
