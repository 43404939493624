import TextInput from "./Input"
import SelectInput from "./Select"
import React, { useState, useEffect, useRef } from 'react';
import { useCreateMutation } from "../redux/user";
import { useLazyGetallQuery, useLazyBankQuery } from "../redux/user";
import { toast } from 'react-toastify';

const QuickTransfer = ({ refetch }) => {
  const [getall, { data: vendors, isLoading }] = useLazyGetallQuery();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [getbanks] = useLazyBankQuery()
  const [createCredit] = useCreateMutation()
  const [banks, setBanks] = useState([])
  const formRef = useRef(null);
  const bgSlate = { backgroundColor: '#f7f7f9' }
  const [formInfo, setFormData] = useState({});
  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));

  };
  useEffect(() => {
    // Fetch data whenever the active tab or page changes
    getall({ scope: 'list', type: 'vendors' });
  }, []);
  const getBanks = async () => {
    try {

      const { data } = await getbanks({ scope: 'list' })
      setBanks(data.bank_data)
      console.log(data.bank_data)
    } catch (e) {
      console.log(e)
    }

  }



  const getDetails = async () => {
    try {

      const { data } = await getbanks({ scope: 'details', account_number: formInfo?.account_number, bank_code: formInfo?.bank_code })
      setName(data.bank_name)

    } catch (e) {
      setName('')
      toast.error('Account not found', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }


  const getBname = (code) => {
    const ban = banks.find((b) => b.code == code)
    console.log(ban)
    if (ban) {

      return ban.name

    }

    return ban?.name


  }


  const handleSave = async () => {
    setLoading(true);
    console.log(formInfo)

    try {

      const loadingToastId = toast.info('crediting account...', {
        position: 'top-right',
        autoClose: false, // Do not auto close loading toast
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
      });


      formInfo?.type === "Wallet" ?

        await createCredit({ ...formInfo, type: 'vendor_credit' }) :
        await createCredit({ scope: 'create', type: 'create_quick_recipient', ...formInfo, bank_name: getBname(formInfo.bank_code), name })
      refetch()
      // Hard reset form
      if (formRef.current) {
        formRef.current.reset();
      }
      toast.dismiss(loadingToastId);
      //console.log(response.data)
      toast.success('Account credited successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      // Perform API post with the selected data

      // console.log(data)
      //   alert(data.message)
    } catch (error) {
      alert(error.error)
    } finally {
      setLoading(false);
    }

  }


  useEffect(() => {
    getBanks()
  }, [])
  return (
    <>
      <form ref={formRef} className='no-scrollbar' >
        <h2 className="update-title mt-[80px]">Quick Transfer</h2>
        <SelectInput
          defaultValue={'Revenue'}
          data={[{
            id: 1,
            name: 'Revenue'
          },
          {
            id: 2,
            name: 'Booking Fee'
          },
          {
            id: 3,
            name: 'Charges'
          },
          {
            id: 4,
            name: 'Vendor Transfer'
          }

          ]
          }
          onChange={(e) => handleChange('vendor', e)}
          className={'input'}
        />
        <div className="bg-white p-4 rounded-[20px]">
          <SelectInput
            style={bgSlate}
            defaultValue={'Bank'}
            data={[
              {
                name: 'Bank',
                id: 1
              },
              {
                name: 'Wallet',
                id: 2
              }
            ]}
            className={'input bg-bg'}
            onChange={(e) => handleChange('type', e)}
          />



          {formInfo?.type === "Wallet" ?


            <SelectInput
              style={bgSlate}
              type={'id'}
              placeholder={'Select vendor'}
              data={vendors}
              className={'input bg-bg'}
              onChange={(e) => {

                handleChange('vendor_id', e)
              }}
            /> :
            <>

              <SelectInput
                style={bgSlate}
                type={'code'}
                placeholder={'Select bank'}
                data={banks}
                className={'input bg-bg'}

                onChange={(e) => {
                  handleChange('bank_code', e)

                }}

              />
              <TextInput
                placeholder={'Account Number'}
                style={bgSlate}
                onBlur={getDetails}
                className={'input bg-bg'}
                onChange={async (e) => {

                  handleChange('account_number', e.target.value)


                }}

              />


              <TextInput
                style={bgSlate}
                value={name}
                placeholder={'Account Name'}
                className={'input bg-bg'}
              />
            </>
          }

          <TextInput
            placeholder={'Enter Amount'}
            onChange={(e) => handleChange('amount', e.target.value)}
            style={bgSlate}
            className={'input bg-bg'}
          />
          <TextInput
            placeholder={'Enter Transfer Pin'}
            style={bgSlate}
            className={'input bg-bg'}
            onChange={(e) => handleChange('pin', e.target.value)}

          />
          <div style={{ width: '95%', height: 50, margin: 'auto', }} className="action d-flex unapproved" onClick={handleSave}>
            {loading ? 'Sending...' : 'Send'}
          </div>
        </div>

      </form>
    </>
  )

}

export default QuickTransfer