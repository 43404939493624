import React, { useState, useEffect } from "react";
import TabView from "../components/Tabview";
import Query from "../components/Query";
import VendorRequest from "../routecomponents/vendorequest";
import VendorApproved from "../routecomponents/vendorapproved";
import UserList from "../routecomponents/userlist";
import { useGetallQuery } from "../redux/user";
import { useLazyGetallQuery,useCreateMutation } from "../redux/user"; 
import { toast } from 'react-toastify';

import CustomPagination from "../components/Pagination";
import CreateUser from "./CreateUserPage";
import EmployeeList from "../routecomponents/EmployeeList";
import EmployeeStatusRow from "../tables/EmployeeStatusRow";
import CreateEmployee from "./CreateEmployee";
import EmployeeStatus from "../routecomponents/EmployeeStatus";
import SearchAndPay from "../components/SearchAndPay";
function EmployeesPage(){
  const [active, setActive] = useState(0); // Index of the active tab
  const [currentPage, setCurrentPage] = useState(1);
  const [search_param,setSearch] = useState();
  const [selectedEmployees, setSelectedEmployees] = useState([]); // State to hold selected employees
  const [create,{isLoading}] = useCreateMutation()
  const [getall,{ data }] = useLazyGetallQuery();

const pay=async()=>{
  if (selectedEmployees.length===0){
    toast.error('Please at least one employee!', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
  });
  return
  }
 const pamp_member_ids= Array.isArray(selectedEmployees) ? selectedEmployees : [selectedEmployees]
 const {data}= create({type:'create_payslips', scope:'create', pamp_member_ids})
 
 toast.success('Payments queued successfully', {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
});  
 console.log(pamp_member_ids)
}


  const handleTabChange = (index) => {
    setActive(index);
    setCurrentPage(1); // Reset page when switching tabs
    // Fetch data for the selected tab
    getall({ scope:'employees', page: currentPage, tab: tabs[index].key });
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const refetch=()=>{
    getall({ scope: "employees", page: currentPage,tab:tabs[active].key,search:search_param });

  }

  useEffect(() => {
    // Fetch data whenever the active tab or page changes
   getall({ scope: "employees", page: currentPage,tab:tabs[active].key,search:search_param });
  }, [search_param,currentPage]);
  const search=(e)=>{
    
    setSearch(e.target.value)
    }
console.log(data)
  const tabs=[
    {
      key: 'list',
        title:'List',
        content: <EmployeeList selectedEmployees={selectedEmployees} setSelectedEmployees={setSelectedEmployees} refetch={refetch} data={data?data.users:[]  }/>
      },
      { key: 'status',
        title:'Status',
        content: <EmployeeStatus refetch={refetch} data={data?data.users:[]  }/>
      },
      
  {
    title:'+ Create',
    content: <CreateEmployee/>
  },

]

return(
    <><h2 className="update-title title">Employees</h2>
   <TabView  show={active===2?false:true} children={active==0&&<SearchAndPay pay={pay} isLoading={isLoading}  search={search} data={data?.users} selectedEmployees={selectedEmployees}/>} tabs={tabs} setActive={handleTabChange} />
<CustomPagination
         data={data}
         show={active===2?false:true}
         handlePageChange={handlePageChange}
       />
    </>
)
}

export default EmployeesPage