import React from "react";
import Checkbox from "../components/Checkbox";

const TableHeadItem = ({ item, checked, onChange,show }) => {
    return (
        <div
            style={{
                width: '100%',
                height: 40,
                padding: 4,
                display: 'flex',

                justifyContent: show && item=='Name' ?'start':'center',
                color: '#00463C',
                fontSize: 15,
                fontWeight: 'bold'
            }}
        >

        {item === "Name" && show &&   <span style={{marginRight:10,marginLeft: 30
        }}> <Checkbox label={item} showLabel={false} checked={checked} onChange={onChange} /></span> }
            {item}
        </div>
    );
};

export default TableHeadItem;
