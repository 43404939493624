import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import ClientForm from "./ClientForm";

const MailchimpFormContainer = (props) => {
  const postUrl = `https://trypamp.us9.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  return (
    <MailchimpSubscribe
      url={postUrl}
      render={({ subscribe, status, message }) => (
        <ClientForm
          status={status}
          message={message}
          onValidated={(formData) => subscribe(formData)}
        />
      )}
    />
  );
};

export default MailchimpFormContainer;
