import dayjs from "dayjs";
import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { ReactComponent as Charge } from "../assets/svgs/Charges.svg"
import { ReactComponent as Revenue } from "../assets/svgs/Revenue.svg"
import { ReactComponent as Fee } from "../assets/svgs/bookingfee.svg"
import UpdateCont from "../components/UpdateCont";
import { useLazyGetallQuery } from "../redux/user"; import AdminCharges from "../components/AdminCharges";
import EarningsCont from "../components/EarningCont";
import QuickTransfer from "../components/QuickTransfer";
import Transfers from "../components/Transfers";
import InfoHeader from "../components/InfoHeader";

function EarningsPage() {
  const containerAnimation = useAnimation();
  const [getall,{ data ,isLoading,}] = useLazyGetallQuery();
  const [getTrans, { data: transData,  }] = useLazyGetallQuery();

  const refetch = () => {
   getTrans({ scope: 'quick_transfers' })
  }

  useEffect(() => {
    getall({scope:'earnings'});
    if (!isLoading) {
      // Add more complex animations for each component
      containerAnimation.start("visible");
    }
  }, [containerAnimation, isLoading]);

  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const childVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <>
      {isLoading ? (
        <div>Loading</div>
      ) : (
        <> 
        <InfoHeader
        title={'Earnings'}
        />
        <motion.div
                      className="earnings-layout"
                      variants={containerVariants}
                      initial="hidden"
                      animate={containerAnimation}
                  >
                      <motion.div
                          className="rev"
                          variants={childVariants}
                      >
   <EarningsCont img={<Revenue/>} bg={"#86d694"} title={'Revenue'} subtitle={'Total Earnings'} data={data?.admin_charge}/>
                      </motion.div>
                      <motion.div
                          className="booking-fee"
                          variants={childVariants}
                      >
   <EarningsCont img={<Fee/>} title={'Booking Fee'} subtitleColor={"#86d694"} dataColor={"#86d694"} titleColor={'#00463C'} subtitle={'Total Earnings'} data={0}/>
                      </motion.div>
                      <motion.div
                          className="transaction"
                          variants={childVariants}
                      >
                          <Transfers data={transData} refetch={refetch}/>
                      </motion.div>
                      <motion.div
                          className="trans"
                          variants={childVariants}
                      >
                         <QuickTransfer refetch={refetch}/>
                      </motion.div>
                      <motion.div
                          className="charges"
                          variants={childVariants}
                      >
   <EarningsCont img={<Charge/>} title={'Charges'} subtitleColor={"#86d694"} titleColor={'#F9B015'} dataColor={'#F9B015'} subtitle={'Total Earnings'} data={data?.admin_charge}/>
                      </motion.div>
                      
                     
                  </motion.div></>
      )}
    </>
  );
}

export default EarningsPage;
