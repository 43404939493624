import React, { useEffect, useState } from "react";

import Menu from "../components/Menu";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ClientDownload from "../components/ClientDownload";
import BusinessDownload from "../components/BusinessDownload";
const Download=()=>{
    const [show, setShow] = useState(false);
    const [menuShow, setMenuShow] = useState(false);
    let menuBtn;
  
    useEffect(() => {
      menuBtn = document.getElementsByClassName("menu-btn")[0];
    });
  
    const handleShow = (data) => {
      if (menuBtn) {
        if (data) {
          document.getElementsByTagName("html")[0].style.overflowY = "hidden";
          menuBtn.style.transform = "scale(0.6)";
          menuBtn.classList.add("open");
          setShow(!show);
          setMenuShow(true);
        } else {
          document.getElementsByTagName("html")[0].style.overflowY = "scroll";
          menuBtn.style.transform = "scale(0.5)";
          menuBtn.classList.remove("open");
          setShow(!show);
          setMenuShow(false);
        }
      }
    };
   
    return(
        <>
      {show && <Menu show={show} handleShow={handleShow} />}
      <Navbar show={show} handleShow={handleShow} />
      <div className="bg-lightgreen">
      <h3 className="bg-lightgreen  text-center text-[40px] text-[#055146] mb-5 text-source-sans-pro">Download The App</h3>

     <div  className="xl:flex pb-[60px]">
     <ClientDownload/>
      <BusinessDownload/>
     </div>

     
      </div>
      

    
      <Footer/>
    </>
    )
}

export default Download