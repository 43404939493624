import React from "react";
import Table from "../tables/Table";
import TableHeadItem from "../tables/TableHead";
import UserListRow from "../tables/UserlistRows";
import { Affix } from "antd"

//import { useGetApprovedVendorsQuery } from "../redux/user";
const UserList = ({ data, refetch, Influencer,rate })=>{
    const theadData = ["Name",'Email', "Number", "Address",  rate?"Rate per Use": 'Active' ,'Action'];
  //    const{ data,isLoading}= useGetApprovedVendorsQuery()
   // console.log(data&&data)
    return (
        <div className="table-head-container">
            <Affix offsetTop={200} className="">
        <div className="table-head">
          {theadData.map((h) => {
            return <TableHeadItem key={h} item={h} />;
          })}
        </div>
        </Affix>
        <div className="reqbody">
        {data.map((item) => {
                    
          return (<UserListRow Influencer={Influencer} refetch={refetch} key={item.id} data={item} />);
                })} 
        </div>
      </div>
      
    );
}

export default UserList