import React from "react";
// import PartnerForm from "./PartnerForm";
import PartnerMailchimpFormContainer from "./PartnerMailchimpFormContainer";

const PartnersWaitlist = () => {
  return (
    <div className="container partner-waitlist partner-text-bg">
      <div className="partner-text-wrapper">
        <h4>What is Pamp?</h4>
        <br />
        Pamp is a booking system that allows consumers to discover, book and pay
        for beauty and wellness appointments with local businesses. With our
        services, we are looking to impact a change for the local beauty and
        wellness businesses and professionals use an all-in-one platform to
        manage their entire operations with its intuitive free business software
        and digital solutions.
        <br />
        <br />
        <h4>Why choose Pamp?</h4>
        <br />
        <ul>
          <li className="list-disc mb-4">Provide exceptional service.</li>
          <li className="list-disc mb-4">
            Organize your time so you can spend it doing the things you love.
          </li>
          <li className="list-disc mb-4">
            You can count on Pamp for all your business needs, every step of the
            way.
          </li>
          <li className="list-disc mb-4">
            Establish a reputation for quality. Increase your business's profit
            and growth.
          </li>
        </ul>
        Pamp's ecosystem gives their merchants everything they need to run their
        business seamlessly by facilitating appointment bookings, point-of-sale,
        customer records management, marketing automation, loyalty and team
        management.
      </div>
      <PartnerMailchimpFormContainer />
    </div>
  );
};

export default PartnersWaitlist;
