import React, { useEffect, useState } from "react";
import Menu from "../components/Menu";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const HelpCenter = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const menuBtn = document.getElementsByClassName("menu-btn")[0];
        if (menuBtn) {
            menuBtn.addEventListener("click", () => setShow(!show));
        }
        return () => {
            if (menuBtn) {
                menuBtn.removeEventListener("click", () => setShow(!show));
            }
        };
    }, [show]);

    const handleShow = (data) => {
        const menuBtn = document.getElementsByClassName("menu-btn")[0];
        if (menuBtn) {
            if (data) {
                document.getElementsByTagName("html")[0].style.overflowY = "hidden";
                menuBtn.style.transform = "scale(0.6)";
                menuBtn.classList.add("open");
                setShow(!show);
            } else {
                document.getElementsByTagName("html")[0].style.overflowY = "scroll";
                menuBtn.style.transform = "scale(0.5)";
                menuBtn.classList.remove("open");
                setShow(!show);
            }
        }
    };

    return (
        <>
            {show && <Menu show={show} handleShow={handleShow} />}
            <Navbar show={show} handleShow={handleShow} />

            <div className="min-h-screen text-darkslategray bg-lightgreen flex justify-center p-4 sm:p-6 md:p-8">
                <div className="text-left w-full max-w-screen-md overflow-y-auto bg-white p-6 sm:p-8 md:p-10 text-darkslategray shadow-md rounded-md">
                    <h1 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6">Help Center</h1>
                    <iframe
                        src="https://docs.google.com/document/d/1_L1omHe3y6dEg_pErdYFF8RxPc_fyZXkM7aVYJO_N6s/pub?embedded=true"
                        width="100%"
                        height="600px"
              
                        style={{ border: 'none' }}
                        title="Help Center Document"
                    />
                </div>
            </div>

            <Footer />
        </>
    );
};

export default HelpCenter;
