import React from "react";
import Table from "../tables/Table";
import TableHeadItem from "../tables/TableHead";
import UserListRow from "../tables/UserlistRows";
import { Affix } from "antd"
import ScheduledRow from "../tables/ScheduledRow";

//import { useGetApprovedVendorsQuery } from "../redux/user";
const ScheduledMessage = ({ data, refetch }) => {
    const theadData = ["Title", 'Body', "Recipient", "Type", 'Send at', 'Views'];

    return (
        <div className="table-head-container">
            <Affix offsetTop={200} className="">
                <div className="table-head">
                    {theadData.map((h) => {
                        return <TableHeadItem key={h} item={h} />;
                    })}
                </div>
            </Affix>
            <div className="reqbody">
                {data.map((item) => {

                    return (<ScheduledRow refetch={refetch} key={item.id} data={item} />);
                })}
            </div>
        </div>

    );
}

export default ScheduledMessage