import React, { useState, useEffect, useRef } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Input, Radio, Select, Typography, Avatar, Drawer } from 'antd';
import { useGetallQuery } from '../redux/user';
import Checkbox from '../components/Checkbox';
import { useAddSubscriptionMutation } from '../redux/user';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useLazyGetallQuery } from "../redux/user";
import TextInput from '../components/Input';
import InfoHeader from "../components/InfoHeader";

import SelectInput from '../components/Select';
import userImage from '../assets/images/userimg.png';
import { useCreateMutation } from '../redux/user';

import { backendURL } from '../redux/http';
import axios from 'axios';
import { DateInput } from '../function';
const { Text } = Typography;

const EditCoupon = ({isOpen, setOpen, data, refetch}) => {
    const [getall, { data: vendors, isLoading }] = useLazyGetallQuery();
    const [CreateCoupon] = useCreateMutation()
    vendors && console.log(vendors)

    useEffect(() => {
        // Fetch data whenever the active tab or page changes
        getall({ scope: 'list', type: 'vendors' });
    }, []);

    const [imageSource, setImageSource] = useState(null);
    const [file, setImageFile] = useState()
    const [formInfo, setFormData] = useState(data);
    const imageInputRef = useRef(null);
    const formRef = useRef(null);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImageFile(file)

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageSource(event.target.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleSave = async () => {
        //alert('ddd')
        try {
            const loadingToastId = toast.info('Updating coupon...', {
                position: 'top-right',
                autoClose: false, // Do not auto close loading toast
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
            });
            const formData = new FormData();

            // Loop through form data fields and append them
            Object.entries(formInfo).forEach(([field, value]) => {
                // Append user fields with appropriate naming
                formData.append(`coupon[${field}]`, value);
            });
            formData.append('id', data.id);

            // Append additional fields like type and scope
            formData.append('model', 'Coupon'); // Replace with the actual type value
            formData.append('scope', 'coupon'); // Replace with the actual scope value

            const response = await axios.patch(backendURL + "/admin/update", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`

                },
            });

            refetch()
            // Hard reset form
            if (formRef.current) {
                formRef.current.reset();
            }
            toast.dismiss(loadingToastId);
            //console.log(response.data)
            toast.success('Coupon updated successfully!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            // Handle successful creation
        } catch (error) {
            console.log(error)
            alert(error.error);
        }
    };


    const handleChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));

    };


    return (
        <Drawer title='Update Coupon' width={800} placement="right" closable={true} onClose={() => setOpen(!isOpen)} open={isOpen }>


        <form ref={formRef} className='no-scrollbar' >
            <div className="flex gap-5">

                <div className="flex-1">
                    <h2 className="mt-[50px] title-med">Code</h2>

                    <TextInput
                        className={'flex-1 input'}
                        placeholder={'Insert code'}
                        onChange={(e) => handleChange('code', e.target.value)}
                        type={'email'}
                        value={formInfo.code}
                    />

                    <h2 className="mt-[50px] title-med">Discount type</h2>

                    <div className='flex gap-5 mt-[30px] mb-[70px]  ml-[30px]'>

                        <Checkbox disabled={true} label={'Percentage'} />
                        <Checkbox checked={true} label={'Amount'} />

                    </div>
                    <h2 className="mt-[50px] title-med">Discount</h2>
                    <TextInput
                        className={'flex-1 input'}
                        placeholder={'0'}
                        type={'number'}
                        value={formInfo.amount}
                        onChange={(e) => handleChange('amount', e.target.value)}

                    />
                    <h2 className="mt-[50px] title-med">Limit</h2>

                    <TextInput
                        value={formInfo.number}
                        className={'flex-1 input'}
                        placeholder={'Insert limit'}
                        onChange={(e) => handleChange('limit', e.target.value)}
                        type={'number'}
                    />

                    <h2 className="mt-[50px] title-med">Description</h2>
                    <textarea
                        style={{ height: 300, paddingTop: 40 }}
                        className={'flex-1 input '}
                        placeholder={'eg . Caring is sharing'}

value={formInfo.description}
                        onChange={(e) => handleChange('description', e.target.value)}

                    />




                </div>
                <div className="flex-1">

                    <h2 className="mt-[50px] title-med">Vendors</h2>
                    <SelectInput
                        defaultValue={formInfo.vendor_ids}
                        mode={'multiple'}
                        className={'input'}
                        onChange={(e) => handleChange('vendor_ids', e)}
                        type={'id'}
                        placeholder={'Select Vendor(s)'}
                        data={vendors}
                    />

                    <h2 className="mt-[50px] title-med">Expires</h2>
                    <TextInput
                        className={'flex-1 input h-[100px]'}
                        placeholder={'Select the expiring date'}
                        type={'date'}
                        value={DateInput(formInfo.expiry)}

                        onChange={(e) => handleChange('expiry', e.target.value)}

                    />
                    <h2 className="mt-[50px] title-med">Enable</h2>
                    <div className='ml-[30px]  mt-[30px]'>
                        <Checkbox checked={formInfo.enabled}  onChange={(e) => handleChange('enabled', e)} label={'Enable'} />
                    </div>
                    <div className="flex gap-3 justify-end mt-[100px] mb-[100px]">
                        <div style={{ width: 100 }} className="action d-flex unapproved" onClick={handleSave}>
                            Update
                        </div>
                       
                    </div>
                </div>
            </div>
        </form>
        </Drawer>
    );
};

export default EditCoupon;
