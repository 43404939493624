import React, { useState, useEffect, useRef } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Input, Radio, Select, Typography, Avatar } from 'antd';
import { useGetallQuery } from '../redux/user';
import Checkbox from '../components/Checkbox';
import { useAddSubscriptionMutation } from '../redux/user';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useLazyGetallQuery } from "../redux/user";
import TextInput from '../components/Input';
import SelectInput from '../components/Select';
import userImage from '../assets/images/userimg.png';
import { useCreateMutation } from '../redux/user';
import { backendURL } from '../redux/http';
import axios from 'axios';
const { Text } = Typography;

const CreateCategory = ({refetch}) => {
    const [createUser] = useCreateMutation()
    const [imageSource, setImageSource] = useState(null);
    const [file, setImageFile] = useState()
    const [formInfo, setFormData] = useState({

    });
    const imageInputRef = useRef(null);
    const formRef = useRef(null);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImageFile(file)

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageSource(event.target.result);
            };

            reader.readAsDataURL(file);
        }
    };


    const handleSave = async () => {
        const loadingToastId = toast.info('Creating category...', {
            position: 'top-right',
            autoClose: false, // Do not auto close loading toast
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
        });
        try {

            const formData = new FormData();

            // Loop through form data fields and append them
            Object.entries(formInfo).forEach(([field, value]) => {
                // Append user fields with appropriate naming
                formData.append(field, value);
            });
            // Append the image file
            file && formData.append('image', file);

            // Append additional fields like type and scope
            formData.append('type', 'category'); // Replace with the actual type value
            formData.append('scope', 'create'); // Replace with the actual scope value

            // Call the createUser mutation with FormData
            //   const { data } = await createUser(formData);
            const response = await axios.post(backendURL + "/admin/create", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`

                },
            });
            if (imageInputRef.current) {
                imageInputRef.current.value = '';
            }

            // Hard reset form
            if (formRef.current) {
                formRef.current.reset();
            }
            refetch()
            toast.dismiss(loadingToastId);
            console.log(response.data)
            toast.success('Category created successfully!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            // Handle successful creation
        } catch (error) {
            toast.dismiss(loadingToastId);
            toast.error('Something went wrong!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };


    const handleChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));

    };


    return (
        <form ref={formRef}>
            <div className="flex gap-5">

                <div className="flex-1">
            


                    <h2 className="mt-[50px] title-med">Name</h2>

                    <TextInput
                        className={'flex-1 input'}
                        placeholder={'category name'}
                        onChange={(e) => handleChange('name', e.target.value)}
                        type={'email'}
                    />
                
                </div>
                <div className="flex-1">
                    <div className='flex flex-col items-center'>
                        <h2 className="mt-[50px] text-center title-med">Image</h2>

                        <label htmlFor="avatarInput" className="cursor-pointer">
                            <Avatar style={{
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                borderRadius: '50%',
                            }} size={100}
                                icon={<UserOutlined />}
                                src={imageSource || userImage} />
                            <input
                                type="file"
                                id="avatarInput"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                            />
                        </label>
                    </div>
                   
                    <div className="flex gap-3 justify-end mt-[100px] mb-[100px]">
                        <div style={{ width: 100 }} className="action d-flex unapproved" onClick={handleSave}>
                            Create
                        </div>
                        <div style={{ width: 100, background: '#DAECD2', color: '#00463' }} className="action w-[50px] d-flex">
                            Cancel
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default CreateCategory;
