import React, { useState } from 'react';
import { Drawer, Button, Input, Typography } from 'antd';
import axios from 'axios';
import { backendURL } from '../redux/http';
import { toast } from 'react-toastify';
const { Title, Text } = Typography;

const InfoItem = ({ title, content }) => (
  <div className="flex mb-3 pb-3 border-b">
    <Text strong className="flex-1 mr-2 text-lg text-lightgreen">{title}:</Text>
    <Text className="text-lg flex-1 text-darkgreen">{content}</Text>
  </div>
);

const EditableInfoItem = ({ title, content, onChange }) => (
  <div className="flex mb-3">
    <Text strong className="mr-2 flex-1 text-lightgreen">{title}:</Text>
    <Input
      className="flex-1"
      value={content}
      onChange={(e) => onChange(title, e.target.value)}
    />
  </div>
);

const renderInfoItems = (data, editMode, editableData, handleChange, keysToDisplay) => {
  return keysToDisplay.map((key) => {
    const value = data[key];

    if (typeof value === 'object' && value !== null) {
      return (
        <React.Fragment key={key}>
          <Title level={4} className="text-darkgreen mt-3 mb-2">{key}</Title>
          {renderInfoItems(value, editMode, editableData[key] || {}, (field, val) =>
            handleChange(key + '.' + field, val)
          )}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment key={key}>
          {editMode ? (
            <EditableInfoItem title={key} content={editableData[key]} onChange={handleChange} />
          ) : (
            <InfoItem title={key} content={value} />
          )}
        </React.Fragment>
      );
    }
  });
};


const InfoDrawer = ({ open, setOpen, userData,scope,keys=[] }) => {
  const [editableData, setEditableData] = useState({});
  const [editMode, setEditMode] = useState(false);

  const handleEdit = () => {
    setEditableData({ ...userData });
    setEditMode(true);
  };

  const handleSave = async () => {
    try {
      // Show loading toast
      const loadingToastId = toast.info('Saving data...', {
        position: 'top-right',
        autoClose: false, // Do not auto close loading toast
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
      });
  
      const response = await axios.put(`${backendURL}/admin/update/${userData.id}`, {
        keys,
        scope,
        id: userData.id,
        data: editableData,
      });
  
      console.log('Saved Data:', response.data);
  
      setEditMode(false);
  
      // Close loading toast
      toast.dismiss(loadingToastId);
  
      // Display success toast
      toast.success('Data saved successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error('API Request Error:', error);
  
      // Close loading toast
  
  
      // Display error toast
      toast.error('Error saving data. Please try again!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  

  const handleCancel = () => {
    setEditableData({});
    setEditMode(false);
  };

  const handleClose = () => {
    setEditableData({});
    setEditMode(false);
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setEditableData((prevData) => ({ ...prevData, [field]: value }));
  };

  return (
    <Drawer width={640} placement="right" closable={false} onClose={handleClose} visible={open}>
      <div className="p-4 flex flex-col h-full">
        <Title level={3} className="mb-4 text-darkgreen">{scope} Information</Title>
        <div className="flex-1">
          {renderInfoItems(userData, editMode, editableData, handleChange,keys)}
        </div>
        <div className="mt-4 flex justify-end">
          {editMode && (
            <>
              <Button type="primary" className="mr-2 bg-darkgreen" onClick={handleSave} size="large">
                Save
              </Button>
              <Button onClick={handleCancel} className="bg-indianred" size="large">
                Cancel
              </Button>
            </>
          )}
          {!editMode && (
            <Button onClick={handleEdit} className="bg-darkgreen text-white" size="large">
              Edit
            </Button>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default InfoDrawer;
