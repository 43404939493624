import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ClientForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    onValidated({
      EMAIL: email,
      MERGE1: firstName,
      MERGE2: lastName,
    });
  };

  useEffect(() => {
    if (status === "success") {
      navigate("/congrats");
    }
  }, [status]);
  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      className="client-form"
    >
      <h3 className="form-header">
        Enter your details
      </h3>

      {status === "error" && (
        <div
          className="mc__alert mc__alert--error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <input
        type="text"
        onChange={(e) => setFirstName(e.target.value)}
        className="input"
        placeholder="First Name"
        required
      />
      <input
        type="text"
        onChange={(e) => setLastName(e.target.value)}
        className="input"
        placeholder="Last Name"
        required
      />
      <input
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        className="input"
        placeholder="Email"
        required
      />
      <div className="client-bottom-wrapper">
        <button
          type="submit"
          className="client-submit-button"
        >
          Notify Me
        </button>
      </div>
    </form>
  );
};

export default ClientForm;
