const termsOfservice=[
    {
        title: "Terms of Service",
        content: [
          "We are Pampdigital, a company registered in Ghana with registered address Lily Close, Greater Accra, ('Pampdigital'', 'we', 'us' and 'our'). Our registration number is BN613990822",
          "These are Terms of Service and Conditions ('Terms of Service') between you and Pampdigital for the use of the Pampdigital Platform (as defined below).",
          "These Terms of Service apply to each booking you make through the Pampdigital Platform (as defined below), including our app booking services.",
          "Please read these Terms of Service carefully before you use the Pampdigital Appointment Services. We recommend that you save or print a copy of these Terms of Service for future reference.",
          "We reserve the right to change these Terms of Service from time to time by changing them on this page. Any changes will apply to your next Appointment, so please check this page regularly. If you do not agree to any changes, you should not make an Appointment.",
          "Your use of our site and app is also subject to our Terms of Use. We describe how we use your personal information in our Privacy Policy.",
        ],
      },
      {
        title: "Key terms",
        content: [
          "When we use capitalised words or phrases in these Terms of Service, they have the following meanings:",
          {
            type: "bullets",
            content: [
              '"Appointment" means any appointment booking for one or more services from a Partner made through the Pampdigital Platform, including as updated, re-scheduled or cancelled;',
              '"Cancellation Policy" means a policy that is included in the Partner Terms of Sale of an Appointment whereby a Partner may charge you (through the Pampdigital Payment Services) a certain fee for a cancelled Appointment, which may be up to 20% of the value of the Appointment;',
              '"Client" and "you" means you, the user of the Pampdigital Services or the purchaser of any Partner Services;',
              '"Confirmation" means a written confirmation that we provide to you regarding an Appointment, via email or the Pampdigital Platform;',
              '"Deposit Policy" means a policy that is included in the Partner Terms of Sale whereby a Partner may request that you (through the Pampdigital Payment Services) deposit 20% of your chosen service at the time you make such Appointment;',
              '"Pampdigital Account" means an account you create on the Pampdigital Platform, containing information such as your name, Appointment and order history, and contact information;',
              '"Pampdigital Appointment Services" means all appointment booking services, including creating, updating, rescheduling and cancelling appointments, provided through the Pampdigital Platform;',
              '"Pampdigital Payment Services" means the in-app payment facility available for select Partners on the Pampdigital Platform;',
              '"Pampdigital Platform" means, together: (a) the website Pampdigital, accessible through any web browser (our "Site"), (b) our mobile application published on the Apple Store and Google Play Store and downloaded and installed by you on your device (our "App");',
              '"Pampdigital Services" means all information, recommendations and/or services provided by Pampdigital to you by means of your use of the Pampdigital Platform, including the Pampdigital Platform, Pampdigital Appointment Services and Pampdigital Payment Services, and Partner Profiles;',
              '"No Show Policy" means a policy that is included in the Partner Terms of Sale of an Appointment whereby a Partner may charge you (through the Pampdigital Payment Services) a certain fee for failing to show up to a Partner\'s place of business at the time indicated on the Appointment, which may be up to 20% of the value of the Appointment;',
              '"Partner Contract" means any contract between you and the relevant Partner for their Partner Services which you book through the Pampdigital Platform, including any Partner Terms of Sale (as provided to you prior to confirming your Appointment);',
              '“Partner Credit” means a credit which is purchased via the Pampdigital Services by the Client for the purpose of providing a pre-payment to a Partner in respect of a Partner Service.',
              '"Partner Profile" means a Partner\'s digital profile on the Pampdigital Platform, which may include information about their Partner Services and, if applicable, their Partner store;',
              '"Partner Services" means any services of a Partner offered for booking on the Pampdigital Platform, including fulfilling any Appointment or Voucher;',
              '"Partner Terms of Sale" mean the date, time, price, Partner Services, discount, location, and any other information or terms and conditions (including any Deposit Policy, Cancellation Policy, No Show Policy, or a return and refunds policy) provided through the Pampdigital Platform at the time that an Appointment is made by the Client.',
              '"Partner" or ‘Vendor’ means a selected third-party provider of services, who offers their services for sale on the Pampdigital Platform;',
              '"pay with cash Service" means where an Appointment is made on the Pampdigital Platform but cash payment is made at the Partner\'s premises (and not made online using the Pampdigital Payment Services);',
              '"Payment Processor" means third-party payment processors supporting Pampdigital with the provision of the Pampdigital Payment Services;',
              '"Promo Codes" means a promotional code issued by a Partner via the Pampdigital Platform which can be used when purchasing Partner Services, always subject to the relevant promotion terms.',
            ],
          },
        ],
      },
      {
        title: "Our Services",
        content: [
          "Pampdigital offers a way to connect you to our Partners, who offer a range of services in the beauty and wellness space, such as hair, beauty, training and therapy services. The Pampdigital Platform allows you to make an Appointment for a Partner Service. Pampdigital does not operate any Partner, provide any Partner Services.",
          "By using the Pampdigital Services you enter into a contract with Pampdigital under these Terms of Service. However, your contract for the Partner Services is only between you and the Partner.",
          "Pampdigital acts only as an intermediary between you and the Partners, including acting as the agent for each Partner to arrange and conclude your Appointment.",
          "When you pay for an Appointment on the Pampdigital Platform, we receive payment as a commercial agent on behalf of the relevant Partner and this discharges your debt to the Partner for that Appointment."
        ],
      },
      {
        title: "Our Partner's Responsibilities",
        content: [
          "If you use the Pampdigital Platform to book any Partner Services, you also enter into a Partner Contract with the relevant Partner, including any Partner Terms of Sale.",
          "Partners' provision of Partner Services and fulfilling any Appointment is subject to the Partner Contract. Pampdigital is not a party to the Partner Contract you have with the Partner."
        ],
      },
      {
        title: "Our Partners' Services",
        content: [
          "Our Partners are required to provide Partner Services as set out in the relevant Partner Contract.",
          "We require our Partners to ensure that all information provided by them for the Pampdigital Platform is accurate, complete and not misleading in any way. Nonetheless, we cannot verify the information which Partners provide to us. It is each Partner's responsibility to ensure that all of its Partner Services listed on the Pampdigital Platform are available and accurately described. The descriptions and/or images of the Partner Services on the Pampdigital Platform are for illustrative purposes only, and actual Partner Services may vary from those descriptions and/or images."
        ],
      },
      {
        title: "Your Rights",
        content: [
          "If you are a consumer, you have legal rights in relation to services that are not performed with reasonable care and skill or are otherwise not as described. Advice about your legal rights is available from your local Citizens' Advice Bureau or trading standards office. Nothing in these Terms of Service will affect these legal rights."
        ],
      },
      {
        title: "Using the Pampdigital Platform",
        content: [
          "In order to make any Appointments through the Pampdigital Platform you may create a Pampdigital Account by signing-up with Pampdigital and providing certain information, including your name, mobile telephone number and payment information, and any password you set.",
          "You must provide accurate and complete information to Pampdigital, and keep this information up to date through the Pampdigital Platform. Pampdigital may verify the information that you provide, and may refuse use of the Pampdigital Services or Pampdigital Platform without providing reasons."
        ],
      },
      {
        title: "Using Pampdigital Responsibly",
        content: [
          "To get the most of your use of Pampdigital, you can",
          {
            type: "bullets",
            content: [
              "Update Partners through notes – after booking a partner, you can share a quick note to a partner regarding any updates. If you are having any issues, you can easily get in touch with Pampdigital support team at support@trypamp.com.",
              "Check out safely – our Pampdigital Payment Services are set up to protect your mobile payment experience.",
              "Research independently – check out reviews on Pampdigital app to see what other people thought of a Partner's services."
            ],
          },
        ],
      },
      {
        title: "Providing any Health or Other Relevant Information",
        content: [
          "It is your (or the person receiving the Partner Services) sole responsibility to communicate in advance any medical or health-related conditions and/or special needs to the Partner that might affect or be affected by any Partner Services (for example without limitation, allergy information and health matters).",
          "If you (or the relevant recipient of the Partner Services) fail to disclose any such information to the applicable Partner, neither Pampdigital nor the relevant Partner shall be liable to you (or the recipient of the Partner Services) for any injury, loss, or damages resulting from the Partner Services that could reasonably have been avoided if you (or the recipient of the Partner Services) had disclosed (or checked or requested) that information prior to receiving the Partner Services."
        ],
      },
      {
        title: "Service Communications",
        content: [
          "In connection with your use of the Pampdigital Services, Pampdigital may send you electronic service messages. These electronic messages may be sent for various reasons, including for security purposes (e.g., verifying that your smartphone is connected to the phone number you entered upon registration) and issues related to an Appointment you placed (e.g., if the Partner updates an Appointment, you may be notified by text message or email).",
          "By using the Pampdigital Services, you agree to receive automated text messages or emails from Pampdigital at the phone number you provided when registering for the service. You further agree that these messages may include promotional content as well as information about your account and Appointment. Your agreement to receive such automated messages is not a condition of using the service. You may choose to not receive some or all automated messages within your personal profile settings.",
          "For information on how we use your personal information, and about promotional and marketing messages, please refer to our Privacy Policy."
        ],
      },
      {
        title: "How to Make an Appointment",
        content: [
          "The Pampdigital Platform allows you to select which Partner Services you would like to receive and make an Appointment for that Partner Service. You may check and correct any errors before confirming your Appointment. We will disclose any Partner Terms of Sale (including any Deposit Policy, Cancellation Policy, or No Show Policy) to you prior to your confirmation. Please read and check all details of your Appointment and any applicable Partner Terms of Sale before confirming your Appointment.",
          "When you make an Appointment, we (as the commercial agent of the Partner) will send your request to the Partner, and the Partner Contract will be formed when we send you a Confirmation. All Partner Services shown on the Pampdigital Platform are subject to availability. We may accept or reject each Appointment on behalf of the Partner. Your Confirmation is your receipt from Pampdigital and will be sent via email or SMS to the contact information provided in your Pampdigital Account, upon confirming an Appointment. If you require a tax receipt, you need to contact the Partner directly.",
          "When you make an appointment, you will need to arrive at the place of business of the Partner to receive the Partner Services. The place of business will be indicated in the Partner Terms of Sale."
        ],
      },
      {
        title: "Promo Codes Issued by Pampdigital",
        content: [
          "Pampdigital may issue Promo Codes, which can be used when booking Partner Services. The Promo Codes are issued at the Partner's discretion and may be withdrawn at any time, including if they are used fraudulently, cause technical issues, or are subject to tampering.",
          "If Promo Codes are made exclusively available and identified as being personal to you, you must not share it with any third party. If Promo Codes are made publicly available, you may share them with any third party. If, in the reasonable opinion of the Partner and/or Pampdigital, it considers that the Promo Code has been shared or misused by you, the Partner and/or Pampdigital can cancel such Promo Codes.",
          "The Partner is responsible for setting the terms of any Promo Codes and fulfilling the Partner Services. Pampdigital is not responsible for any Promo Codes issued by our Partners."
        ],
      },
      {
        title: "Paying for a Partner Service",
        content: [
          "Prices and processing charges for any Appointment will be as quoted on the Pampdigital Platform.",
          "The final price you pay for an Appointment may be adjusted to reflect any service you purchase from the Partner at the time of receiving the Partner Services.",
          "Pampdigital Partners may also offer discounts that would be reflected on the final amount payable."
        ],
      },
      {
        title: "Making Payments for Partner Services",
        content: [
          "You agree that you will provide Pampdigital with a valid method of payment, and that you will pay for all Partner Services you book from the Partner. You may pay for Appointments via the Pampdigital Payment Services (Paystack) or directly with the Partner as a pay with cash Service.",
          "If you choose to use the Pampdigital Payment Services, Pampdigital will charge you on behalf of the Partner (as its commercial agent) for the total amount of your Appointment (including any applicable taxes and additional fees). To complete the Pampdigital Payment Services, we use the payment method you provide to us and in the currency stated in the Confirmation. Currency conversion costs or other charges incurred by you will be borne by you in addition to the payment made to us. When Pampdigital receives your payment for the Partner Services, this will discharge your debt to the relevant Partner for the relevant Appointment.",
          "You may have the option to select certain Appointments as a pay with cash service. This means that you may make an Appointment without using Pampdigital Payment Services and then pay the Partner directly for Partner Services with cash. This option will only appear for Partners which enable pay with cash Services."
        ],
      },
      {
        title: "Payment Processor",
        content: [
          "Pampdigital uses a third-party payment processor (the 'Payment Processor - Paystack') to link the payment card you provide to the Pampdigital Platform. The processing of payments, as applicable, in connection with your use of the Pampdigital Platform will be subject to the privacy policies, terms and conditions, and other terms of our Payment Processor and your credit card issuer (in addition to these Terms of Service). Pampdigital takes reasonable care to ensure that the Pampdigital Payment Services are available and functioning at all times, but cannot guarantee continuous, uninterrupted or secure access to the Pampdigital Payment Services, nor can we guarantee that the facility is virus or error-free.",
          "Pampdigital is not responsible for any errors by the Payment Processor. Access to the Payment Processing may be occasionally restricted to allow for repairs, maintenance, or the introduction of new facilities or services. We will attempt to provide reasonable notice of any scheduled interruptions and will do what we can to restore the facility as soon as reasonably possible."
        ],
      },
      {
        title: "Tax",
        content: [
          "The Partner is solely responsible for any indirect taxes (for example sales tax, VAT, GST, or service tax) on the total value of an Appointment, plus any adjustments for additional goods or services sold or discounts applied, where applicable."
        ],
      },
      {
        title: "Appointment Changes and Cancellations",
        content: [
          "Rescheduling an Appointment",
          "If you wish to reschedule your Appointment (e.g. time/date) in whole or in part after you have received a Confirmation, you may do so through the Pampdigital Platform.",
          "You may only reschedule your Appointment if your Appointment is not due to take place within a time frame (e.g. 24 or 48 hours) determined by the Partner and set out in the Partner Terms of Sale. The interval between when you may reschedule your Appointment and your scheduled Appointment start time will be determined independently by each Partner and disclosed to you at the time of making your Appointment. You should review and understand the Cancellation Policy before confirming your appointment on the Pampdigital Platform. Please note that your ability to reschedule your Appointment will be subject to the Partner's availability during the time you wish to reschedule."
        ],
      },
      {
        title: "Deposits, Cancellation, and No-Show Policies",
        content: [
          "In addition to your other legal rights, you may in certain circumstances have the right to cancel a Partner Contract as set out in this section of the Terms of Service.",
          "Deposits",
          "If you are paying in cash or paying in person, you must add your card details onto the Pampdigital platform. A 20% fee from the service cost would be used to hold your appointment in place with the selected partner. You can also pay 100% of the service via MoMo (Mobile Money) or Credit/Debit Card.",
          "Cancellations & No Shows",
          "If you wish to cancel your Appointment, you may do so through the Pampdigital Platform, provided that your Appointment is not due to take place within a time frame (e.g. 72 hours).",
          "If you cancel an Appointment beyond the time indicated in Pampdigital's Cancellation Policy, you will not be entitled to the 20% deposit. However, if the Partner cancels the appointment, you are entitled to a full refund.",
          "If you fail to turn up to a partner's place of business at the time indicated in your appointment, Pampdigital may choose to exercise their no-show policy, wherein Pampdigital would automatically send your 20% charge to the partner you have selected for your appointment, and in the case of a 100% payment, 80% would be refunded to you and 20% to the partner.",
          "No refund will be given where a cancellation is attempted within the time period specified in the Cancellation Policy."
        ],
      },
      {
        title: "Restrictions on Access",
        content: [
          "Pampdigital reserves the right to partially or fully restrict your access to the Pampdigital Services or Pampdigital Platform where any of the following occur:",
          "- you have a history of many cancelled Appointments, no shows, refunds, chargebacks, or other transactions that are negative to the experience of our Partners;",
          "- you fail any credit or fraud prevention check provided by the Pampdigital Payment Services;",
          "- we reasonably suspect fraud or money laundering by you or someone using your Pampdigital Account;",
          "- we reasonably suspect the security of your Pampdigital Account has been compromised;",
          "- you behave inappropriately, abusively, or offensively towards staff of Pampdigital or employees of a Partner, whether on the phone, through email, through communications within the Pampdigital Platform, or in person at the place of business of a Partner or breach our Terms of Use;",
          "- you breach other terms set forth in these Terms of Service, including your promises to us; or",
          "- we believe in our reasonable and sole discretion, that your actions are negatively affecting the quality of Pampdigital Services.",
          "Further (as Partner Services are sold by our Partners and not by Pampdigital), our Partners may impose additional restrictions on who may book the Partner Services that they offer. In particular, a Partner may:",
          "- restrict your access to their Partner Profile;",
          "- prevent you from making an Appointment with Partner Services that they offer; and",
          "- otherwise restrict your ability to interact with that Partner on the Pampdigital Platform.",
          "These restrictions are determined solely by the Partners, and we are unable to provide you with access to these Partner Services."
        ],
      },
      {
        title: "Liability",
        content: [
          "Where we have been negligent and/or breached a contractual obligation to you, we will be liable for any loss or damage you suffer as a result, provided that loss and/or damage is foreseeable. Loss or damage is foreseeable if it is an obvious consequence of our negligence or breach of contract, or would have been considered by you and us to be a likely consequence of it at the time you accepted these Terms of Service.",
          "We do not accept any liability for the following types of loss, whether caused by breach of contract, tort (including negligence) or otherwise, even if the loss is foreseeable: loss of income or revenue; loss of business; loss of profits; loss of anticipated savings; or waste of management or office time.",
          "We do not exclude or limit our liability for death or personal injury arising from our negligence, for fraud or fraudulent misrepresentation, or for any other liability which cannot be excluded or limited under Ghanaian law.",
          "We are not liable to you for the provision or non-provision of the Partner Services. The Partner is responsible for the fulfillment of all Partner Services."
        ],
      },
      {
        title: "Indemnity",
        content: [
          "You agree to indemnify and release Pampdigital (and its subsidiaries, agents, licensors, managers, and other affiliated companies, and their staff members, contractors, agents, officers and directors), from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees and costs) arising out of or related to:",
          "- your use (or misuse) of the Pampdigital Services or Pampdigital Platform, including any data or content transmitted or received by you;",
          "- personal information or any other information or content that is submitted via your customer account, including without limitation misleading, false or inaccurate information;",
          "- your breach or violation of any term of these Terms of Service or any applicable law, rule or regulation;",
          "- your violation of any rights of any third party, including Partners;",
          "- negligent or willful misconduct; or",
          "- any other party's access and use of the Pampdigital Services with your unique username, password or other appropriate security code."
        ],
      },
      {
        title: "Ending these Terms of Service",
        content: [
          "Pampdigital shall, as soon as reasonably practicable following, and in any event within thirty (30) days of, termination of the agreement, delete (including by putting beyond practicable use) or return to the Client (at the Client's direction) all personal data.",
          "Notwithstanding the foregoing, Pampdigital may retain personal data beyond the termination or expiry solely if, and for so long as, such personal data must be retained in order to comply with applicable law."
        ],
      },
      {
        title: "General",
        content: [
          "Platform fees",
          "Clients' use of the Pampdigital Platform is free of charge. Pampdigital reserves the right to introduce a fee for the use of the Pampdigital Platform. If Pampdigital decides to introduce such a fee, Pampdigital will tell you in advance and allow you to continue or stop using the Pampdigital services.",
          "Assignment",
          "You may not assign your rights under these Terms of Service without prior written approval of Pampdigital.",
          "Notices",
          "Pampdigital may give notice by means of a general notice on the Pampdigital Platform, or by email. Pampdigital will only accept notice by email at support@trypamp.com",
          "Entire agreement",
          "The Terms of Service (including any referenced documents) form the entire agreement between you and Pampdigital and governs your use of the Pampdigital Services, superseding any prior version of these Terms of Service between you and Pampdigital. Your Partner Contract governs the provision of the Partner Services.",
          "Enforcement & severability",
          "Pampdigital's failure to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision.",
          "If any provision of these Terms of Service is found by a competent authority to be invalid, unlawful or unenforceable to any extent, such provision will to that extent be severed from the other provisions of the Terms of Service remain in full force and effect."
        ],
      },
      {
        title: "Governing Law",
        content: [
          "These Terms of Service will be governed by and construed in accordance with the laws of Ghana. You and we each agree that the Ghanaian courts will have non-exclusive jurisdiction over any claim or dispute arising from, or related to, the Partner Services via the Pampdigital Platform."
        ],
      },
      {
        title: "Interpretation",
        content: [
          "Where applicable, words in these Terms of Service and Conditions that are defined in the singular shall retain the same definition in the plural, and vice versa. Words in the masculine include the feminine, and vice versa."
        ],
      },
      {
        title: "Updating the service and these Terms of Service",
        content: [
          "These Terms of Service were last updated on 1st November 2023.",
          "We may update these Terms of Service from time to time, and will post any changes on this document. Pampdigital may modify or replace any of these Terms of Service, or change, suspend, or discontinue the Pampdigital Services (including without limitation, the availability of any feature, database, or content) at any time.",
          "We will notify you by posting a notice on the Pampdigital Platform, or by sending you notice through the Pampdigital Platform, your Pampdigital Account or via email. If we make any substantive changes, we will notify you through email, or app pop-ups within our Pampdigital Platform.",
          "Your continued use of the Pampdigital Platform following the posting of changes will mean that you accept and agree to the changes. As long as you comply with these Terms of Service, Pampdigital grants you a personal, non-exclusive, non-transferable, limited privilege to enter and use the Pampdigital Platform.",
          "Pampdigital may also impose limits on certain features and services or restrict your access to parts or all of the Pampdigital Services without notice or liability. Such modification will not apply to any Bookings already placed."
        ],
      },
      {
        title: "Need help with anything?",
        content: [
          "We care about your experience and want to ensure we maintain the highest standards possible.",
          "If you have any feedback, any questions concerning the Pampdigital Platform, or would like to make a complaint about one of our Partners or their Partner Services, please:",
          "- speak to the Partner yourself to try and resolve the issue;",
          "- leave an honest review and/or rating for the Partner on the Pampdigital Platform to reflect your experience; or",
          "- email us at support@trypamp.com",
          "If you reach out to us at support@trypamp.com, we may contact the Partner to attempt to resolve the issue. If we are unable to resolve the issue following contact with the Partner, and if we consider it to be fair in all the circumstances, then we may, at our sole discretion and always as a last resort, elect to give you a refund for the disputed / complained about amount. These refunds will be processed through the Pampdigital Payment Services.",
          "While we take all complaints about our Partners very seriously and will always do what we can to help to resolve them, we are not responsible to you for the Partner Services and are under no obligation to provide you with a refund or any other recompense if you are dissatisfied with any Partner Services."
        ],
      }
      
      
      
      
      
      
]


export default termsOfservice