import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { ReactComponent as Fee } from "../assets/svgs/bookingfee.svg";
import { useLazyReportQuery } from "../redux/user";
import StatisticInfoContainer from "../components/StatCont";
import UserVendorStats from "../components/UserVendorStats";
import BarChart from "../components/BarChart";
import LineChart from "../components/LineChart";
import InfoHeader from "../components/InfoHeader";

function Reports() {
    const containerAnimation = useAnimation();
    const [period, setPeriod] = useState('this_year');
    const [getReport, { isLoading, data }] = useLazyReportQuery();
    const [getBooking, { data: bookings }] = useLazyReportQuery();
    const [getUser, { data: users }] = useLazyReportQuery();
    const [getVendor, { data: vendors }] = useLazyReportQuery();

    useEffect(() => {
        getReport({ report_type: 'all' });
        getBooking({ report_type: 'chart', period: period, scope: 'Booking' });
        getUser({ report_type: 'chart', period: period, scope: 'User' });
        getVendor({ report_type: 'chart', period: period, scope: 'Vendor' });

        if (!isLoading) {
            containerAnimation.start("visible");
        }
    }, [containerAnimation, isLoading]);

    const containerVariants = {
        hidden: {
            opacity: 0,
            y: 50,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.8,
                when: "beforeChildren",
                staggerChildren: 0.2,
            },
        },
    };

    const childVariants = {
        hidden: { opacity: 0, x: -20 },
        visible: { opacity: 1, x: 0 },
    };

    return (
        <>
            {isLoading ? (
                <div>Loading</div>
            ) : (
                <>
                    <InfoHeader
                        title={'Reports'}
                    />
                    <motion.div
                        className="grid grid-cols-1 md:grid-cols-2 gap-8"
                        variants={containerVariants}
                        initial="hidden"
                        animate={containerAnimation}
                    >
                        <motion.div
                                className="graph-container bg-white rounded-[20px]"
                            variants={childVariants}
                        >

                                {users && users.labels && (
                                    <LineChart
                                        data={users}
                                        onChange={(e) => getUser({ report_type: 'chart', period: e.target.value, scope: 'User' })}
                                        name={'Users'}
                                    />
                                )}
                        </motion.div>
                        <motion.div
                                className="graph-container bg-white rounded-[20px]"
                            variants={childVariants}
                        >
                                {vendors && vendors.labels && (
                                    <LineChart
                                        onChange={(e) => getVendor({ report_type: 'chart', period: e.target.value, scope: 'Vendor' })}
                                        data={vendors}
                                        name={'Vendors'}
                                    />
                                )}
                        </motion.div>
                        <motion.div
                            className="graph-container bg-white rounded-[20px]"
                            variants={childVariants}
                        >
            
                                {bookings && bookings.labels && (
                                    <BarChart
                                        onChange={(e) => getBooking({ report_type: 'chart', period: e.target.value, scope: 'Booking' })}
                                        data={bookings}
                                        name={'Bookings'}
                                    />
                                )}
                        </motion.div>
                    </motion.div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <motion.div
                            className="stat-container mt-5"
                            variants={childVariants}
                        >
                            <StatisticInfoContainer
                                name="Walk-In Clients"
                                total={data?.walkinClient.count}
                                newValue={data?.walkinClient.newThisMonth}
                                improvement={data?.walkinClient.improvement}
                            />
                            <StatisticInfoContainer
                                name="Influencers"
                                total={data?.influencers.count}
                                newValue={data?.influencers.newThisMonth}
                                improvement={data?.influencers.improvement}
                            />
                        </motion.div>
                        <motion.div
                            className="stat-container mt-5"
                            variants={childVariants}
                        >
                            <StatisticInfoContainer
                                name="Inapp-Impressions"
                                total={data?.views.count}
                                newValue={data?.views.newThisMonth}
                                improvement={data?.views.improvement}
                            />
                            <StatisticInfoContainer
                                icon={<Fee />}
                                name="Employees"
                                total={data?.members.count}
                                newValue={data?.members.newThisMonth}
                                improvement={data?.members.improvement}
                            />
                        </motion.div>
                    </div>
                    <motion.div
                        className="user-vendor-container"
                        variants={childVariants}
                    >
                        <UserVendorStats />
                    </motion.div>
                </>
            )}
        </>
    );
}

export default Reports;
