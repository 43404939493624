import React from "react";
import money from '../assets/images/money-bag.png'


const TotalEarnings=({data})=>{
    return(
        <><div style={{ display: 'flex', marginLeft: 20 }}>
            <img src={money} style={{ width: 100, height: 117, marginRight: 10 }}></img>

        </div>
        <div className="e-money">₵{parseFloat(data).toFixed(2)}</div>
        <div style={{ textAlign: 'center', color: '#ffff',  marginTop: 20,marginBottom:30 }}>Total Earnings</div></>
    )
}

export default TotalEarnings