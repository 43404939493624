import React, { useState, useRef } from 'react';
import { Drawer, Button, Input, Typography, Avatar } from 'antd';
import axios from 'axios';
import TextInput from '../components/Input';
import { UserOutlined } from '@ant-design/icons';

import { backendURL } from '../redux/http';
import { toast } from 'react-toastify';
import Notification from './Notification';

import userImage from '../assets/images/userimg.png';
const { Title, Text } = Typography;




const UserDrawer = ({ open, setOpen, user }) => {
  const [formInfo, setFormData] = useState({});
  const [imageSource, setImageSource] = useState(null);
  const [file, setImageFile] = useState()
  const bgSlate = { backgroundColor: '#f7f7f9' }
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));

  };



  const handleClose = () => {

    setOpen(false);
  };





  return (
    <Drawer width={450} placement="right" closable={false} onClose={handleClose} visible={open}>
      <form
        ref={formRef}
      >


        <div className='flex mt-5 flex-col bg-blue items-center'>

          <Avatar style={{
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '50%',
          }} size={100}
            icon={<UserOutlined />}

            src={imageSource || localStorage.getItem('image') || userImage} />


          <h2 className="mt-[50px]  ml-[50px] self-start title-med">Profile</h2>
          <div className="self-start profile section ml-8 p-6 bg-white shadow-md rounded-[20px]">
            <p className="text-lg text-darkgreen font-semibold">Name: <span className="font-normal">{user.first_name}</span></p>
            <p className="text-lg  text-darkgreen font-semibold">Last Name: <span className="font-normal">{user.last_name}</span></p>
            <p className="text-lg  text-darkgreen font-semibold">Account Number: <span className="font-normal">{user.account_number}</span></p>
            <p className="text-lg  text-darkgreen font-semibold">Salary: <span className="font-normal">{user.salary}</span></p>
            <p className="text-lg  text-darkgreen font-semibold">Gender: <span className="font-normal">{user.gender}</span></p>
            <p className="text-lg  text-darkgreen font-semibold">Address: <span className="font-normal">{user.address}</span></p>
          </div>

        </div>

      </form>
    </Drawer>
  );
};

export default UserDrawer;
