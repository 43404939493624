import React from "react";
import { ReactComponent as ChevronRight } from "../assets/svgs/chevron_right.svg";
import { ReactComponent as FlipFlops } from "../assets/svgs/flip_flops.svg";
import { ReactComponent as Umbrella } from "../assets/svgs/umbrella.svg";

const Waitlist = () => {
  return (
    <section className="appTertiary wait-bg">
      <div className="waitlist-wrapper container" >
        <div className="flex items-center justify-center mx-auto w-screen">
          <FlipFlops className="hide-mobile" />
          <a
            className="join-waitlist-button"
            href="/download"
          >
          Download The App<ChevronRight className="ml-10"/> 
          </a>
          <Umbrella className="hide-mobile" />
        </div>
      </div>
    </section>
  );
};

export default Waitlist;
