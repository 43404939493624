import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as Tree } from "../assets/svgs/nav_tree.svg";

const Menu = ({ show }) => {
  console.log(show);
  const location = useLocation();
  useEffect(() => {
    if (show === null) {
      var menu = document.getElementById("menu");
      console.log(menu);
    }
  });
  return (
    <div id="menu" className="menu-main-wrapper ">
      <div className="menu-wrapper">
        <ul className="menu-list">
          <li
            className={`active-menu-link ${
              location.pathname === "/" ? "text-secondaryText" : "text-navText"
            } `}
          >
            <a href="/">Home</a>
          </li>
          <li
            className={`inactive-menu-link ${
              location.pathname === "/pricing"
                ? "text-secondaryText"
                : "text-navText"
            } `}
          >
            <a href="#">Pricing</a>
          </li>
          <li
            className={`inactive-menu-link ${
              location.pathname === "/about-us"
                ? "text-secondaryText"
                : "text-navText"
            } `}
          >
            <a href="#">About Us</a>
          </li>
          <li
            className={`active-menu-link ${
              location.pathname === "/contact-us"
                ? "text-secondaryText"
                : "text-navText"
            } `}
          >
            <a href="/contact-us">Contact Us</a>
          </li>
        </ul>
      </div>
      <Tree className="menu-tree" />
    </div>
  );
};

export default Menu;
