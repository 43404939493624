const keysToDisplay = [
    'email',
    'username', 
    'phone',
    'description',
    'name',  
    'lat',
    'lon',
    'balance',
    'provider',
    'top_services',
    'subscription_start_date',
    'subscription_end_date',
    'subscription_type',
    'wallet_blocked',
    'requested',
    'reset_phone_token',
    'verification_badge',
    'role',
  ];
  
  
  const bookingAttributes = [
    'date',
    'time',
    'status',
    'notes',
    'payment_method',
    'total',
    'ref',
    'refund',
    'rebook',
    'cancel_date',
    'balance',
    'staff',
    'total_mins',
    'approved',
    'refund_approved',
    'refund_status',
    'discount_amount',
    'amount_paid',
    'refund_amount'
  ];

  export {keysToDisplay,bookingAttributes}