import { Avatar } from "antd";
import React from "react";
import bin from '../assets/svgs/bin.svg'
import edit from '../assets/svgs/edit.svg'
import axios from "axios";
import { backendURL } from "../redux/http";
import { Button, Modal, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import EditDropdown from "./Editmenu";
import { timeAgo } from "../function";
const { confirm } = Modal;
const ReviewsRow = ({ data, refetch }) => {


  console.log(data);


  const approve = async (state) => {
    const response = await axios.patch(backendURL + "/admin/update", {
      model: 'Rating',
      scope: 'rating',
      id: data.id,
      rating: { approved: state }
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('token')}`

      },
    });

    refetch()
  }
  return (
    <div key={data.id} style={{ height: 150 }} className="tred "
    >

      <div className="flex-item" >{data.user.username}</div>

      <div className="flex-item">{data.vendor.username}</div>
      <div className="flex-item">{data.rating}</div>
      <div className="flex-item">{data.description}</div>
      <div className="flex-item">{data.updated_at ? timeAgo(data.updated_at) : ''}</div>
      <div className="flex-item d-flex ">
        <button onClick={() => approve(true)} className="d-flex action unapproved">{data.approved ? "Approved" : 'Approve'}</button>
        <button onClick={() => approve(false)} className="d-flex decline action">Reject</button>
      </div>

    </div>
  );
};

export default ReviewsRow;