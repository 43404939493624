import { Avatar } from "antd";
import React from "react";
import bin from '../assets/svgs/bin.svg'
import edit from '../assets/svgs/edit.svg'
import InfoDrawer from "../components/InfoDrawer";

import { Button, Modal, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import EditDropdown from "./Editmenu";
import { timeAgo } from "../function";
import { bookingAttributes } from "../_data/keys";
const { confirm } = Modal;
const BookingListRow = ({ data }) => {

  const [open, setOpen] = React.useState(false);
  console.log(data);
  const bgc = {
    cancelled: '#CD3D49',
    completed: '#86D694',
    confirmed: '#86D694',
    booked: "#00463C'",
    rebook: "#00463C'",
    "no show": '#CD3D49',
    unconfirmed: "#00463C",
    declined: '#CD3D49',
    pending: 'red'
  }

  const servs = (data) => {

    const service_names = data.map((d) => d.name)
    return service_names.join('-')
  }
  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure delete this task?',
      icon: <ExclamationCircleFilled />,
      content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        alert(id)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  return (
    <div key={data.id} style={{ height: 100 }} className="tred2"
    >



      <div className="flex-item" >{data.user.username}</div>

      <div className="flex-item">{data.vendor.username}</div>
      <div className="flex-item">{servs(data.services)}</div>
      <div className="flex-item" style={{ whiteSpace: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>{data.vendor.name}</div>
      <div className="flex-item"><div style={{ backgroundColor: bgc[data.status] }} className="status" >{data.status}</div></div>
      <div className="flex-item">{timeAgo(data.created_at)}</div>
      <div className="flex-item d-flex edit">
        <img onClick={() => showDeleteConfirm(data.id)} src={bin} />
        <img onClick={() => setOpen(!open)} src={edit} style={{ width: 30, marginLeft: 10 }} />
      </div>
      <InfoDrawer keys={bookingAttributes} scope={'Booking'} userData={data} open={open} setOpen={setOpen} />

    </div>
  );
};

export default BookingListRow;