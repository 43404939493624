import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {backendURL} from './http';
export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: backendURL,
    tagTypes: ["Admin"],
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        // include token in req header
        headers.set('Authorization', `Bearer ${token}`);
        return headers;
      }
    },
  }),
  endpoints: builder => ({
    
    createVendor: builder.mutation({
      query: data => ({
        url: '/vendor/signup',
        method: 'POST',
        body: data,
      }),
    }),
    Login: builder.mutation({
      query: data => ({
        url: '/user/login',
        method: 'POST',
        body: data,
        invalidatesTags: ["Admin"],
      }),
    }),
    getApprovedVendors: builder.query({
      query: (scope) => ({
        url: '/admin/home',
        method: 'GET',
        params : {
          scope: scope
        }
      
      }),
    }),
    getall: builder.query({
      query: ({scope,type,page,tab,search})=> ({
        url: `/admin?scope=${scope}&page=${page}&tab=${tab}&search=${search}&type=${type}`,
        method: 'GET',
        invalidatesTags: ["Admin"],
      }),
    }),
    action: builder.mutation({
      query: data => ({
        url: `/admin/action`,
        method: 'POST',
        params: data
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/logout',
        method: 'POST',
      }),
    }),
    getvendorstaff: builder.query({
      query: (id) => ({
        url: `/staff/${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['Vendor'],
    }),
    createvendorstaff: builder.mutation({
      query: (data) => ({
        url: '/staff/',
        method: 'POST',
        params: data
      }),
    }),

    deletevendorstaff: builder.mutation({
      query: (data) => ({
        url: `/staff/${data.staff_id}?vendor_id=${data.id}`,
        method: 'DELETE',
      }),
    }),
    getVendor: builder.query({
      query: (id)=> ({
        url: '/details?id='+ id,
        method: 'GET',
        invalidatesTags: ["Admin"],
      }),
    }),
    delete: builder.mutation({
      query: (data)=> ({
        url: '/admin/delete',
        method: 'POST',
        params: data,
        invalidatesTags: ["Admin"],
      }),
    }),
    addSubscription: builder.mutation({
      query: (data)=> ({
        url: '/upgrade',
        method: 'POST',
        params: data
      }),
    }),
    create: builder.mutation({
      query: (data)=> ({
        url: '/admin/create',
        method: 'POST',
        params: data,
    
      }),
    }),
    sendnoti: builder.mutation({
      query: (data)=> ({
        url: '/send_notification',
        method: 'POST',
        params: data
      }),
    }),
    checkNoti: builder.query({
      query: (data)=> ({
        url: '/has_unread',
        method: 'GET',
        params: data
      }),
    }),
    bank: builder.query({
      query: (data)=> ({
        url: '/bank',
        method: 'GET',
        params: data
      }),
    }),
   getnoti: builder.query({
      query: (data)=> ({
        url: '/user/notifications',
        method: 'GET',
        params: data
      }),
    }),
    updateVendor: builder.mutation({
      query: (data) => ({
        url: '/vendor/business',
        method: 'POST',
        params: data
      }),
    }),

  report: builder.query({
      query: (data) => ({
        url: '/admin/report',
        method: 'GET',
        params: data
      }),
    }),
   
    getsub: builder.query({
      query: (data)=> ({
        url: '/subscriptions',
        method: 'GET',
        params: data
      }),
    }),
  }),
});

export const {
  useGetEmailConfirmMutation,
  useVerifyPhoneMutation,
  useLoginMutation,
  useGetVendorQuery,
  useLazyGetallQuery,
  useCreateVendorMutation,
  useGetApprovedVendorsQuery,
  useGetvendorstaffQuery,
  useCreatevendorstaffMutation,
  useDeletevendorstaffMutation,
  useActionMutation,
  useAddSubscriptionMutation,
  useSendnotiMutation,
  useDeleteMutation,
  useCreateMutation,
  useGetnotiQuery,
  useCheckNotiQuery,
  useLazyBankQuery,
  useGetsubQuery,
  useUpdateVendorMutation,
  useLazyReportQuery

} = authApi;
