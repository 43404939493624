import React from 'react';
import refresh from '../assets/images/refresh.png';
import print from '../assets/images/print.png';
import exportpic from '../assets/images/exportpic.png';
import Input from 'antd/es/input/Input';
import { SearchOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { saveAs } from 'file-saver';
import generatePDFFromElement from '../function';


function Query({ refetch, search, data=[{
  name:'rich'
}] }) {
    const exportToCSV = () => {
        if (!data || data.length === 0) {
            console.log('No data to export.');
            return;
        }

        const csvContent = convertToCSV(data);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'data.csv');
    };

    const exportToExcel = () => {
      
        generatePDFFromElement('vendorapp')
    };

    

    const convertToCSV = (data) => {
        const csvRows = [];
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(','));

        data.forEach((row) => {
            const values = headers.map((header) => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        });

        return csvRows.join('\n');
    };

    

    const menuItems = [
        {
            label: 'CSV',
            key: '0',
            onClick: exportToCSV,
        },
        {
            label: 'Excel',
            key: '1',
            onClick: exportToExcel,
        },
        {
            label: 'PDF',
            key: '2',
            onClick:null,
        }
    ];

    return (
        <div style={{ width: '100%', marginLeft: 'auto', display: 'flex' }}>
            <img onClick={refetch} className="action-img" style={{ width: 60, height: 30 }} src={refresh} alt="refresh" />
            <Dropdown placement="topRight" overlay={<Menu>{menuItems.map(item => (
                <Menu.Item key={item.key} onClick={item.onClick}>
                    {item.label}
                </Menu.Item>
            ))}</Menu>}>
                <img src={exportpic} className="action-img" style={{ width: 60, height: 30, marginLeft: 30 }} alt="export" />
            </Dropdown>
            <img src={print} className="action-img" style={{ width: 60, height: 30, marginLeft: 30 }} alt="print" />
            <Input onChange={search} className="search" placeholder={'search..'} prefix={<SearchOutlined />} /> <br />
        </div>
    );
}

export default Query;
