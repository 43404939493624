import React from "react";
import { Avatar,Empty } from "antd";
import userImage from '../assets/images/userimg.png';

import { formatDate } from "../function";
const UserUpdate=({data})=>{
    return(
        <div>
        { data?
data.map((item)=>{
   return (<div className="user-details">
        <div className="content">
       <Avatar onClick={() => alert(item.image)} size={50} src={item.image || userImage} />
        </div>
        <div className="content font-weight-bold">{item.username}</div>
        <div className="content">{item.email}</div>
        <div className="content">{item.phone?item.phone:'none'}</div>
        <div className="content">{formatDate(item.created_at) }</div>
      </div>)
})
      :<Empty/>  }
        </div>
        
    )
}

export default UserUpdate