// src/TermsOfUse.js
import React, { useEffect, useState } from "react";
import Menu from "../components/Menu";
import Navbar from "../components/Navbar";
import { ReactComponent as ChevronRight } from "../assets/svgs/chevron_right.svg";
import { ReactComponent as Tree } from "../assets/svgs/phone_tree.svg";
import { ReactComponent as Cloud } from "../assets/svgs/cloud.svg";
import { ReactComponent as Birds } from "../assets/svgs/birds.svg";
import { ReactComponent as Dot } from "../assets/svgs/dot.svg";
import { ReactComponent as LandingBubble } from "../assets/svgs/landing_bubble.svg";
import { ReactComponent as MobileBubble } from "../assets/svgs/mobile_bubble.svg";
import PrivacyPolicySection from "../components/PolicySection";
import partnerTermsOfBusiness from "../_data/partner_terms";
import Footer from "../components/Footer";

const PartnerTerms = () => {
    const [show, setShow] = useState(false);
    const [menuShow, setMenuShow] = useState(false);
    let menuBtn;

    useEffect(() => {
        menuBtn = document.getElementsByClassName("menu-btn")[0];
    });

    const handleShow = (data) => {
        if (menuBtn) {
            if (data) {
                document.getElementsByTagName("html")[0].style.overflowY = "hidden";
                menuBtn.style.transform = "scale(0.6)";
                menuBtn.classList.add("open");
                setShow(!show);
                setMenuShow(true);
            } else {
                document.getElementsByTagName("html")[0].style.overflowY = "scroll";
                menuBtn.style.transform = "scale(0.5)";
                menuBtn.classList.remove("open");
                setShow(!show);
                setMenuShow(false);
            }
        }
    };

    return (
        <>
            {show && <Menu show={show} handleShow={handleShow} />}
            <Navbar show={show} handleShow={handleShow} />

            <div className="min-h-screen text-darkslategray bg:bg-darkslategray bg-lightgreen flex justify-center">
                <div className="text-left max-w-screen-md overflow-y-auto max-w-2xl p-8 text-darkslategray shadow-md md:rounded-md md:mb-3">
                    <h1 className="text-3xl text-darkslategray font-bold mb-6">Partner Terms</h1>
                    {partnerTermsOfBusiness.map((section, index) => (
                        <PrivacyPolicySection key={index} title={section.title} content={section.content} />
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PartnerTerms;
