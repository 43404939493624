import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const generatePDFFromElement = (elementId) => {
  const element = document.getElementById(elementId);

  if (!element) {
    console.error(`Element with ID '${elementId}' not found.`);
    return;
  }

  // Capture the HTML element as an image using html2canvas
  html2canvas(element)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');

      // Add the image to the PDF
      pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());

      // Download the PDF
      pdf.save(`${elementId}_report.pdf`);
    })
    .catch((error) => {
      console.error('Error generating PDF:', error);
    });
};

export default generatePDFFromElement;

 
 
 export function timeAgo(railsDateString) {
  const now = new Date();
  const date = new Date(railsDateString);

  if (isNaN(date)) {
    return "Invalid date";
  }

  const seconds = Math.floor((now - date) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return `${interval} years ago`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} months ago`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} days ago`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} hours ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} minutes ago`;
  }
  return `${Math.floor(seconds)} seconds ago`;
}

export function formatDate(originalDate) {
  var parsedDate = new Date(originalDate);

  var day = parsedDate.getDate();
  var month = parsedDate.getMonth() + 1; // Months are zero-based, so add 1
  var year = parsedDate.getFullYear();

  var formattedDate = day + '/' + month + '/' + year;

  return formattedDate;
}


export const DateInput = (value) => {
    if  (!value|| value===null ||value==''){
      return new Date(value).toISOString()
    }
 else  return ''
}

 
export const formatDateReadable=(isoDateString)=> {
  const date = new Date(isoDateString);

  // Format the date
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short"
  };

  return date.toLocaleString("en-US", options);
}

// Example usage:


export function formatRailsDatetime(railsDatetime) {
  // Convert Rails datetime string to a JavaScript Date object
  const date = new Date(railsDatetime);

  // Check if the date is valid
  if (isNaN(date)) {
    throw new Error("Invalid date format");
  }

  // Options for formatting the date
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',

  }
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDate;
}


export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + '...';
};