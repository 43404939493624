import React, { useEffect, useState } from "react";
import ClientWaitlist from "../components/ClientWaitlist";
// import ClientWaitlist from "../components/ClientWaitlist";
import Navbar from "../components/Navbar";
// import Footer from "../components/Footer";
import Menu from "../components/Menu";

const ClientWaitlistPage = () => {
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  let menuBtn;
  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  const handleShow = (data) => {
    console.log("here", data);
    if (menuBtn) {
      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        console.log("else");
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };
  return (
    <div className="appBg ">
      {show && <Menu handleShow={handleShow} />}
      <Navbar show={show} handleShow={handleShow} />

      <ClientWaitlist />
    </div>
  );
};

export default ClientWaitlistPage;
