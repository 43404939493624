import { Avatar } from "antd";
import React from "react";
import bin from '../assets/svgs/bin.svg'
import edit from '../assets/svgs/edit.svg'
import { Button, Modal, Space } from 'antd';
import { toast } from 'react-toastify';

import { ExclamationCircleFilled } from '@ant-design/icons';
import InfoDrawer from "../components/InfoDrawer";
import { useDeleteMutation } from "../redux/user";

import EditDropdown from "./Editmenu";
import Checkbox from "../components/Checkbox";
import { timeAgo } from "../function";
const { confirm } = Modal;
const EmployeeStatusRow = ({ data ,refetch}) => {


  const bg={
    pending:'#00463C',
    failed:'#c63f4c',
    paid:'#86d694'
  }
  const [del]= useDeleteMutation()
     
  const [open, setOpen] = React.useState(false);
    console.log(data);

    
    const showDeleteConfirm = async(id) => {

      try{ confirm({
         title: 'Are you sure delete this user?',
         icon: <ExclamationCircleFilled />,
         content: 'Are you sure you want to delete this user',
         okText: 'Yes',
         okType: 'danger',
         cancelText: 'No',
         onOk: async()=> {
          const {data} = await del({id,scope:'user'})
          refetch()
          
           // Display success toast
   toast.success('User Deleted', {
       position: 'top-right',
       autoClose: 3000,
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,

     });

         },
         onCancel() {
           console.log('Cancel');
         },
       });

     }catch(e){
       console.error('Error during confirmation:', e);
     }
     };
    return (
       <div key={data.id}   className="tred3"> 
      
      
          <div className="flex-item" style={{ whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis', marginLeft:20,  overflowWrap: 'break-word' }} >{data.name}</div>
        <div className="flex-item" style={{ whiteSpace: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>{data.position}</div>
        <div className="flex-item price">  ₵{parseFloat(data.amount).toFixed(2)}</div>
        

<div className="flex-item action"  style={{justifySelf:'center',color:'white', background: bg[data.status]|| '#00463C'}}>
      {data.status||'pending'}
      </div>
      <div className="flex-item" style={{ whiteSpace: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>{timeAgo(data.updated_at)}</div>

      
        <InfoDrawer keys={['username','nick_name','email',"phone","address",'reset_password_token','confirmation_token',"reset_phone_token","phone_confirmed", "unconfirmed_email","ref_code","password","role"]} scope={"User"} userData={data} open={open} setOpen={setOpen}/>
    
        </div>
    );
};
export default EmployeeStatusRow;