import React,{useState,useEffect} from "react";
import TabView from "../components/Tabview";
import Query from "../components/Query";
import VendorRequest from "../routecomponents/vendorequest";
import VendorApproved from "../routecomponents/vendorapproved";
import UserList from "../routecomponents/userlist";
import ServiceList from "../routecomponents/serviceslist";
import { useLazyGetallQuery } from "../redux/user";
import Loading from "../components/Loading";
import CustomPagination from "../components/Pagination";
import CreateServices from "./CreateServices";
import InfoHeader from "../components/InfoHeader";
import { Empty } from "antd";
import Hours from "../components/Hours";
import Account from "../components/Account";


function SettingsPage(){
  const [search_param,setSearch] = useState();
  const [active, setActive] = useState(0); // Index of the active tab
  const [currentPage, setCurrentPage] = useState(1);
  const [getall,{ data, isLoading, refetch }] = useLazyGetallQuery();
  console.log(data)

    const tabs=[


      {
          title:'Account',
          content: <Account/>
        },
    {
      title:'Vendors',
      content: <Empty/>
    },
    {
        title:'Pamp Members',
        content: <Empty/>
      },
      {
        title:'System',
        content: <Empty/>
      },
      {
        title:'Roles',
        content: <Hours/>
      },
  
  ]
  useEffect(() => {
    // Fetch data whenever the active tab or page changes
  }, []);
  
return(
    <><h2 className="update-title title">Settings</h2>
   
   

   <TabView  show={active===1?false:true} width={'100%'} tabs={tabs} setActive={setActive} />
   
   
    </>
)
}

export default SettingsPage