import React, { useState, useEffect } from "react";
import TabView from "../components/Tabview";
import Query from "../components/Query";
import VendorRequest from "../routecomponents/vendorequest";
import VendorApproved from "../routecomponents/vendorapproved";
import Reviews from "../routecomponents/reviews";
import { useLazyGetallQuery } from "../redux/user"; 
import CustomPagination from "../components/Pagination";
import InfoHeader from "../components/InfoHeader";

function ReviewsPage(){
  const [active, setActive] = useState(0); // Index of the active tab
  const [currentPage, setCurrentPage] = useState(1);
  const [getall,{ data, isLoading }] = useLazyGetallQuery();
  const [search_param,setSearch] = useState();

  const handleTabChange = (index) => {
    setActive(index);
    setCurrentPage(1); // Reset page when switching tabs
    // Fetch data for the selected tab
    getall({ scope:'reviews', page: currentPage, });
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };


  const refetch=()=>{
    getall({ scope: "reviews", page: currentPage, search: search_param });

  }
  useEffect(() => {
    // Fetch data whenever the active tab or page changes
   getall({ scope: "reviews", page: currentPage,search:search_param });
  }, [search_param,currentPage]);
  const search=(e)=>{
  
    setSearch(e.target.value)
    }
  const tabs=[{
    title:'Reviews',
    content: <Reviews refetch={refetch} data={data?data.reviews:[]}/>
  },
]
return(

    <><h2 className="update-title title">Reviews</h2>
   
   <TabView  show={true} children={<Query search={search} refetch={refetch}/>} tabs={tabs} setActive={setActive} />

   <CustomPagination
         data={data}
         handlePageChange={handlePageChange}
       />
   
   
    </>
)
}

export default ReviewsPage