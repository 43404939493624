import React from 'react';

const StatisticInfoContainer = ({ icon, name, total, newValue, improvement }) => {
    const improvementIcon = improvement > 0 ? '▲' : improvement < 0 ? '▼' : '';
    const improvementColor = improvement > 0 ? 'text-green-500' : improvement < 0 ? 'text-red-500' : '';

    return (
        <div className="bg-white rounded-[20px] mt-5  p-4 flex items-center justify-between">
            <div className="flex items-center">
                <div>
                    <h3 className="text-lg text-lightgreen font-semibold">{name}</h3>
                    <div className="flex items-center">
                        <p className="text-gray-600 mr-2">Total:</p>
                        <p className="text-xl font-semibold">{total}</p>
                    </div>

                    <div className="flex items-center">
                        <p className="text-gray-600 mr-2">New:</p>
                        <p className="text-xl font-semibold">{newValue}</p>
                    </div>

                    {/* {improvement !== undefined && (
                        <div className={`text-xl font-semibold ${improvementColor}`}>
                            {improvementIcon} {Math.abs(improvement)}%
                        </div>
                    )} */}
                </div>
            </div>

        </div>
    );
};

export default StatisticInfoContainer;
