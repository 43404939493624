import React from 'react';
import refresh from '../assets/images/refresh.png';
import print from '../assets/images/print.png';
import exportpic from '../assets/images/exportpic.png';
import Input from 'antd/es/input/Input';
import { SearchOutlined,LoadingOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { Spin } from 'antd';
function SearchAndPay({ search, selectedEmployees, data,pay ,isLoading}) {
    const calculateTotalAmount = () => {
        if (!data || !selectedEmployees || selectedEmployees.length === 0) {
            return 0;
        }
        
        return selectedEmployees.reduce((total, id) => {
            const employee = data.find(user => user.id === id);
            if (employee) {
                return total + employee.salary;
            }
            return total;
        }, 0);
    };

    return (
        <div style={{ width: '100%', marginLeft: 'auto', alignItems: 'center', display: 'flex' }}>
            <Input onChange={search} className="search mr-5" placeholder={'search..'} prefix={<SearchOutlined />} />
            <div style={{ display: 'flex', backgroundColor: ' ', paddingBottom: 30 }}>
                <div style={{ height: 50, background: 'white', borderRadius: 35, padding: 10 }}>
                    <div className='' style={{ height: 30, width: 200, display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                        <TabButton key={Math.random()}>{calculateTotalAmount()}</TabButton>
                        <TabButton key={Math.random()} isActive={true} onClick={pay}>Pay{isLoading&&<Spin indicator={<LoadingOutlined style={{ fontSize: 18,}} spin />} />}</TabButton>
                    </div>
                </div>
            </div>
            <br />
        </div>
    );
}

const TabButton = ({ isActive, onClick, children }) => (
    <button
        style={{
            color: isActive ? '#ffffff' : '#86D694',
            textAlign: 'center',
            height: 30,
            width: 200,
            borderRadius: 35,
            background: isActive ? "#86d694" : "#ffff",
            border: 'none',
            outline: 'none',
        }}
        onClick={onClick}
    >
        {children}
    </button>
);

export default SearchAndPay;
