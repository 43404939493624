import React from "react";

const PrivacyPolicySection = ({ title, content }) => {
  const renderContent = (item) => {
    if (typeof item === "string") {
      const parts = item.split(/(\[.*?\]\(.*?\))/);
      return (
        <p>
          {parts.map((part, index) => {
            const match = part.match(/\[(.*?)\]\((.*?)\)/);
            if (match) {
              const [, linkText, url] = match;
              return (
                <a key={index} href={url} className="text-blue-500 hover:underline">
                  {linkText}
                </a>
              );
            }
            return part;
          })}
        </p>
      );
    } else if (item.type === "bullets") {
      return (
        <ul>
          {item.content.map((bullet, bulletIndex) => (
            <li key={bulletIndex}>{renderContent(bullet)}</li>
          ))}
        </ul>
      );
    } else if (item.type === "subSection") {
      return (
        <div>
          <h3 className="text-lg font-semibold mb-2">{item.title}</h3>
          {item.content.map((subItem, subIndex) => (
            <div key={subIndex}>{renderContent(subItem)}</div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="mb-6">
      <h2 className="text-xl text-darkslategray text-left font-semibold mb-2">{title}</h2>
      {content.map((item, index) => (
        <div key={index}>{renderContent(item)}</div>
      ))}
    </div>
  );
};

export default PrivacyPolicySection;
