import TabView from "./Tabview";
import React, { useEffect } from "react";
import UserUpdate from "./UserUpdateCont";
import VendorUpdate from "./VendorUpdate";
import { Empty } from "antd";
import TransferList from "./TransferList";
import TransferStatus from "./TransferStatus";
import { useLazyGetallQuery } from "../redux/user";

function Transfers({ refetch, data }) {

  useEffect(() => {
    refetch()
  }, [])



  const tabs = [{
    title: 'Lists',
    content: <TransferList data={data} />
  },
  {
    title: 'Status',
    content: <TransferStatus data={data} />
  }]
  return (
    <><h2 className="update-title mt-[80px]">Latest Transactions</h2><TabView tabs={tabs} /></>
  )
}

export default Transfers