import React, { useState, useEffect } from 'react';
import SelectInput from './Select';
import { useLazyGetallQuery, useLazyReportQuery } from '../redux/user';

const VendorStats = () => {
    const bgSlate = { backgroundColor: '#f7f7f9', outerWidth: 200 }
    const [report, setReport] = useState(null);
    const [getall, { data: vendors }] = useLazyGetallQuery();
    const [getReport, { isLoading }] = useLazyReportQuery();

    useEffect(() => {
        getall({ scope: 'list', type: 'vendors' });
    }, []);

    const findVendor = async (id) => {
        const { data } = await getReport({
            id,
            report_type: "vendor_report"
        });
        setReport(data)
    }

    return (
        <div className="bg-white rounded-[20px] p-6">
            <div className='w-[250px]'>
                <SelectInput
                    style={bgSlate}
                    type={'id'}
                    placeholder={'Select vendor'}
                    data={vendors}
                    className={'input bg-bg'}
                    onChange={(id) => {

                        findVendor(id);
                    }}
                />
            </div>
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <>
                    {report && (
                        <div>
                            <h2 className="text-lg text-lightgreen font-semibold mb-4 update-title">{report.name}</h2>
                            <div className="grid grid-cols-2 gap-4">
                                {Object.entries(report).map(([key, value]) => (
                                    <div key={key}>
                                        <h3 className="text-sm text-lightgreen font-medium">{key.replace(/_/g, ' ').toLocaleUpperCase()}</h3>
                                        <p className="text-xl text-lightgreen font-semibold">{value}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default VendorStats;
