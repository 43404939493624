import React from "react";
import { ReactComponent as Waitlist } from "../assets/svgs/waitlist_option.svg";
import { ReactComponent as Arrow } from "../assets/svgs/contact_us_arrow.svg";
import { ReactComponent as FlipFlops } from "../assets/svgs/flip_flops.svg";

const WaitlistOption = () => {
  return (
    <section className="waitlist-option">
      {/* <Birds className=" absolute right-[10px] top-0 " />
      <Birds className=" absolute left-[10px] top-[200px] scale-[0.6]" />
      <Cloud className=" absolute right-[150px] top-[-120px] hide" />
      <Cloud className=" absolute left-[200px] top-[50px] hide" />
      <Dot className=" absolute left-[500px] top-[-180px] hide" />
      <Suitcase className=" absolute right-0 top-[600px] scale-[0.7] hide" /> */}

      <a href="/waitlist/clients" className="waitlist-link-one"></a>
      <a href="/waitlist/partners" className="waitlist-link-two"></a>

      <p className="choose-waitlist-text">
        Choose a waitlist <span className="break-choose-text"></span> you'd like
        to join.
      </p>
      <FlipFlops className="wait-flip-flop hide-mobile" />
      {/* <img src={option}/> */}
      <div className="wait-svg-wrapper ">
        <Waitlist className="wait-svg" />
        <Arrow className="wait-arrow hide-mobile" />
      </div>
    </section>
  );
};

export default WaitlistOption;
