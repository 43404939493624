import React from "react";
import { ReactComponent as Pamp } from "../assets/svgs/business.svg";
import phone from "../assets/images/business_phone.png";
import { ReactComponent as Boat } from "../assets/svgs/boat.svg";
import { ReactComponent as Cloud } from "../assets/svgs/cloud.svg";
import { ReactComponent as Birds } from "../assets/svgs/birds.svg";
import { ReactComponent as Dot } from "../assets/svgs/dot.svg";

const Business = () => {
  return (
    <section className="appBg">
      <div className="container">
        <div className="row centerit business-wrapper">
          <div className="col-12 col-lg-6 relative">
            <Birds className="business-brid-one" />
            <Dot className="business-dot-one" />
            <Dot className="business-dot-two" />
            <Pamp className="business-pamp" />
            <Cloud className="business-cloud-two hide-mobile" />

          </div>

          <div className="col-12 col-lg-6 business-phone-wrapper">
            <Cloud className="business-cloud-one" />
            <Birds className="business-brid-two" />
            <Dot className="business-dot-three" />

            <img src={phone} className="img-fluid business-phone" alt="" />
            <Boat className="business-boat hide-mobile" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Business;
