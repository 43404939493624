import React from 'react';
import client from '../assets/svgs/clientDownload.svg'
import moclient from '../assets/images/Receive2.png'
import slipper from '../assets/svgs/slipper.svg'
import ScanDesktop from './ScanDesktop';
import ScanMobile from './ScanMobile';
const data = [
  { color: 'green-500', text: 'Find vendors near you.' },
  { color: 'blue-500', text:  (<>
  Seamlessly book <br />services.
</>)},
  { color: 'yellow-500', text: <>Keep track of your <br />appointments.</> },
  { color: 'purple-500', text: 'And more.' },
];

const ClientDownload = () => {
  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
  return (
    <div  className='relative  p-[10px] md:p-[20px] flex-1'>
      <div id='dcont'  className="mt-10  h-[433px]    md:h-[730px] relative mx-auto rounded-[20px] p-[20px]  " style={{width:'95%',backgroundColor:'white'}}>
        <h3 className='text-darkgreen text-[26px] md:text-[40px] text-source-san-pro pt-[40px] ml-[10px] md:ml-[50px]'>For Everyone</h3>
        <ul className="list-none pt-[20px] md:ml-[10px] ml-[-25px]  md:pt-[50px]">
        {data.map((item, index) => (
            <li key={index} className="flex  sm:mb-2 md:mb-[20px] animate-fade-in">
              <div className={`rounded-full mt-[5px] md:mt-[10px]  bg-beige  h-2 w-2 md:h-3 md:w-3 mr-3`}></div>
              <div className='text-lightgreen text-[13px] md:text-[24px] text-source-san-pro'>{item.text}</div>
            </li>
          ))}
        </ul>
      
        <div id='appsvg' className='absolute top-[0px] md:top-[0px] right-[10px]'>
        <img
 src={isMobile?moclient:client}
        
        className='h-[480px] w-[180px] md:w-[290px] md:h-[690px]  '
        />
</div>
<img
        src={slipper}
        className='ml-[60px] mb-[20px] h-[35px] md:h-[67px]'
        />
       
        <div className='w-full pr-[10px] pl-[30px]  ml-[20px] absolute bottom-[15px] md:bottom[20px] right-[10px]'>
              <div id='desk' className='hidden desk '>
              <ScanDesktop/>

              </div>
             <div id='mobile'  className='mobile'>
             <ScanMobile/>
              </div>  
        </div>
      </div>
   
    </div>
  )
}

export default ClientDownload;
