import React, { useState,useEffect } from 'react';
import TabView from "../components/Tabview";
import Query from "../components/Query";
import VendorRequest from "../routecomponents/vendorequest";
import VendorApproved from "../routecomponents/vendorapproved";
import UserList from "../routecomponents/userlist";
import BookingList from "../routecomponents/bookingslist";
import BookingStatus from "../routecomponents/bookingstatus";
import { useGetallQuery } from "../redux/user";
import PackageList from "../routecomponents/packagelist";
import { useLazyGetallQuery } from "../redux/user"; 
import InfoHeader from "../components/InfoHeader";

import CustomPagination from "../components/Pagination";


function PackagesPage(){
  const [getPlans,{ data}] = useLazyGetallQuery();

  const tabs=[
    {
        title:'Package List',
      content: <PackageList refetch={() => getPlans({ scope: 'list', type: 'packages' })} data={data?data:[]} />
      },
 
]
useEffect(() => {
  // Fetch data whenever the active tab or page changes
  getPlans({scope: 'list',type:'packages'})
}, []);

    const [active, setActive] = React.useState();
return(
    <><h2 className="update-title title">Packages</h2>
 


   

    <TabView  tabs={tabs} children={<Query />} setActive={setActive} />
   
   
    </>
)
}

export default PackagesPage