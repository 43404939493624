import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { backendURL } from '../redux/http';
import { Drawer, Button, message, Modal } from 'antd';
import userImage from '../assets/images/userimg.png';

import { useDeleteMutation } from '../redux/user';
import { toast } from 'react-toastify';
import { ExclamationCircleFilled } from '@ant-design/icons';

const Gallery = ({ data, refetch, drawerVisible, setDrawerVisible }) => {
  const [del] = useDeleteMutation()

  const { confirm } = Modal;
  const showDeleteConfirm = async (imageName, id) => {

    try {
      confirm({
        title: 'Are you sure delete this picture?',
        icon: <ExclamationCircleFilled />,
        content: 'Are you sure you want to delete this picture',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await axios.post(`${backendURL}/vendors/images/remove`, { image: imageName, id: data.id, img_id: id })
          refetch()

          // Display success toast
          toast.success('Picture Deleted', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,

          });

        },
        onCancel() {
          console.log('Cancel');
        },
      });

    } catch (e) {
      console.error('Error during confirmation:', e);
    }
  };




  const renderImageGallery = () => {


    // You can access the vendor images directly from the selectedVendor object
    const { vendor_images, name, cover_image, logo_image } = data;

    return (
      <div className="image-gallery">
        <h2 style={{ fontWeight: 'bold' }} className="title-med mb-3 mt-5">Logo Image</h2>
        <img onClick={() => showDeleteConfirm('logo')} key={1} style={{ objectFit: 'cover' }} src={logo_image || userImage} alt={`Vendor ${name} logo`} className="m-2 h-[400px] w-[400px]" />

        <h2 style={{ fontWeight: 'bold' }} className="title-med mb-3 mt-5">Cover Image</h2>
        <img onClick={() => showDeleteConfirm('cover')} key={2} style={{ objectFit: 'cover' }} src={cover_image || userImage} alt={`Vendor ${name} cover`} className="m-2 h-[400px] w-[400px]" />


        <h2 style={{ fontWeight: 'bold' }} className="title-med mb-3 mt-5">Vendor Images</h2>
        {vendor_images.map((image, index) => (
          <div key={index} className="mb-3">
            <img onClick={() => showDeleteConfirm('other', image.id)} style={{ objectFit: 'cover' }} src={image.image || userImage} alt={`Vendor ${name} Image ${index}`} className="m-2 h-[400px] w-[400px]" />

          </div>
        ))}


      </div>
    );
  };

  return (
    <div className="">
      <Drawer title={`Images`} placement="right" onClose={() => setDrawerVisible(false)} visible={drawerVisible} width={400}>
        <h1 className="update-title title">Vendors Gallery</h1>
        {renderImageGallery()}
      </Drawer>
    </div>
  );
};

export default Gallery;
