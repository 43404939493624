import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { ReactComponent as Congrats } from "../assets/svgs/congrats.svg";
import { ReactComponent as FlipFlops } from "../assets/svgs/flip_flops.svg";
import Menu from "../components/Menu";

const CongratsPage = () => {
  const [show, setShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  let menuBtn;
  useEffect(() => {
    menuBtn = document.getElementsByClassName("menu-btn")[0];
  });

  const handleShow = (data) => {
    if (menuBtn) {
      if (data) {
        document.getElementsByTagName("html")[0].style.overflowY = "hidden";
        menuBtn.style.transform = "scale(0.6)";
        menuBtn.classList.add("open");
        setShow(!show);
        setMenuShow(true);
      } else {
        document.getElementsByTagName("html")[0].style.overflowY = "scroll";
        menuBtn.style.transform = "scale(0.5)";
        console.log("else");
        menuBtn.classList.remove("open");
        setShow(!show);
        setMenuShow(false);
      }
    }
  };
  return (
    <div className="appBg congrats-page">
      {show && <Menu show={show} handleShow={handleShow} />}

      <Navbar />
      <div className="congrats-wrapper">
        <FlipFlops className="congrats-flip-flop" />
        <Congrats className="congrats-bg " />
        <a
          href="#"
          className="bg-none congrats-link w-[310px] h-[140px] top-[353px] left-[140px] block cursor-pointer z-[2] absolute "
        ></a>
      </div>
    </div>
  );
};

export default CongratsPage;
