import React, { useState, useEffect } from "react";
import TabView from "../components/Tabview";
import Query from "../components/Query";
import VendorRequest from "../routecomponents/vendorequest";
import VendorApproved from "../routecomponents/vendorapproved";
import { useLazyGetallQuery } from "../redux/user";
import Loading from "../components/Loading";
import { Pagination, Affix } from "antd";
import CustomPagination from "../components/Pagination";
import CreateVendor from "./CreateVendor";
import InfoHeader from "../components/InfoHeader";
import UserList from "../routecomponents/userlist";


function Influencers() {
    const [active, setActive] = useState(0); // Index of the active tab
    const [currentPage, setCurrentPage] = useState(1);
    const [getall, { data, isLoading, refetch }] = useLazyGetallQuery();
    const [search_param, setSearch] = useState();

    const handleTabChange = (index) => {
        setActive(index);
        setCurrentPage(1); // Reset page when switching tabs
        // Fetch data for the selected tab
        getall({ scope: 'influencers', page: currentPage, tab: tabs[index].key });
    };

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        // Fetch data whenever the active tab or page changes
        getall({ scope: "influencers", page: currentPage, tab: tabs[active].key, search: search_param });
    }, [refetch, search_param, currentPage]);
    const search = (e) => {

        setSearch(e.target.value)
    }
    const tabs = [
        { key: 'request', title: 'Request', content: <UserList rate Influencer={true} refetch={() => handleTabChange(active)} data={data?.users || []} /> },
        { key: 'approved', title: 'Approved', content: <UserList  rate={true}  refetch={() => handleTabChange(active)} data={data?.users || []} /> },
    ];

    console.log(data, 'kk')


    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Affix offsetTop={100}><h2 style={{ height: 70, backgroundColor: ' #F7F7F9' }} className="update-title"> Influencers</h2>
                    </Affix>

                    <TabView show={active === 3 ? false : true} children={<Query search={search} refetch={() => handleTabChange(active)} />} tabs={tabs} setActive={handleTabChange} />



                    <CustomPagination
                        data={data}
                        show={active === 3 ? false : true}

                        handlePageChange={handlePageChange}
                    />

                </>
            )}
        </>
    );
}

export default Influencers;
