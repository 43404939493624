import { Avatar } from "antd";
import React from "react";
import bin from '../assets/svgs/bin.svg';
import edit from '../assets/svgs/edit.svg';
import { Button, Modal, Space } from 'antd';
import { toast } from 'react-toastify';
import { ExclamationCircleFilled } from '@ant-design/icons';
import InfoDrawer from "../components/InfoDrawer";
import { useDeleteMutation } from "../redux/user";
import EditDropdown from "./Editmenu";
import Checkbox from "../components/Checkbox";
import EditEmployee from "../pages/EditEmployee";
const { confirm } = Modal;

const EmployeeListRow = ({ data, refetch, selected, toggleSelection }) => {
    const [del] = useDeleteMutation();
    const [open, setOpen] = React.useState(false);

    const handleCheckboxChange = () => {
        toggleSelection(data.id);
    };

    const showDeleteConfirm = async (id) => {
        try {
            confirm({
                title: 'Are you sure delete this member?',
                icon: <ExclamationCircleFilled />,
                content: 'Are you sure you want to delete this member',
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk: async () => {
                  
                   const { data } = await del({ id, scope: 'pampMember' });
      
               refetch()
                    // Display success toast
                    toast.success('Employee Deleted', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } catch (e) {
            console.error('Error during confirmation:', e);
        }
    };

    return (
        <div key={data.id} className="tred">
            <div className="flex-item icont" onClick={() => console.log(data.id)}>
                <Checkbox
                     showLabel={false}
                     label={data.id}
                    checked={selected}
                    onChange={handleCheckboxChange}
                />
                <div className="" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>{data.first_name}</div>
            </div>
            <div className="flex-item" style={{ whiteSpace: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>{data.job_title}</div>
            <div className="flex-item">{data.bank_name}</div>
            <div className="flex-item flex-wrap" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>
                {data.account_number}
            </div>
            <div className="flex-item price" style={{ justifySelf: 'center', color: "#86D694" }}>
                ₵{parseFloat(data.salary).toFixed(2)}
            </div>
            <div className="flex-item d-flex edit">
                <img onClick={() => showDeleteConfirm(data.id)} src={bin} alt="delete" />
                <img onClick={() => setOpen(!open)} src={edit} alt="edit" style={{ width: 30, marginLeft: 10 }} />
            </div>
            <EditEmployee refetch={refetch}  data={data} isOpen={open} setOpen={setOpen}/>
        </div>
    );
};

export default EmployeeListRow;
