import React from "react";
import { ReactComponent as Logo } from "../assets/svgs/small_logo.svg";
import { ReactComponent as Instagram } from "../assets/svgs/instagram.svg";
import { ReactComponent as Twitter } from "../assets/svgs/twitter.svg";
import { ReactComponent as Facebook } from "../assets/svgs/facebook.svg";
import { ReactComponent as In } from "../assets/svgs/in.svg";
import { ReactComponent as TikTok } from "../assets/svgs/tiktok.svg";
import { ReactComponent as Youtube } from "../assets/svgs/youtube.svg";

const Footer = ({ style }) => {
  return (
    <footer className={`appTextBg ${style}`}>
      <div className="footer container">
        <div className="hide-mobile">
          <a href="/">
            <Logo className="footer-logo" />
          </a>
          <p className="connect-text-footer">Connect with us on</p>
          <div className="sitemap">
            <a
              className="footer-link"
              href="https://www.instagram.com/trypamp"
              target="_blank"
            >
              <Instagram className="mr-5 cursor-pointer" />
            </a>
            <a
              className="footer-link"
              href="https://twitter.com/trypamp"
              target="_blank"
            >
              <Twitter className="mr-5 cursor-pointer" />
            </a>
            <a
              className="footer-link"
              href="https://www.facebook.com/trypamp"
              target="_blank"
            >
              <Facebook className="mr-5 cursor-pointer" />
            </a>
            <a
              className="footer-link"
              href="https://www.linkedin.com/company/trypamp"
              target="_blank"
            >
              <In className="mr-5 cursor-pointer" />
            </a>
            <a
              className="footer-link"
              href="https://www.tiktok.com/@trypampgh"
              target="_blank"
            >
              <TikTok className="mr-5 cursor-pointer" />
            </a>
            <a
              className="footer-link"
              href="https://www.youtube.com/channel/UCFhbT66WEK6johvi7GGzliQ"
              target="_blank"
            >
              <Youtube className="mr-5 cursor-pointer" />
            </a>
          </div>
        </div>
        <div className="sitelinks">
          <div>
            <h4 className="sitelink-title fw-600">Company</h4>
            <ul className="sitelink-list">
              <li className="sitelink-list-item">About Us</li>
              <li className="sitelink-list-item">
                <a href="">Careers</a>
              </li>
              <li className="sitelink-list-item">Support</li>
              <li className="sitelink-list-item">
                <a href="/contact-us">Contact Us</a>
              </li>
            </ul>
          </div>
          <div className="text-secondaryText text-[25px] hide-mobile">
            <h4 className="sitelink-title fw-600">For Business</h4>
            <ul className="sitelink-list">
              <li className="sitelink-list-item">Pricing</li>
              <li className="sitelink-list-item">For Partners</li>
              <li className="sitelink-list-item">Support for Partners</li>
            </ul>
          </div>
          <div className="hide-mobile">
            <h4 className="sitelink-title fw-600"> Legal</h4>
            <ul className="sitelink-list">
             <li className="sitelink-list-item">  <a href="/privacy-policy">Privacy Policy</a></li>
             <li className="sitelink-list-item"><a href="/terms-of-service">Terms of Service</a></li>
             <li className="sitelink-list-item"> <a href="/terms-of-use">Terms of Use</a></li> 
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
