import { Avatar } from "antd";
import React from "react";


import { Button, Modal, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import EditDropdown from "./Editmenu";
import { formatDate } from "../function";
import axios from "axios";
import { backendURL } from "../redux/http";
const { confirm } = Modal;
const RefundRow = ({ data }) => {


  console.log(data);
  const refundAmount = (status, amount) => {
    if (status === 'full' || status === 'yes') {
      return amount
    } else if (status === 'half') {
      return amount - (0.2 * amount)
    }
  }

  const showConfirm = async () => {
    try {
      confirm({
        title: 'Confirm withdrawal?',
        icon: <ExclamationCircleFilled />,
        content: 'Are you sure you want to approve this withdrawal?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          const response = await axios.post(
            backendURL + `/vendor/withdraw/process/${data.id}`,
            { scope: 'approved' }
          );
          alert(response.data.message);
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } catch (error) {
      console.error('Error during confirmation:', error);
      // Handle the error as needed
    }
  };

  return (
    <div key={data.id} className="tred "
    >


      <div className="flex-item" onClick={() => console.log(data.id)}>
        <div className="" >{data.user.username}</div>
      </div>
      <div className="flex-item price">{refundAmount(data.refund, data.total)}</div>
      <div className="flex-item " >{data.payment_method}</div>
      <div className="flex-item">{data.ref || 'none'}</div>
      <div className="flex-item">{formatDate(data.created_at)}</div>
      <div className="flex-item d-flex ">
        <div onClick={showConfirm} className="d-flex action unapproved">Approve</div>
        <div className="d-flex decline action">Decline</div>
      </div>

    </div>
  );
};

export default RefundRow;