import React from "react";


const TotalCategories=({data,title,sub_title})=>{
    return(<div  className="userdetcont"> 
      <div style={{color:'#C1E6BA'}} className="small-cont1">{title}</div>
        <div className="small-cont2">{data}</div>
        <div className="small-cont3" style={{color:'#C1E6BA'}} >{sub_title}</div>
    </div>)
}

export default TotalCategories

// background: #B0EBBD 0% 0% no-repeat padding-box;
// opacity: 1;