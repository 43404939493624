import React, { useState, useRef } from 'react';
import { Drawer, Avatar } from 'antd';
import TextInput from '../components/Input';
import { UserOutlined } from '@ant-design/icons';
import userImage from '../assets/images/userimg.png';

const Account = () => {
    const [formInfo, setFormData] = useState({});
    const [imageSource, setImageSource] = useState(null);
    const [file, setImageFile] = useState(null);
    const formRef = useRef(null);
    const bgSlate = { backgroundColor: '#f7f7f9' }
    const [loading, setLoading] = useState(false);
    const handleChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };

    const handleClose = () => {

    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImageFile(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageSource(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSave = async () => {
        // Your save logic here
    };

    return (

        <form ref={formRef}>
            <div className="flex mt-5 flex-col  p-5 items-center rounded-lg">
                <label htmlFor="avatarInput" className="cursor-pointer">
                    <Avatar
                        className="shadow-lg rounded-full"
                        size={100}
                        icon={<UserOutlined />}
                        src={imageSource || localStorage.getItem('image') || userImage}
                    />
                    <input
                        type="file"
                        id="avatarInput"
                        accept="image/*"
                        className="hidden"
                        onChange={handleImageChange}
                    />
                </label>
                <h2 className="mt-5 text-lg title-med font-semibold">Change Password</h2>
                <TextInput
                    className="input mt-3 bg-gray-200 w-full p-3 rounded-lg"
                    placeholder="Current Password"
                    onChange={(e) => handleChange('current_password', e.target.value)}
                />
                <TextInput
                    className="input mt-3 bg-gray-200 w-full p-3 rounded-lg"
                    placeholder="New Password"
                    onChange={(e) => handleChange('new_password', e.target.value)}
                />
                <div style={{ width: '30%', fontSize:20, height: 50, margin: 'auto', marginTop: 40 }} className="action d-flex unapproved" onClick={handleSave}>
                    {loading ? 'Updating...' : 'Update'}
                </div>
            </div>
        </form>


    );
};

export default Account;
