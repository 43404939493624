
import React from "react";
import Table from "../tables/Table";
import TableHeadItem from "../tables/TableHead";
import RequestRow from "../tables/RequestRow";
import BookingListRow from "../tables/BookingListRow";
import BookingStatusRow from "../tables/BookingStatusRow";
import { Affix } from "antd"

//import { useGetApprovedVendorsQuery } from "../redux/user";
const BookingStatus=({data})=>{
  const theadData = ["Client", 'Vendor', "Date", "Time", 'Amount Paid',"Total",'Action'];
//    const{ data,isLoading}= useGetApprovedVendorsQuery()
   // console.log(data&&data)
    return (
        <div className="table-head-container">
           <Affix offsetTop={200} className="">
        <div className="table-head">
          {theadData.map((h) => {
            return <TableHeadItem key={h} item={h} />;
          })}
        </div>
        </Affix>
        <div className="reqbody">
        {data.map((item) => {
                    
                    return ( <BookingStatusRow key={item.id} data={item} />);
                })} 
        </div>
      </div>
      
    );
}

export default BookingStatus