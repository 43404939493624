import React from 'react';
import { Pagination } from 'antd';

const CustomPagination = ({ data,show=true ,handlePageChange }) => {
  const customPrevText = <div className="custom-pagination-button">Prev</div>;
  const customNextText = <div className="custom-pagination-button">Next</div>;

  return (
    <>
    { show ? 

      <Pagination
      current={parseInt(data?.current_page)}
      total={parseInt(data?.total)}
      pageSize={parseInt(data?.per_page)}
      onChange={handlePageChange}
      prevIcon={customPrevText}
      nextIcon={customNextText}
      style={{ margin: '20px 0' }}
    />: <></>
    }
    </>
  );
};

export default CustomPagination;
