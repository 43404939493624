import React from "react";
import user from '../assets/images/user.png'

const TotalUsers=({data})=>{
    return(
        <div className="userdetcont">
            <div className="d-flex">
            <img src={user} ></img>
            <div className="user-title">Users</div>
        </div>
        <div className="innertext1">{data}</div>
        <div className="innertext2">Total Users</div>
        </div>

    )
}

export default TotalUsers 