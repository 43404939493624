import React, { useState } from 'react';
import { Drawer, Button, Input, Typography } from 'antd';
import axios from 'axios';
import { backendURL } from '../redux/http';
import { toast } from 'react-toastify';
import Notification from './Notification';
const { Title, Text } = Typography;




const NotiDrawer = ({ open, setOpen, notifications,scope,keys=[] }) => {
 
  



  const handleClose = () => {

    setOpen(false);
  };

 
  return (
    <Drawer width={450} placement="right" closable={false} onClose={handleClose} visible={open}>
     <Notification notifications={ notifications}/>
    </Drawer>
  );
};

export default NotiDrawer;
