import React, { useState, useEffect } from "react";
import TabView from "../components/Tabview";
import Query from "../components/Query";
import VendorRequest from "../routecomponents/vendorequest";
import VendorApproved from "../routecomponents/vendorapproved";
import UserList from "../routecomponents/userlist";
import { useLazyGetallQuery } from "../redux/user"; 
import Payouts from "../routecomponents/serviceslist";
import PayoutList from "../routecomponents/PayoutList";
import PayoutStatus from "../routecomponents/Payoutstatus";
import CustomPagination from "../components/Pagination";
import InfoHeader from "../components/InfoHeader";

function VendorsPayoutPage(){
  const [active, setActive] = useState(0); // Index of the active tab
  const [currentPage, setCurrentPage] = useState(1);
  const [getall,{ data, isLoading, refetch }] = useLazyGetallQuery();
  const [search_param,setSearch] = useState();

  const handleTabChange = (index) => {
    setActive(index);
    setCurrentPage(1); // Reset page when switching tabs
    // Fetch data for the selected tab
    getall({ scope:'payouts', page: currentPage, tab: tabs[index].title });
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // Fetch data whenever the active tab or page changes
   getall({ scope: "payouts", page: currentPage,tab:tabs[active].title,search:search_param });
  }, [refetch,search_param,currentPage]);
  const search=(e)=>{
    console.log(e.target.value,'kk')
    setSearch(e.target.value)
    }
  const tabs=[
    {
        title:'Request',
        content: <PayoutList data={data?data.status:[] }/>
      },
  {
    title:'Payout Status',
    content:  <PayoutStatus data={data?data.status:[]}/>
  },

]
return(
    <><h2 className="update-title title">Payouts</h2>
 
   
   

   <TabView show={active === 3 ? false : true} children={<Query search={search} refetch={()=>handleTabChange(active)} />} tabs={tabs} setActive={handleTabChange} />

   <CustomPagination
         data={data}
         handlePageChange={handlePageChange}
       />
    </>
)
}

export default VendorsPayoutPage