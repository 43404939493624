import "./App.scss";
import "./assets/stylesheets/App.scss"
import LandingPage from "./pages/LandingPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import WaitlistOptionPage from "./pages/WaitlistOptionPage";
import CongratsPage from "./pages/CongratsPage";
import ClientWaitlistPage from "./pages/ClientWaitlistPage";
import PartnersWaitlistPage from "./pages/PartnersWaitlistPage";
import ContactUsPage from "./pages/ContactUsPage";
import AdminLayout from "./layout/AdminLayout";
import PrivateRoute from "./layout/PrivateRoute";
import LoginPage from "./pages/LoginPage";
import Dashboard from "./pages/Dashboard";
import VendorPage from "./pages/VendorPages";
import { useNavigate} from "react-router-dom";
import ReviewsPage from "./pages/ReviewsPage";
import UsersPage from "./pages/UsersPage";
import BookingsPage from "./pages/BookingsPage";
import ServicesPage from "./pages/ServicesPage";
import CouponsPage from "./pages/CouponsPage";
import AlertCont from "./components/ToastContainer";
import SubcriptionPage from "./pages/Subscriptionpage";
import PackagesPage from "./pages/PackagesPage";
import WalletsPage from "./pages/WalletsPage";
import VendorsPayoutPage from "./pages/VendorPayoutPage";
import RefundPage from "./pages/RefundPage";
import ProfileForm from "./pages/user_settings";
import TermsOfUse from "./pages/TermsOfUse";
import Privacy from "./pages/PrivacyPage";
import TermsOfService from "./pages/TermsOfService";
import SendNotification from "./pages/SendNotificationsPage";
import AvailabityPage from "./pages/Availability";
import Gallery from "./pages/GalleryPage";
import EarningsPage from "./pages/EarningsPage";
import Download from "./pages/Download";
import Plans from "./pages/Plans";
import EmployeesPage from "./pages/Employees";
import SettingsPage from "./pages/Settings";
import Reports from "./pages/Reports";
import PartnerTerms from "./pages/PartnerTerms";
import InfluencersPage from "./pages/Influencer";
import CategoryPage from "./pages/CategoryPage";
import HelpCenter from "./pages/HelpCenter";
function App() {
 
  let Authenticated = localStorage.getItem('token') !== null;
     console.log(Authenticated)

  return (
    <><BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/waitlist" element={<WaitlistOptionPage />} />
        <Route path="/help-center" element={<HelpCenter />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/waitlist/clients" element={<ClientWaitlistPage />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/partner-terms" element={<PartnerTerms/>}/>
        <Route path="/download" element={<Download />} />
        <Route path="/plans/:id" element={<Plans />} />
        <Route path="/waitlist/partners" element={<PartnersWaitlistPage />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/congrats" element={<CongratsPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/admin/*"
          element={<PrivateRoute
            element={<AdminLayout>
              <Routes>
                <Route index element={<Dashboard />} />
                <Route path="/earnings" element={<EarningsPage />} />
                <Route path="/influencers" element={<InfluencersPage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/vendors" element={<VendorPage />} />
                <Route path="/categories" element={<CategoryPage />} />
                <Route path="/reviews" element={<ReviewsPage />} />
                <Route path="/users" element={<UsersPage />} />
                <Route path="/bookings" element={<BookingsPage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/coupons" element={<CouponsPage />} />
                <Route path="/subscriptions" element={<SubcriptionPage />} />
                <Route path="/packages" element={<PackagesPage />} />
                <Route path="/wallets" element={<WalletsPage />} />
                <Route path="/vendor_payouts" element={<VendorsPayoutPage />} />
                <Route path="/refunds" element={<RefundPage />} />
                <Route path="/profile" element={<ProfileForm />} />
                <Route path="/employees" element={<EmployeesPage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/send-notifications" element={<SendNotification />} />
              </Routes>
            </AdminLayout>}
            isAuthenticated={Authenticated} />} />
      </Routes>


    </BrowserRouter><AlertCont /></>
  );
}

export default App;
