import React from "react";
import people from "../assets/images/people.png";
import mobile_people from "../assets/images/mobile_people.png";
import { ReactComponent as Pamp } from "../assets/svgs/everyone.svg";
import { ReactComponent as Left } from "../assets/svgs/everyone_tree_left.svg";
import { ReactComponent as Right } from "../assets/svgs/everyone_tree_right.svg";
import { ReactComponent as Dot } from "../assets/svgs/dot.svg";
import { ReactComponent as Cloud } from "../assets/svgs/cloud.svg";
import { ReactComponent as Birds } from "../assets/svgs/birds.svg";

const Everyone = () => {
  return (
    <section className="appBg pt-5 relative">
      <Dot className="everyone-dot-one" />
      <Dot className="everyone-dot-two" />
      <Cloud className="everyone-cloud" />
      <Birds className="everyone-bird" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <img src={people} className="img-fluid everyone-image" alt="" />
          </div>
          <div className="col-12 text-center ">
            <Left className="everyone-tree-left hide-mobile " />
            <Pamp  className="pamp-class mx-auto" /> 
            <Right className="everyone-tree-right hide-mobile" />
          </div>

          <div className="col-12 text-center"></div>
        </div>
      </div>
    </section>
  );
};

export default Everyone;
