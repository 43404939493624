import React, { useState, useEffect } from 'react';
import TabView from "../components/Tabview";
import Query from "../components/Query";
import VendorRequest from "../routecomponents/vendorequest";
import VendorApproved from "../routecomponents/vendorapproved";
import UserList from "../routecomponents/userlist";
import BookingList from "../routecomponents/bookingslist";
import BookingStatus from "../routecomponents/bookingstatus";
import { useGetallQuery } from "../redux/user";
import PackageList from "../routecomponents/packagelist";
import { useLazyGetallQuery } from "../redux/user";
import InfoHeader from "../components/InfoHeader";
import CategoryList from '../routecomponents/Categorylist';
import CreateCategory from './CreateCategory.js';
import CreateCat from './CreateCategory.js';
import CreateUser from './CreateUserPage';



function CategoryPage() {
    const [getCat, { data }] = useLazyGetallQuery();
    const refetch = async () => {
        getCat({ scope: 'list', type: 'categories' })

    }
    console.log(data, 'kk')
    const tabs = [
        {
            title: 'Category List',
            content: <CategoryList refetch={refetch} categories={data}/>
        },
        {
            title: '+Create category',
            content: <CreateCategory refetch={refetch}/>
        },

    ]

   
    useEffect(() => {
        // Fetch data whenever the active tab or page changes
     refetch()
    }, []);

    const [active, setActive] = React.useState();
    return (
        <><h2 className="update-title title">Categories</h2>





            <TabView tabs={tabs} children={<Query />} setActive={setActive} />


        </>
    )
}

export default CategoryPage