import React,{useState,useRef} from 'react';
import { Modal } from 'antd';
import { backendURL } from '../redux/http';
import { toast } from 'react-toastify';
import { Drawer, Avatar } from 'antd';
import axios from 'axios';
import userImage from '../assets/images/userimg.png';
import TextInput from '../components/Input';
import { UserOutlined } from '@ant-design/icons';

    const CategoryList = ({ categories = [],refetch }) => {
        const formRef = useRef(null);
        const imageInputRef = useRef(null);
        const [drawerVisible, setDrawerVisible] = React.useState(false);
        const [selectedCategory, setSelectedCategory] = React.useState(null);
        const [imageSource, setImageSource] = useState(null);
        const [file, setImageFile] = useState()
        const [formInfo, setFormData] = useState({

        });
    
        const handleCategoryClick = (category) => {
            setFormData(category);
            setDrawerVisible(true);
        };

        const handleDrawerClose = () => {
            setDrawerVisible(false);
        };

        const handleImageChange = (e) => {
            const file = e.target.files[0];
            setImageFile(file)

            if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    setImageSource(event.target.result);
                };

                reader.readAsDataURL(file);
            }
        };
        const handleChange = (field, value) => {
            setFormData((prevData) => ({ ...prevData, [field]: value }));

        };




        const handleSave = async () => {
            const loadingToastId = toast.info('Updating category...', {
                position: 'top-right',
                autoClose: false, // Do not auto close loading toast
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
            });
            try {

                const formData = new FormData();

                // Loop through form data fields and append them
                Object.entries(formInfo).forEach(([field, value]) => {
                    // Append user fields with appropriate naming
                    formData.append(field, value);
                });
                // Append the image file
                file && formData.append('img', file);

                // Append additional fields like type and scope
                formData.append('type', 'category'); // Replace with the actual type value
                formData.append('scope', 'create'); // Replace with the actual scope value
               
                // Call the createUser mutation with FormData
                //   const { data } = await createUser(formData);
                const response = await axios.post(backendURL + "/admin/create", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`

                    },
                });
                if (imageInputRef.current) {
                    imageInputRef.current.value = '';
                }

                // Hard reset form
                if (formRef.current) {
                    formRef.current.reset();
                }
                refetch()
                toast.dismiss(loadingToastId);
                console.log(response.data)
                toast.success('Category update successfully!', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                // Handle successful creation
            } catch (error) {
                console.log(error)
                toast.dismiss(loadingToastId);
                toast.error('Something went wrong!', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        };
        return (
            <div className="grid grid-cols-3 gap-4">
                {categories.map((category) => (
                    <div
                        key={category.id}
                        className="bg-white  rounded-lg shadow-md p-4 flex flex-col justify-between"
                        style={{ borderRadius: "20px" }}
                        onClick={() => handleCategoryClick(category)}
                    >
                        <img
                            src={category.image}
                            alt={category.name}
                            style={{ borderRadius: "20px" }}
                            className="w-full h-32 object-cover mb-4"
                        />
                        <h3 className="text-xl title-med font-semibold">{category.name}</h3>
                    </div>
                ))}

                <Drawer
                    visible={drawerVisible}
                    onClose={handleDrawerClose}
                    footer={null}
                >
                    {/* Content of the drawer */}
                    {formInfo && (
                        <><div>
                            <h2 className='title-med'>Edit {formInfo.name}</h2>

                            <h2 className="mt-[50px] title-med">Name</h2>

                            <TextInput
                                className={'flex-1 input'}
                                placeholder={'category name'}
                                value={formInfo?.name}
                                onChange={(e) => handleChange('name', e.target.value)}
                                 />

                        </div><div className="flex-1">
                                <div className='flex flex-col '>
                                    <h2 className="mt-[50px] title-med">Image</h2>

                                    <label htmlFor="avatarInput" className="cursor-pointer">
                                        <Avatar style={{
                                            marginLeft:30,
                                            marginTop:30,
                                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                            borderRadius: '50%',
                                        }} size={100}
                                            icon={<UserOutlined />}
                                            src={imageSource ||formInfo.image || userImage} />
                                        <input
                                            type="file"
                                            id="avatarInput"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={handleImageChange} />
                                    </label>

                                    <div className="flex gap-3 justify-end mt-[100px] mb-[100px]">
                                        <div style={{ width: 100 }} className="action d-flex unapproved" onClick={handleSave}>
                                            Create
                                        </div>
                                        <div style={{ width: 100, background: '#DAECD2', color: '#00463' }} className="action w-[50px] d-flex">
                                            Cancel
                                        </div>
                                    </div>
                                </div>
                            </div></>
                    )}
                </Drawer>
            </div>
        );
    };


export default CategoryList;