import React, { useState, useEffect } from 'react';
import TabView from "../components/Tabview";
import Query from "../components/Query";
import VendorRequest from "../routecomponents/vendorequest";
import VendorApproved from "../routecomponents/vendorapproved";
import UserList from "../routecomponents/userlist";
import CouponList from "../routecomponents/couponslist";
import CustomPagination from "../components/Pagination";
import InfoHeader from "../components/InfoHeader";

import { useLazyGetallQuery } from "../redux/user";
import { Affix } from 'antd';
import CreateCoupon from './CreateCoupon';
import SentMessage from '../routecomponents/SentMessage';
import ScheduledMessage from '../routecomponents/ScheduledMessage';


function SentMessages() {
    const [active, setActive] = useState(0); // Index of the active tab
    const [currentPage, setCurrentPage] = useState(1);
    const [getall, { data, isLoading }] = useLazyGetallQuery();
    const [search_param, setSearch] = useState();

    const handleTabChange = (index) => {
        setActive(index);
        setCurrentPage(1); // Reset page when switching tabs
        // Fetch data for the selected tab
        getall({ scope: 'sent_messages', page: currentPage, tab: tabs[index].key });
    };

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
    };
    const refetch = async () => {
        getall({ scope: "sent_messages", page: currentPage, tab: tabs[active].title, search: search_param });

    }
    useEffect(() => {
        // Fetch data whenever the active tab or page changes
        getall({ scope: "sent_messages", page: currentPage, tab: tabs[active].title, search: search_param });
    }, [search_param, active, currentPage]);
    const search = (e) => {
        console.log(e.target.value, 'kk')
        setSearch(e.target.value)
    }

    const tabs = [
       
        {
            title: 'Scheduled',
            content: <ScheduledMessage refetch={refetch} data={data?.messages ? data?.messages : []} />
        },
        {
            title: 'Sent',
            content: <SentMessage refetch={refetch} data={data?.messages ? data?.messages : []} />
        },

    ]

    const handleScroll = () => {
        const body = document.body;
        body.style.overflow = 'hidden';

        // Remove overflow hidden after a delay to allow for smooth scrolling
        setTimeout(() => {
            body.style.overflow = 'visible';
        }, 500);
    };


    return (
        <>




            <TabView show={active === 3 ? false : true} right tabs={tabs}  top={-120} setActive={setActive} />
            <CustomPagination

                data={data}
                handlePageChange={handlePageChange}
            />
        </>
    )
}

export default SentMessages