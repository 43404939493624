import React, { useState, useEffect } from 'react';
import { Radio, Select, Typography } from 'antd';
import { useGetallQuery } from '../redux/user';
import Checkbox from '../components/Checkbox';
import { useSendnotiMutation } from '../redux/user';
import { Alert } from 'react-bootstrap';
import { useLazyGetallQuery } from "../redux/user";
import html2canvas from 'html2canvas';
import generatePDFFromElement from '../function';
const { Text } = Typography;

const Emails = () => {
    const options = [];
    const [allUsers, setAllUsers] = useState(false);
    const [sendnoti] = useSendnotiMutation()

    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [user_ids, setIds] = useState([]);
    const [getall, { data: users, isLoading, refetch }] = useLazyGetallQuery();

    // Fetch vendors and plans using RTK Query

    useEffect(() => {
       
        // Fetch data whenever the active tab or page changes
        getall({ scope: 'list', type: 'users' });
    }, []);
    const handleSave = async () => {
    alert('Coming soon')

    }

    const handleTitle = (e) => {
        setTitle(e.target.value)
    }
    const handleMessage = (e) => {
        setMessage(e.target.value)
    }

    const handleAllUserChange = () => {
        setAllUsers(!allUsers);
    };

    return (
        <div className="mb-[300px]">


            <h2  className="mt-[50px] title-med">Subject</h2>
            <input
                value={title}
                className="input"
                placeholder="subject"
                onChange={handleTitle}
            />


            <h2 className="mt-[50px] title-med">Body</h2>

            <textarea
                style={{ height: 300, padding: 20 }}
                className="input"
                value={message}
                placeholder="Notification Message"
                onChange={handleMessage}
            />
            <h2 className="mt-[50px] title-med">Users</h2>

            <Select
                showSearch
                disabled={allUsers}
                mode="multiple"
                className="input"
                bordered={false}
                style={{ height: 'fit-content' }}
                placeholder="Select user(s)"
                optionFilterProp="children"
                onChange={(value) => setIds(value)}
            >
                {users?.map((user) => (
                    <Select.Option key={user.id} value={user.id} label={user.username}>
                        {user.username}
                    </Select.Option>
                ))}
            </Select>

            <div className='ml-[100px] mt-[50px]'>
                <Checkbox label={'Send to all users'} onChange={handleAllUserChange} checked={allUsers} />
            </div>

            <div className="flex gap-3 justify-end mt-[100px] mb-[100px]">
                <div style={{ width: 100 }} className="action d-flex unapproved" onClick={handleSave}>
                    {loading ? 'Sending...' : 'Send'}
                </div>
                <div style={{ width: 100, background: '#DAECD2', color: '#00463' }} className="action w-[50px] d-flex">
                    Cancel
                </div>
            </div>
        </div>

    );
};

export default Emails;
