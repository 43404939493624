
import React from "react";
import Table from "../tables/Table";
import TableHeadItem from "../tables/TableHead";
import RequestRow from "../tables/RequestRow";
import BookingListRow from "../tables/BookingListRow";
import PackagesRow from "../tables/PackagesRow";
import { Affix } from "antd"

//import { useGetApprovedVendorsQuery } from "../redux/user";
const PackageList=({data,refetch})=>{
    const theadData = ["Name",'Description', "Price", "Discount", 'Duration',"Disabled",'Action'];
//    const{ data,isLoading}= useGetApprovedVendorsQuery()
   // console.log(data&&data)
    return (
        <div className="table-head-container">
                  <Affix offsetTop={200} className="">

        <div className="table-head">
          {theadData.map((h) => {
            return <TableHeadItem key={h} item={h} />;
          })}
        </div>
               </Affix>
        <div className="reqbody">
        {data.map((item) => {
                    
                    return ( <PackagesRow refetch={refetch}  key={item.id} data={item} />);
                })} 
        </div>
      </div>
      
    );
}

export default PackageList