import TabView from "./Tabview";
import React, { useEffect } from "react";
import UserUpdate from "./UserUpdateCont";
import VendorUpdate from "./VendorUpdate";
import { Empty } from "antd";
import TransferList from "./TransferList";
import TransferStatus from "./TransferStatus";
import { useLazyGetallQuery } from "../redux/user";
import VendorStats from "./VendorStats";
import UserStats from "./UserStats";

function UserVendorStats({  data }) {

   


    const tabs = [{
        title: 'Vendors',
        content: <VendorStats />
    },
    {
        title: 'Users',
        content:<UserStats/>
    }]
    return (
        <><h2 className="update-title mt-5">Individual Stats</h2><TabView tabs={tabs} /></>
    )
}

export default UserVendorStats