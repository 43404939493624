import React, { useState, useEffect } from 'react';
import { Radio, Select, Typography } from 'antd';
import { useGetallQuery } from '../redux/user';
import Checkbox from '../components/Checkbox';
import { useSendnotiMutation } from '../redux/user';
import { useLazyGetallQuery } from "../redux/user";
import TextInput from './Input';
import { DatePicker } from 'antd';
import { Alert } from 'react-bootstrap';
const { Text } = Typography;

const SendNotiVendor = () => {
  const options = [];
  const [allUsers, setAllUsers] = useState(false);
  const [email, setEmail] = useState(false);
  const [push, setPush] = useState(false);
  const [sendnoti] = useSendnotiMutation()
  const [title, setTitle] = useState(null);
  const [time, setTime] = useState()
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user_ids, setIds] = useState([]);
  // Fetch vendors and plans using RTK Query
  const [getall, { data: vendors, isLoading, refetch }] = useLazyGetallQuery();
  const paymentStatusOptions = ['Paid', 'Pending', 'Failed', 'Refunded'];

  useEffect(() => {
    // Fetch data whenever the active tab or page changes
    getall({ scope: 'list', type: 'vendors' });
  }, []);
  const handleSave = async () => {
    setLoading(true);
    console.log(title, message, user_ids)
    if (!title || !message || !user_ids) {
      alert('Please fill all relevant infomation')
      setLoading(false);
      return
    }
    try {


      // Perform API post with the selected data
      const { data } = await sendnoti({
        user_ids,
        title,
        message,
        send_time: time,
        scope: allUsers ? 'all_vendors' : 'vendors',
        ...(email && { email }),
        ...(push && { push })
      });
      console.log(data)
      alert('Notification sent successfully')
      setMessage('')
      setTitle('')
    } catch (error) {
      alert(error.error)
    } finally {
      setLoading(false);
    }

  }
  const handleTitle = (e) => {
    setTitle(e.target.value)
  }
  const handleMessage = (e) => {
    setMessage(e.target.value)
  }

  const handleAllUserChange = () => {
    setAllUsers(!allUsers);
  };


  return (
    <div className="mb-[300px]">


      <h2 className="mt-[50px] title-med">Title</h2>
      <input
        value={title}

        className="input"
        placeholder="Notification Title"
        onChange={handleTitle}
      />


      <h2 className="mt-[50px] title-med">Message</h2>

      <textarea
        value={message}

        style={{ height: 300, padding: 20 }}
        className="input"
        placeholder="Notification Message"
        onChange={handleMessage} />
      <h2 className="mt-[50px] title-med">Vendors</h2>

      <Select
        disabled={allUsers}
        showSearch
        mode="multiple"
        className="input"
        style={{ height: 'fit-content' }}
        bordered={false}
        placeholder="Select vendors(s)"
        optionFilterProp="children"
        onChange={(value) => setIds(value)}
      >


        {vendors?.map((user) => (
          <Select.Option key={user.id} value={user.id} label={user.name}>
            {user.name}
          </Select.Option>
        ))}
      </Select>
      <div className='ml-[30px] flex gap-5 mt-[50px]'>
        <Checkbox label={'Send to all vendors'} onChange={handleAllUserChange} checked={allUsers} />
        <Checkbox label={'Push Notifications'} onChange={() => setPush(!push)} checked={push} />
        <Checkbox label={'Email'} onChange={() => setEmail(!email)} checked={email} />

      </div>
      <h2 className=" mt-[50px]  title-med">Schedule Time</h2>

      <DatePicker
        style={{ paddingTop: 10 }}
        className={'flex-1 pt-[20px] input items-center justify-items-center'}
        popupStyle={{
        
          borderRadius: "10px",
          cursor: "pointer",
          fontSize: "17px",
        
          margin: "1px",
          padding: "1px",


        }}

        format="DD/MM/YYYY hh:mm A"
        onChange={(date, dateString) =>
          setTime(dateString)}
        showTime={{ use12Hours: true }}
      />
      <div className="flex gap-3 justify-end mt-[100px] mb-[100px]">
        <div style={{ width: 100 }} className="action d-flex unapproved" onClick={handleSave}>
          {loading ? 'Sending...' : 'Send'}
        </div>
        <div style={{ width: 100, background: '#DAECD2', color: '#00463' }} className="action w-[50px] d-flex">
          Cancel
        </div>
      </div>
    </div>

  );
};

export default SendNotiVendor;
