import { Avatar } from "antd";
import React from "react";
import { Button, Modal, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import EditDropdown from "./Editmenu";
import axios from "axios";
import { formatDate, timeAgo } from "../function";
import { backendURL } from "../redux/http";
const { confirm } = Modal;

const TransactionRow = ({ data,refetch }) => {
  const showDeleteConfirm = (id) => {
    confirm({
      title: data.wallet_blocked ? 'Unblock Wallet?' : 'Block Wallet?',
      icon: <ExclamationCircleFilled />,
      content: `Are you sure you want to ${data.wallet_blocked ? 'unblock' : 'block'} the wallet?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleApproval(id);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleApproval = async (id) => {
    try {
      // Make API request to block/unblock user
      const response = await axios.post( backendURL+`/admin/vendor/block_wallet/${id}`, {
        // Additional data if needed
       
      });
  refetch()
      // Handle the response as needed
      console.log(response.data);

    } catch (error) {
      // Handle errors
      console.error('API Request Error:', error);
    }
  };

  console.log(data);



  return (
    <div key={data.id} className="tred">
      <div className="flex-item" onClick={() => console.log(data?.vendor.username)}>
        <div className="">{data.vendor?.username}</div>
      </div>
      <div className="flex-item price">¢{data?.amount}</div>
      <div className="flex-item">{data?.id}</div>
      <div className="flex-item"  style={{justifySelf:'center' }}>
        {data.user?.username}
      </div>
      
      <div className="flex-item d-flex">
        <div
          className="d-flex action unapproved"
         
        >Paid
       
        </div>
      </div>
      <div className="flex-item">{timeAgo(data.updated_at)}</div>
    </div>
  );
};

export default TransactionRow;
