import React,{useState,useRef} from "react";
import FileUpload from "./MultiFileUpload";
import { Drawer } from "antd";
import { backendURL } from "../redux/http";
import axios from "axios";
import { toast } from 'react-toastify';
const FileDrawer=({setFileOpen,fileOpen,id,refetch})=>{
    const [selectedFiles, setSelectedFiles] = useState([]);
    const imageInputRef = useRef(null);
    const handleFileChange = (event) => {
        const files = event.target.files;
       setSelectedFiles(Array.from(files));
    };

    const handleSave = async (e) => {
  
          e.preventDefault();
          const loadingToastId = toast.info('Adding document...', {
              position: 'top-right',
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
          });
    
          try {
  
  
            
              const formData = new FormData();
         
         
              // Append files
            
  
              if ( selectedFiles.length > 0) {
                  for (let i = 0; i <  selectedFiles.length; i++) {
                    const image =  selectedFiles[i];
                    formData.append('others[]', image);
                  }
                }
            
      
              // Append additional fields like type and scope
              formData.append('id', id);
              formData.append('scope', 'employee_documents');
              
      
              // Call the createUser mutation with FormData
              const response = await axios.post(backendURL + "/admin/update", formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data',
                      'Authorization':  `Bearer ${localStorage.getItem('token')}`
                  },
              });
                response.data.message==='created' &&refetch()
             // Reset form and dismiss loading toast on success
              if (imageInputRef.current) {
                  imageInputRef.current.value = '';
              }
            
              toast.dismiss(loadingToastId);
      
              // Handle successful creation response
            
              toast.success('Document added successfully!', {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              });
          } catch (error) {
              toast.dismiss(loadingToastId);
             error.response.data.errors.map((err)=>{
              
  
              toast.error(err, {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              });
             })
             
              console.log(error);
          }
      };
      
    
    return(
        <Drawer width={450} placement="right" closable={false} onClose={()=>setFileOpen(!fileOpen)} visible={fileOpen}>

<h2 className="mt-[30px] title-med">Documents</h2>

<FileUpload  handleFileChange={handleFileChange} selectedFiles={selectedFiles}/>



<div style={{ width: 120,marginTop:40 }} className="action d-flex unapproved" onClick={handleSave}>
          + Add Document
          </div>
            </Drawer>

    )
}

export default FileDrawer