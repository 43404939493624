import React, { useState,useEffect ,useRef} from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Input, Radio, Select, Typography ,Avatar,Empty} from 'antd';
import {} from '../redux/user';
import Checkbox from '../components/Checkbox';
import { useAddSubscriptionMutation } from '../redux/user';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import bin from '../assets/svgs/bin.svg'
import edit from '../assets/svgs/edit.svg'
import TextInput from '../components/Input';
import SelectInput from '../components/Select';
import userImage from '../assets/images/userimg.png';
import { useCreateMutation, useLazyGetallQuery } from '../redux/user';
import { backendURL } from '../redux/http';
import axios from 'axios';

const { Text } = Typography;

const CreateServices = () => {
    
    const colors = [
        '#D7AAEE',
        '#11FCDB',
        '#F5F518',
        '#F5B1C9',
        '#B0EBBD',
        '#00CED1',
        '#000000',
        '#DAECD2',
        '#EFEFEF',
        '#D6B986',
        '#F9B015',
        '#F037CB',
      ];
      
      const colorOptions = colors.map((color, index) => ({
        name: color,
        id: index,
      }));
    const [staffs,setStaffs]= React.useState([])
    const [getall,{ data:vendors, isLoading, refetch }] = useLazyGetallQuery();
    const [imageSource, setImageSource] = useState(null);
    const [file,setImageFile]=useState()
    const [treatmentData,setTreatmentData]= useState([])
    const [name, setName] = React.useState();
    const [time, setDuration] = React.useState();
    const [amount, setAmount] = React.useState();
    const [formInfo, setFormData] = useState({
       
      });
      const imageInputRef = useRef(null);
      const formRef = useRef(null);
      async function call(id){
        const res = await axios.get(`${backendURL}/staff/${id}`)
         setStaffs(res.data)
         console.log(res.data,'kk')
       }

  const handleSave = async () => {
  //  await axios.post(`${backendURL}/services/create`,{vendor}).then(response => {
    if (treatmentData.length==0){
        toast.warn('A category must have at least one treatment!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        return
         }
    try {
        const loadingToastId = toast.info('Creating Service...', {
            position: 'top-right',
            autoClose: false, // Do not auto close loading toast
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
          });
          const vendor= {
            name: formInfo.name,
            id:formInfo.id,
            service_for: formInfo.service_for,
            appointment_color: formInfo.appointment_color,
            services: treatmentData
          }
        const {data}=  await axios.post(`${backendURL}/services/create`,{vendor},
        {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization':  `Bearer ${localStorage.getItem('token')}`
          },
        }
        )
   console.log(data)
     
  
      // Hard reset form
      if (formRef.current) {
        formRef.current.reset();
      }
      setTreatmentData([])
      toast.dismiss(loadingToastId);
      
      toast.success('Service created successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      // Handle successful creation
    } catch (error) {
        
        console.log(error)

    }
  };

  const [editIndex, setEditIndex] = useState(null);

  const addTreatment = () => {
    if (editIndex !== null) {
      // If editIndex is not null, update the existing treatment
      const updatedTreatments = [...treatmentData];
      updatedTreatments[editIndex] = { name, time: parseInt(time), price: parseFloat(amount) };
      setTreatmentData(updatedTreatments);
      setEditIndex(null); // Reset editIndex after saving
    } else {
      // Otherwise, add a new treatment
      setTreatmentData([...treatmentData, { name, time: parseInt(time), price: parseFloat(amount) }]);
    }
    // Clear input fields
    setName('');
    setDuration('');
    setAmount('');
  };

  const editTreatment = (index) => {
    // Set the editIndex to enable editing of the selected treatment
    setEditIndex(index);
    // Populate input fields with the details of the selected treatment
    setName(treatmentData[index].name);
    setDuration(treatmentData[index].time.toString());
    setAmount(treatmentData[index].price.toString());
  };

  const removeTreatment = (index) => {
    const updatedTreatments = [...treatmentData];
    updatedTreatments.splice(index, 1);
    setTreatmentData(updatedTreatments);
    setEditIndex(null); // Reset editIndex when removing a treatment
  };

const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
   
  };
  useEffect(() => {
    // Fetch data whenever the active tab or page changes

   getall({scope:'list',type: 'vendors'});
  }, []);
 
  return (
    <form ref={formRef}>
    <div className="flex gap-5">

      <div className="flex-1">
        <h2 className="mt-[50px] title-med">Vendor</h2>
       <div className='gap-5'>

       <SelectInput
          type={'id'}
           className={'input'}
           onChange={(e) =>{ handleChange('id', e);}}
           placeholder={'Select vendor'}
             data={vendors}
             
           />
         
       </div>


        <h2 className="mt-[50px] title-med">Category</h2>
        
        <TextInput
        className={'flex-1 input'}
        placeholder={'Category name'}
        onChange={(e) => handleChange('name', e.target.value)}
         />

        <h2 className="mt-[50px] title-med">Treatment</h2>
        <TextInput
        className={'flex-1 input'}
        placeholder={'Treatment name'}
        onChange={(e) => setName(e.target.value)}
        value={name}

         />
          <div className='flex gap-5'>
           <div className='flex-1 '>
            <h2 className="mt-[50px] title-med">Duration</h2>
                <TextInput
                className={'input'}
                placeholder={'Eg 60'}
                onChange={(e) => setDuration(e.target.value)}
                value={time}
                />
            </div> 
            <div  className='flex-1 '>
              <h2 className="mt-[50px] title-med">Price</h2>
            <TextInput
            className={'input'}
            placeholder={'GHC'}
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            />
            </div>
        </div >
        <div style={{ width: 100 ,marginBottom:30,marginTop:30}} className="action d-flex unapproved" onClick={addTreatment}>
          Add Treatment
          </div>
          <div className="mt-8">
          <h2 className="mt-[50px] title-med">Added Treatments</h2>      
            <ul className='mb-[100px]'>
          {
            treatmentData.length!==0? 
          
          treatmentData.map((treatment, index) => (
            <li key={index} className="flex bg-white p-3 rounded-full bg-white shadow-md p-4 items-center justify-between mb-2">
              <div>
                <span className="font-semibold">{treatment.name}</span> - {treatment.time} mins - GHC {treatment.price}
              </div>
              <div className="flex gap-2">
                <span className="text-blue-600  cursor-pointer" onClick={() => editTreatment(index)}>
                <img  src={edit} style={{ width: 30, height:30, marginLeft: 10 }} />
                </span>
                <span className="text-red-600 cursor-pointer" onClick={() => removeTreatment(index)}>
                <img  width={30} height={20} src={bin}  />

                </span>
              </div>
            </li>
          )): <Empty />}
        </ul>
      </div>
      </div>


      <div className="flex-1">
       
      <h2 className="mt-[50px] title-med">Service available for</h2>
           <SelectInput
           className={'input'}
           onChange={(e) => handleChange('service_for', e)}
           placeholder={'Select group'}
             data={[
                {
                    name:'Everyone',
                    id:1
                },
                {
                    name:'Male',
                    id:2
                },
                {
                    name:'Female',
                    id:3
                }

             ]}
           />

          <h2 className="mt-[50px] title-med">Appointment color</h2>
           <SelectInput
           className={'input'}
           onChange={(e) => handleChange('appointment_color', e)}
           placeholder={'Select Color'}
             data={colorOptions}
             color
           />

        <h2 className="mt-[50px] title-med">Select Staff</h2>
           <SelectInput
           className={'input'}
           onChange={(e) => handleChange('staff', e)}
           placeholder={'Select Staff'}
             data={staffs}
           />

 <div className="flex gap-3 justify-end mt-[100px] mb-[100px]">
          <div style={{ width: 100 }} className="action d-flex unapproved" onClick={handleSave}>
          Create
          </div>
          <div style={{ width: 100, background: '#DAECD2', color: '#00463' }} className="action w-[50px] d-flex">
            Cancel
          </div>
        </div>
      </div>
    </div>
    </form>
  );
};

export default CreateServices;
