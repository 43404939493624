import React, { useState, useRef } from "react";
import FileUpload from "./MultiFileUpload";
import { Drawer } from "antd";
import { backendURL } from "../redux/http";
import axios from "axios";
import TextInput from "./Input";
import { toast } from 'react-toastify';
import { useUpdateVendorMutation } from "../redux/user";
import Checkbox from "./Checkbox";
import PaymentPreference from "./PaymentPref";
const OpeningDrawer = ({ open, SetOpen, id, refetch, data }) => {
    const [updateVendor] = useUpdateVendorMutation()
    const [paymentPrefs, setPaymentPrefs] = useState({})
    const formatTime = (time) => {
        if (!time) {
            return ""; // Return an empty string for undefined or null input
        }

        const [hours, minutes] = time.split(":");
        const parsedHours = parseInt(hours);
        const isPM = parsedHours >= 12;
        const formattedHours = isPM ? parsedHours - 12 : parsedHours;
        const formattedMinutes = !isNaN(minutes) ? parseInt(minutes) : 0;
        const period = isPM ? "pm" : "am";
        return `${isNaN(formattedHours) ? "" : formattedHours}:${formattedMinutes.toString().padStart(2, "0")}${period}`;
    };

    const [hour, setHours] = useState();
    const [timeValues, setTimeValues] = useState(data.hours);
    const [isTimePickerVisible, setTimePickerVisible] = useState(false);
    const [currentPickerIndex, setCurrentPickerIndex] = useState(null);
    const [selectedTimeType, setSelectedTimeType] = useState(""); // "opening" or "closing"
    const [defaultTime, setDefaultTime] = useState()


    const handleBodyPress = (index) => {
        setTimeValues((prevTimeValues) => {
            const newTimeValues = [...prevTimeValues];
            newTimeValues[index] = {
                ...newTimeValues[index],
                isOpen: !newTimeValues[index].isOpen,
            };
            setHours(newTimeValues);
            return newTimeValues;
        });
    };


    const handleTimeChange = (index, field, value) => {

        setTimeValues((prevTimeValues) => {
            const newTimeValues = [...prevTimeValues];
            newTimeValues[index][field] = value;
            return newTimeValues; // Return the updated state
        });
    };



    const formatTimeInput = (time) => {
        if (!time) {
            return "";
        }
        const [hours, minutes] = time.split(":");
        let parsedHours = parseInt(hours);
        const parsedMinutes = parseInt(minutes);

        // Adjust parsedHours if it's greater than or equal to 12
        if (parsedHours >= 12) {
            parsedHours %= 12;
        }

        // Ensure parsedHours is in the correct range (0-23)
        parsedHours %= 24;

        // Return formatted time string
        return `${parsedHours.toString().padStart(2, "0")}:${parsedMinutes.toString().padStart(2, "0")}`;
    };


    const isTimeSet = (opening_time, closing_time) => {
        return opening_time && closing_time;
    };

    const handleCheckBoxChange = (index) => {
        setTimeValues((prevTimeValues) => {
            const newTimeValues = [...prevTimeValues];
            newTimeValues[index] = {
                ...newTimeValues[index],
                opened: !newTimeValues[index].opened,
            };
            setHours(newTimeValues);
            return newTimeValues;
        });
    };

    const handleSave = async () => {
        const loadingToastId = toast.info('Updating vendor...', {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
        });
        const vendor = {
            id: data.id,
            opening_hours: timeValues,
            username: data.username,
            location: {
                name: data.name,
                lat: data.lat,
                lon: data.lon
            },
            paymentPrefs: paymentPrefs,
            description: data.description,
            top_services: data.top_services
        }

        await axios.post(`${backendURL}/vendor/business`, { vendor })
        // await updateVendor({vendor})
        refetch()
        toast.dismiss(loadingToastId);
        toast.success('Updated successfully!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    return (
        <Drawer width={450} placement="right" closable={false} onClose={() => SetOpen(!open)} open={open}>

            <h2 className="mt-[30px] title-med">Availability</h2>

            {timeValues.map((time, index) => (
                <>
                    <div className="flex pl-5 pr-5 pb-2 items-center justify-between gap-5 mt-10">
                        <Checkbox onChange={() => handleCheckBoxChange(index)} label={time.day} checked={time.opened} />
                        <div className="flex gap-5">
                            {isTimeSet(time.opening_time, time.closing_time) ? (
                                <div>{`${formatTime(time.opening_time)} - ${formatTime(time.closing_time)}`}</div>
                            ) : (
                                !time.isOpened && <div>closed</div>
                            )}
                        </div>
                    </div>


                    <div className='flex gap-5'>

                        <TextInput
                            type={'time'}
                            value={time.opening_time}  // Ensure it's bound to timeValues[index].opening_time
                            className={'flex-1 input'}
                            placeholder={'First Name'}
                            onChange={(e) => handleTimeChange(index, 'opening_time', e.target.value)}
                        />
                        <TextInput
                            className={'flex-1 input'}
                            value={time.closing_time}  // Ensure it's bound to timeValues[index].closing_time
                            type={'time'}
                            placeholder={'Last Name'}
                            onChange={(e) => handleTimeChange(index, 'closing_time', e.target.value)}
                        />


                    </div>

                </>

            ))}
            <h2 className="mt-[50px]  title-med">PaymentPreferences</h2>

            <PaymentPreference paymentPrefs={data.paymentPrefs} setPaymentPrefs={setPaymentPrefs} />

            <button onClick={handleSave

            } className='w-[180px] ml-5 text-white h-[40px] mt-5 rounded-[20px] bg-lightgreen'>Update
            </button>
        </Drawer>

    )
}

export default OpeningDrawer