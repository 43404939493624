
import React, { useEffect, useState ,useRef} from "react";
import Menu from "../components/Menu";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import TextInput from "../components/Input";
import SelectInput from "../components/Select";
import { useCreateMutation } from "../redux/user"; 
import { useGetsubQuery} from "../redux/user";
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { usePaystackPayment } from 'react-paystack';

import Avatar from "antd/es/avatar/avatar";
import { distance } from "framer-motion";
import PaystackPayment from "../components/PaystackPayment";
import axios from "axios";
import { backendURL } from "../redux/http";
const Plans=()=>{


const generateReference = () => {
  const timestamp = new Date().getTime();
  return `PD${timestamp}`;
};

  const ref=generateReference()
  const {id}=useParams()
 
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference,'jjj');
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.

  }


    const [show, setShow] = useState(false);
    const [menuShow, setMenuShow] = useState(false);
    let menuBtn;
    const { data:subs,isLoading, refetch, isError} = useGetsubQuery({id});
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
  subs &&  console.log(subs,id)
    const [banks,setBanks]=useState([])
    const formRef = useRef(null);
  const bgSlate= {backgroundColor:'#f7f7f9'}
  const [formInfo, setFormData] = useState({});
  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
   
  };



    useEffect(() => {
      
      menuBtn = document.getElementsByClassName("menu-btn")[0];

      if (isError){

      }
    },[isError]);

    const handleShow = (data) => {
      if (menuBtn) {
        if (data) {
          document.getElementsByTagName("html")[0].style.overflowY = "hidden";
          menuBtn.style.transform = "scale(0.6)";
          menuBtn.classList.add("open");
          setShow(!show);
          setMenuShow(true);
        } else {
          document.getElementsByTagName("html")[0].style.overflowY = "scroll";
          menuBtn.style.transform = "scale(0.5)";
          menuBtn.classList.remove("open");
          setShow(!show);
          setMenuShow(false);
        }
      }
    };



  const df=  subs?.find(item=>item.subscribed==true)
  const currentPlan= subs?.find((item => item.name == formInfo?.plan))
 
  const handleCustomClick = async() => {
   
  if (!formInfo.type|| !formInfo.plan){
                        // Display success toast
   toast.warn('Please fill in all parts required before proceeding', {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,

  })
return 
};



const {data}= await axios.post(backendURL+'/charge',{
  amount: calculate(currentPlan,formInfo?.type)*100,
  vendor_id: id,
  reference: ref,
  plan_type: formInfo?.type,
  plan_name:  formInfo?.plan
})
return true
  }
const calculate=(plan,duration)=>{
  if (!plan || !duration){
    return 0
  }
 let durationChange= {
  monthly:1,
  yearly:12
 }
 console.log(duration)
  let cost = (plan.price * durationChange[duration])-plan.discount
return  cost.toFixed(2)
}




    return(
        <div>
 
     {isError?
     
<div className="mx-3 flex items-center justify-center h-screen">
  <div className="bg-lightgreen p-8 rounded-md shadow-md">
    <h2 className="text-darkgreen text-3xl font-semibold mb-4">Vendor Not Found</h2>
    <p className="text-darkgreen text-lg">
      We couldn't find the vendor you're looking for. Please check the information and try again.
    </p>
    {/* Add any additional information or actions here */}
  </div>
</div>

     
     
     :  <form ref={formRef} onSubmit={(e)=>e.preventDefault()} className='no-scrollbar' >
        <h2 className="update-title text-center mt-5 mb-5 ">Upgrade Plan</h2>
       
        <div className="bg-white p-4 flex flex-col md:w-2/3 lg:w-1/3 md:shadow-lg mb-5 mx-auto  items-center md:justify-center  rounded-[20px]">
        <div className="rounded-5 mb-5 pt-2 pb-2 gap-1 bg-slate px-4 flex w-full" onClick = {()=> console.log('')}>
          <Avatar size={50} src={df?.logo}/>{' '}
          <div>
          <div className="text-darkgreen text-source-sans-pro" style={{fontWeight:'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }} >{df?.username}</div>
          <div className="text-darkgreen text-source-sans-pro">Current plan: <span className="text-lightgreen">{df?.name}</span></div>
          </div>
          </div>
            <SelectInput
            placeholder={'Select new plan'}
            style={bgSlate}
            defaultValue={df?.name}
            data={subs}
            className={'input bg-bg'}
            onChange={(e) => handleChange('plan', e)}
            />
{subs && formInfo?.plan && (
  <div className="bg-darkgreen w-full mt-5 p-3 rounded-4 mb-5 transition-transform duration-300 ease-in-out ">
    <h3 className="text-beige text-1xl md:text-2xl font-semibold pt-4 ml-4 md:ml-12">
    {formInfo?.plan} Plan  Benefits
    </h3>
    <ul className="list-none pt-4 md:ml-4 ml-[-25px] md:pt-12">
      {subs && formInfo?.plan ? (
        subs
          .find((item) => item.name === formInfo?.plan)
          .benefits.split(',')
          .map((item, index) => (
            <li
              key={index}
              className="flex items-center mb-2 md:mb-8 animate-fade-in"
            >
              <div className="rounded-full bg-beige h-3 w-3 md:h-2 md:w-2 mr-3"></div>
              <div className="text-lightgreen text-sm md:text-lg text-source-san-pro">
                {item}
              </div>
            </li>
          ))
      ) : (
        <></>
      )}
    </ul>
  </div>
)}



{formInfo?.type=== "Wallet" ?          


         <SelectInput
            style={bgSlate}
            type={'id'}
            placeholder={'Select vendor'}
            data={[]}
            className={'input bg-bg'}
            onChange={(e) => {
         
              handleChange('vendor_id', e)
            }}
            />:
        <>
        
        <SelectInput
            style={bgSlate}
         
            placeholder={'Select Duration'}
            data={
              [
              {
                name: 'monthly',
              id: 1
            },
            {name: 'yearly',
            id: 2
          }
              ]
            }
            className={'input bg-bg'}
            
            onChange={(e) =>
               {
                handleChange('type', e)
            
               }}
            
            />
       
              
           
                </>
      }
      <div className="bg-white shadow-md text-darkgreen text-source-sans-pro  rounded my-6 overflow-auto">
      <table className="min-w-full leading-normal">
        <thead>
          <tr>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Summary
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-5 py-3 border-b border-gray-200">
              <div className="text-sm">
                <span className="text-gray-900 font-semibold">Plan:</span>
              </div>
            </td>
            <td className="px-5 py-3 border-b border-gray-200">
              <div className="text-sm">{formInfo.plan}</div>
            </td>
          </tr>
          <tr>
            <td className="px-5 py-3 border-b border-gray-200">
              <div className="text-sm">
                <span className="text-gray-900 font-semibold">Duration:</span>
              </div>
            </td>
            <td className="px-5 py-3 border-b border-gray-200">
              <div className="text-sm">{formInfo.type}</div>
            </td>
          </tr>
          <tr>
            <td className="px-5 py-3">
              <div className="text-sm">
                <span className="text-gray-900 font-semibold">Total:</span>
              </div>
            </td>
            <td className="px-5 py-3">
              <div className="text-sm">GH{calculate(currentPlan,formInfo.type)}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
          
  <PaystackPayment
      publicKey="pk_live_cac01fe0c36e8e38e53f9dcae4a3a405fed0e7d1"
      email="user@example.com"
      amount={1} 
      currency="GHS"
      reference={ref}
      onSuccess={onSuccess}
      onClose={onClose}
      button={<button style={{width:150,height:40,fontSize:17 }} className="action d-flex unapproved">Proceed</button>}
      customOnClick={handleCustomClick}

  />

        </div>
        
        </form>}
      

            </div>
    )
}

export default Plans