import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ checked,disabled,showLabel=true, onChange, label }) => {
  const [isChecked, setChecked] = useState(checked || false);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
    onChange && onChange(!isChecked);
  };

  const checkboxId = label;

  return (
    <div className={`custom-checkbox ${isChecked ? 'checked' : ''}`}>
      <input
      disabled={disabled}
        type="checkbox"
        id={checkboxId}
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <label htmlFor={checkboxId}>
        {isChecked && <span className="check-mark">&#10003;</span>}
      </label>
      {label && <span className="checkbox-label font-source-sans-pro text-darkgreen">{showLabel&&label}</span>}
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default Checkbox;
