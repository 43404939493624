import React, { useState } from "react";
import { ReactComponent as Logo } from "../assets/svgs/pamp_logo.svg";
const Navbar = ({ handleShow, show }) => {
  return (
    <nav className="appBg pl-20">
      <div className="container navbar ">
        <a className="z-[10]" href="/">
          <Logo />
        </a>
        {/* <Menu className="" /> */}
        <div
          onClick={() => handleShow(!show)}
          className="menu-btn"
        >
          <div className="menu-btn__burger"></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
