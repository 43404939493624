import React from 'react';

const DocumentRenderer = ({ documents }) => {
    return (
        <div className="flex flex-wrap gap-3">
            
            {documents.map((document, index) => (
                <div key={index} className="w-[200px]">
                    <div className="bg-white rounded-[20px]  p-4">
                        <p className="text-lg font-semibold mb-2">Document {index + 1}</p>
                        <div className="overflow-hidden rounded-lg mb-4">
                        </div>
                        <a style={{textDecoration:'none'}} href={document} target="_blank" rel="noopener noreferrer" className="block text-center bg-lightgreen decoration-none text-white px-4 py-2 rounded-[20px] outline-none ">View Document</a>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DocumentRenderer;
