import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Ghana } from "../assets/svgs/ghana.svg";

const PartnerForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [cat, setCat] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    onValidated({
      MERGE1: firstName,
      MERGE2: lastName,
      MERGE3: name,
      MERGE0: email,
      MERGE4: phone,
      MERGE5: cat,
    });
  };

  useEffect(() => {
    if (status === "success") {
      navigate("/congrats");
    }
  }, [status]);
  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      className="partner-form"
      // className="w-[80%] partner-form mx-auto relative bg-white rounded-2xl p-7 mt-10"
    >
      <h3 className="form-header">
        Enter your details
      </h3>
      {status === "error" && (
        <div
          className="mc__alert mc__alert--error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      <div className="fn-ln-wrapper">
        <div className="fn-ln">
          <input
            type="text"
            onChange={(e) => setFirstName(e.target.value)}
            className="input"
            placeholder="First Name"
            required
          />
        </div>
        <div className="fn-ln">
          <input
            type="text"
            onChange={(e) => setLastName(e.target.value)}
            className="input"
            placeholder="Last Name"
            required
          />
        </div>
      </div>
      <input
        type="text"
        onChange={(e) => setName(e.target.value)}
        className="input"
        placeholder="Business Name"
        required
      />
      <input
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        className="input"
        placeholder="Business Email"
        required
      />
      <div className="relative">
        <Ghana className="ghana-svg" />
        <input
          type="tel"
          onChange={(e) => setPhone(e.target.value)}
          className="input pl-10"
          placeholder="Phone Number"
          required
        />
      </div>
      <input
        type="text"
        onChange={(e) => setCat(e.target.value)}
        className="input"
        placeholder="Category? Hair salon, Beauty salon, Nail salon?"
        required
      />
      <div className="partner-button-wrapper">
        <button
          type="submit"
          className="partner-button"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default PartnerForm;
