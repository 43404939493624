import React from 'react';
import { Line } from 'react-chartjs-2';
import SelectDropdown from './SelectDropDown';

const LineChart = ({ data, name, onChange }) => {

    return (
        <div className='p-[20px]'>
            <div style={{ marginTop: -20 }} className='flex  items-center justify-between'>
                {/* Select dropdown */}
                <h2 className='text-lightgreen'>{name}</h2>
                <SelectDropdown onChange={onChange} />
            </div>
            <div>
                <Line data={data} />
            </div>

        </div>
    );
};

export default LineChart;
