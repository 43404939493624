import React from "react";


const TotalBookings=({data})=>{
    return(<div className="userdetcont"> 
      <div  className="small-cont1">Bookings</div>
        <div className="small-cont2">{data}</div>
        <div className="small-cont3" style={{color:"white"}}>Total Bookings</div>
    </div>)
}

export default TotalBookings 