import React, { useState, useEffect } from "react";
import { notification, List, Avatar } from 'antd';
import { BellOutlined, } from '@ant-design/icons';
import { useGetnotiQuery } from "../redux/user";
import { timeAgo } from "../function";
import { backendURL } from "../redux/http";
import axios from "axios";

const Notification = ({ notifications}) => {
 // const [notifications, setNotifications] = useState([]);
 
 const user = JSON.parse(localStorage.getItem('user'));
 const userId = user ? user.id : null;

 useEffect(() => {
    const delay = setTimeout(() => {
      // Make your API request here after 3 seconds
      axios.patch(backendURL+'/mark_all_as_read', { id:userId, scope:'pamp_member'})
        .then(response => {
          // Handle the response if needed
          console.log('API request successful:', response.data);
        })
        .catch(error => {
          // Handle errors
          console.error('Error making API request:', error);
        });
    }, 3000);

    // Cleanup the timeout to avoid making the request if the component unmounts
    return () => clearTimeout(delay);
  }, []); // Empty dependency array to run the effect once on mount


  const openNotification = (noti) => {
    notification.info({
      message: 'Notification',
      description: noti.text,
      placement: 'bottomRight',
    });
  };

  return (
    <div style={{}}>
        <div style={{paddingInline:30,fontWeight:700, paddingTop:20}}>
            <div className="font-source-sans-pro text-darkgreen text-[20px] text-bold">Notifications</div>
        </div>
     <List
        dataSource={notifications}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            onClick={() => openNotification(item)}
            style={{ cursor: 'pointer',borderBottomColor:'#B0EBBD', }}
          >
            <List.Item.Meta
               style={{paddingInline:30,}}
               avatar={!item.read?<div className="mt-[6px] rounded-full w-[10px] h-[10px] bg-lightgreen"></div>:<></>}
              title={
                <div>
                   <div style={{ fontWeight: 700 }} className="text-source-sans-pro text-darkgreen  text-[15px]">{`${item.title}`}</div>
                  <div className="text-source-sans-pro text-darkgreen  text-[15px]">{item.body}</div>
                </div>
              
            
            }
              description={<div className="text-source-sans-pro text-[12px]">{timeAgo(item.created_at)}</div>}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default Notification;
