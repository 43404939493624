import { Avatar } from "antd";
import React from "react";
import bin from '../assets/svgs/bin.svg'
import edit from '../assets/svgs/edit.svg'

import { Button, Modal, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import InfoDrawer from "../components/InfoDrawer";
import EditDropdown from "./Editmenu";
import { timeAgo } from "../function";
import EditPackage from "../pages/EditPackage";
const { confirm } = Modal;
const PackagesRow= ({ data ,refetch}) => {

  const [open, setOpen] = React.useState(false);
    console.log(data);

    
    const showDeleteConfirm = (id) => {
        confirm({
          title: 'Are you sure delete this task?',
          icon: <ExclamationCircleFilled />,
          content: 'Some descriptions',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk() {
            alert(id)
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };
    return (
       <div key={data.id}   className="tred2 height" 
     > 
      

      <div className="flex-item" onClick = {()=> console.log(data.id)}>
       
          <div className="" >{data.name}</div>
          </div>  
         <div className="flex-item">{data.benefits.slice(0,60)}...</div>
        <div className="flex-item price">¢{data.price}</div>
          <div className="flex-item price">{data.discount||0}</div>
        <div className="flex-item">{'Monthly-Yearly'}</div>
        <div className="flex-item"><div className="no">{"No"}</div></div>
        <div className="flex-item d-flex edit">
        <img onClick={()=>showDeleteConfirm(data.id)} src={bin}  />
        <img onClick={()=>setOpen(!open)}  src={edit} style={{ width: 30, marginLeft: 10 }} />
        </div> 
        <EditPackage isOpen={open} setOpen={setOpen}  data={data} refetch={refetch}/>  
        </div>
    );
};

export default PackagesRow ;