import React, { useState, useEffect } from "react";
import CheckBox from "./Checkbox";
import moment from "moment";
const DaysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const formatTime = (time) => {
    if (!time) {
        return ""; // Return an empty string for undefined or null input
    }

    const [hours, minutes] = time.split(":");
    const parsedHours = parseInt(hours);
    const isPM = parsedHours >= 12;
    const formattedHours = isPM ? parsedHours - 12 : parsedHours;
    const formattedMinutes = !isNaN(minutes) ? parseInt(minutes) : 0;
    const period = isPM ? "pm" : "am";
    return `${isNaN(formattedHours) ? "" : formattedHours}:${formattedMinutes.toString().padStart(2, "0")}${period}`;
};

const Hours = () => {
    const items = [
        {
            "id": 1,
            "day": "Monday",
            "opening_time": "8:00",
            "closing_time": "18:00",
            "created_at": "2023-06-18T01:19:35.547Z",
            "updated_at": "2024-01-28T11:33:55.762Z",
            "vendor_id": 1,
            opened: false,
            "isOpened": false
        },
        // Other days omitted for brevity
    ];
    const [hour, setHours] = useState();


    const [timeValues, setTimeValues] = useState([]);
    const [isTimePickerVisible, setTimePickerVisible] = useState(false);
    const [currentPickerIndex, setCurrentPickerIndex] = useState(null);
    const [selectedTimeType, setSelectedTimeType] = useState(""); // "opening" or "closing"
    const [defaultTime, setDefaultTime] = useState()
    React.useEffect(() => {
        // Set initial time values
        setTimeValues(items);

        // Order time values from Monday to Sunday
        setTimeValues((prevTimeValues) => {
            return prevTimeValues.sort((a, b) => {
                const daysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
                return daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day);
            });
        });
    }, [items]);

    const handleBodyPress = (index) => {
        setTimeValues((prevTimeValues) => {
            const newTimeValues = [...prevTimeValues];
            newTimeValues[index] = {
                ...newTimeValues[index],
                isOpen: !newTimeValues[index].isOpen,
            };
            setHours(newTimeValues);
            return newTimeValues;
        });
    };

    const handleCheckBoxChange = (index) => {
        setTimeValues((prevTimeValues) => {
            const newTimeValues = [...prevTimeValues];
            newTimeValues[index] = {
                ...newTimeValues[index],
                opened: !newTimeValues[index].opened,
            };
            setHours(newTimeValues);
            return newTimeValues;
        });
    };

    const handleTimeChange = (index, field, value) => {
        setTimeValues((prevTimeValues) => {
            const newTimeValues = [...prevTimeValues];
            newTimeValues[index][field] = value;
            setHours(newTimeValues);
            return newTimeValues;
        });
    };

    const showTimePicker = (index, type) => {
        setCurrentPickerIndex(index);
        setSelectedTimeType(type);
        const defaultTime = type === "opening" ? timeValues[index].opening_time : timeValues[index].closing_time;
        setTimePickerVisible(true);
        setDefaultTime(defaultTime);
    };

    const hideTimePicker = () => {
        setCurrentPickerIndex(null);
        setSelectedTimeType("");
        setTimePickerVisible(false);
    };

    const handleTimePickerConfirm = (selectedDate) => {
        if (currentPickerIndex !== null) {
            const selectedTime = moment(selectedDate).format("HH:mm");
            const timeField = selectedTimeType === "opening" ? "opening_time" : "closing_time";
            handleTimeChange(currentPickerIndex, timeField, selectedTime);
            hideTimePicker();
        }
    };

    const formatTimeInput = (input) => {
        if (!input) {
            return "";
        }
        return input;
    };
    const isTimeSet = (opening_time, closing_time) => {
        return opening_time && closing_time;
    };
    return (
        <>
            {timeValues.map((time, index) => (
                <div className="main" key={index}>
                    <div className="cont" onClick={() => handleBodyPress(index)}>
                        <div>
                            <CheckBox label={time.day} checked={time.opened} onChange={() => handleCheckBoxChange(index)} />
                        </div>
                        {time.isOpen ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div className="text">Opens at</div>
                                <div>
                                    <input
                                        type="time"
                                        value={time.opening_time || ""}
                                        onChange={(e) => handleTimeChange(index, "opening_time", e.target.value)}
                                    />
                                </div>
                                <div className="text">Closes at</div>
                                <div>
                                    <input
                                        type="time"
                                        value={time.closing_time || ""}
                                        onChange={(e) => handleTimeChange(index, "closing_time", e.target.value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="text2">Closed</div>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
};

export default Hours;
