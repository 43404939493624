import React from 'react';

const SelectDropdown = ({  onChange }) => {
    const options = [
        { value: 'this_year', label: 'yearly' },
        { value: 'this_month', label: 'monthly' },
     
    ];
    return (
        <select
            onChange={onChange}
            className=" my-4 p-2 h-[25x] rounded-[10px] border border-lightgreen"
            style={{ borderRadius: '20px', padding: '0.5rem 1rem', fontSize: '1rem', backgroundColor: '#fff', color: '#333', border: '2px solid #ccc' }}
        >
            {options.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
            ))}
        </select>
    );
};

export default SelectDropdown;
