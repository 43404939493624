import { Avatar } from "antd";
import React from "react";
import bin from '../assets/svgs/bin.svg'
import edit from '../assets/svgs/edit.svg'
import { formatDate } from "../function";
import { Button, Modal, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import EditDropdown from "./Editmenu";
import { timeAgo } from "../function";
const { confirm } = Modal;
const PayoutStatusRow = ({ data }) => {


  console.log(data);


  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure delete this task?',
      icon: <ExclamationCircleFilled />,
      content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        alert(id)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  return (
    <div key={data.id} className="tred2"
    >
      <div className="flex-item" onClick={() => console.log(data.id)}>
        <div className="" >{data?.withdrawable?.username || 'unknown'}</div>
      </div>
      <div className="flex-item">{data?.amount}</div>
      <div className="flex-item">{data?.method}</div>
      <div className="flex-item">{data?.reference}</div>
      <div className="flex-item">{formatDate(data.created_at)}</div>
      <div className="flex-item">{data?.status}</div>
      <div className="flex-item d-flex edit">
        <img onClick={() => showDeleteConfirm(data.id)} src={bin} />
        <img src={edit} style={{ width: 30, marginLeft: 10 }} />
      </div>

    </div>
  );
};

export default PayoutStatusRow;
