import React, { useState,useEffect ,useRef} from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Input, Radio, Select, Typography ,Avatar} from 'antd';
import { useGetallQuery } from '../redux/user';
import Checkbox from '../components/Checkbox';
import { useAddSubscriptionMutation } from '../redux/user';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useLazyGetallQuery } from "../redux/user"; 
import TextInput from '../components/Input';
import SelectInput from '../components/Select';
import userImage from '../assets/images/userimg.png';
import { useCreateMutation,useLazyBankQuery } from '../redux/user';
import { backendURL } from '../redux/http';
import axios from 'axios';
import FileUpload from '../components/MultiFileUpload';
const { Text } = Typography;

const CreateEmployee = () => {
      const [createUser]=useCreateMutation()
      const [loading, setLoading] = useState(false);
      const [name, setName] = useState('');
      const [getbanks]=useLazyBankQuery()
      const [banks,setBanks]=useState([])
    const [imageSource, setImageSource] = useState(null);
    const [file,setImageFile]=useState()
    const [formInfo, setFormData] = useState({
      password: 'pamp2024'
      });
      const imageInputRef = useRef(null);
      const formRef = useRef(null);
      const [selectedFiles, setSelectedFiles] = useState([]);

      const handleFileChange = (event) => {
        const files = event.target.files;
       setSelectedFiles(Array.from(files));
    };
    const handleSave = async (e) => {
      const bank_name=getBname(formInfo?.bank_code)
      setFormData((prevData) => ({ ...prevData, bank_name: bank_name}));

        e.preventDefault();
        const loadingToastId = toast.info('Creating user...', {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
        });
  
        try {


          
            const formData = new FormData();
       
            // Append user fields
            Object.entries(formInfo).forEach(([field, value]) => {
                formData.append(`pamp_member[${field}]`, value);
            });
    
            // Append email confirmation if true
            if (formInfo.email_confirmed) {
                formData.append('email_confirmed', formInfo.email_confirmed);
            }
    
            // Append files
          

            if ( selectedFiles.length > 0) {
                for (let i = 0; i <  selectedFiles.length; i++) {
                  const image =  selectedFiles[i];
                  formData.append('others[]', image);
                }
              }
          
    
            // Append additional fields like type and scope
            formData.append('type', 'pampMember');
            formData.append('scope', 'create');
            
            console.log(formInfo, formData); // Log FormData here
    
            // Call the createUser mutation with FormData
            const response = await axios.post(backendURL + "/admin/create", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization':  `Bearer ${localStorage.getItem('token')}`
                },
            });
    
           // Reset form and dismiss loading toast on success
            if (imageInputRef.current) {
                imageInputRef.current.value = '';
            }
            if (formRef.current) {
                formRef.current.reset();
            }
            toast.dismiss(loadingToastId);
    
            // Handle successful creation response
          
            toast.success('Employee created successfully!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } catch (error) {
            toast.dismiss(loadingToastId);
           error.response.data.errors.map((err)=>{
            

            toast.error(err, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
           })
           
            console.log(error);
        }
    };
    
  

const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
   
  };

  const getBanks=async()=>{
    try{
  
      const  {data} = await getbanks({scope:'list'})
      setBanks(data.bank_data)
   console.log(data.bank_data)
    }catch(e){
      console.log(e)
    }
  
  }


  const getBname=(code)=>{
    const ban= banks.find((b)=>b.code===code)

   
    return ban?.name
    

  }


  const getDetails=async()=>{
    try{
   
      const  {data} = await getbanks({scope:'details',account_number:formInfo?.account_number,bank_code:formInfo?.bank_code})
       setName(data.bank_name)
    
    }catch(e){
        setName('')
      toast.error('Account not found', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  
  }

  useEffect(() => {
    // Fetch data whenever the active tab or page changes
    getBanks()

  }, [formInfo]);
  React.useEffect(() => {
 console.log(selectedFiles)
  }, [selectedFiles]);
  return (
    <form ref={formRef}>
    <div className="flex gap-5">

      <div className="flex-1">
        <h2 className="mt-[50px] title-med">Name</h2>
       <div className='flex gap-5 p-[0px]'>

         <TextInput
         className={'flex-1 input'}
        
         placeholder={'First Name'}
         onChange={(e) => handleChange('first_name', e.target.value)}
         
         />
          <TextInput
        className={'flex-1 input'}
        placeholder={'Last Name'}
        onChange={(e) => handleChange('last_name', e.target.value)}

         />
       </div>
       <h2 className="mt-[50px] title-med">Role</h2>
           <SelectInput
           className={'input'}
           onChange={(e) => handleChange('role', e)}

           placeholder={'Select Role'}
             data={[
                {
                name: 'admin',
                id:1
               },
               { id:2,
                name: 'user'
               },
               { id:3,
                name: 'employee'
               },
               { id:4,
                name: 'other'
               },
            ]}
           />
      

        <h2 className="mt-[50px] title-med">Email</h2>
        
        <TextInput
        className={'flex-1 input'}
        placeholder={'Email Address'}
        onChange={(e) => handleChange('email', e.target.value)}
        type={'email'}
         />
        <h2 className="mt-[50px] title-med">Phone Number</h2>
        <TextInput
        className={'flex-1 input'}
        placeholder={'Phone'}
        onChange={(e) => handleChange('phone', e.target.value)}

         />
         <h2 className="mt-[50px] title-med">DOB</h2>
        <TextInput
        className={'flex-1 input'}
        placeholder={'01/10/2024'}
        type={'date'}
       
        onChange={(e) => handleChange('date_of_birth', e.target.value)}

         />
      
           <h2 className="mt-[50px] title-med">Gender</h2>
           <SelectInput
           className={'input'}
           onChange={(e) => handleChange('gender', e)}
           placeholder={'Select Gender'}
             data={[
                {
                name: 'Male',
                id:1
               },
               { id:2,
                name: 'Female'
               },
            ]}
           />

        <h2 className="mt-[50px] title-med">Address</h2>
        <TextInput
        className={'flex-1 input'}
        placeholder={'Insert location'}
        onChange={(e) => handleChange('address', e.target.value)}

         />
      </div>
      <div className="flex-1">
<div className='mb-[65px]'>
<h2 className="mt-[50px]  title-med">Job Title</h2>
        <TextInput
    
        className={'flex-1 mb-[10px] input'}
        placeholder={'Insert Title'}
        onChange={(e) => handleChange('job_title', e.target.value)}

        
         />
</div>
        
         <h2 className=" mt-[50px]  title-med">Salary</h2>
        <TextInput
    type={'number'}
    className={'flex-1 input'}
        placeholder={'Insert Salary'}
        onChange={(e) => handleChange('salary', e.target.value)}

        
         />
         <h2 className="mt-[50px] title-med">Bank</h2>
           <SelectInput
           className={'input'}
           type={'code'}
           onChange={(e) =>{
            handleChange("bank_name",getBname(e))
            handleChange('bank_code', e)
           }
            
         }
                        
           placeholder={'Select bank'}            
            data={banks}
           />

<h2 className=" mt-[50px]  title-med">Account Number</h2>
        <TextInput
        onBlur={getDetails}
    type={'number'}
    className={'flex-1 input'}
        placeholder={'Account Number'}
        onChange={(e) => 
            
            handleChange('account_number', e.target.value)
        }

        
         />




<h2 className=" mt-[50px]  title-med">Account Name</h2>
        <TextInput

className={'flex-1 input'}
        placeholder={'Account Name'}
       value={name}
         />


              
<h2 className=" mt-[50px]  title-med">Schedule Delivery</h2>
        <TextInput
        type='date'
        className={'flex-1 input'}
        placeholder={'Account Name'}
        onChange={(e) => 
            
            handleChange('start_date', e.target.value)
        }
         />

      </div>

      
      
    </div>

    <h2 className="mt-[50px]  title-med">Avatar</h2>
    
<FileUpload handleFileChange={handleFileChange} selectedFiles={selectedFiles}/>


        <div className="flex gap-3 justify-end mt-[100px] mb-[100px]">
          <div style={{ width: 100 }} className="action d-flex unapproved" onClick={handleSave}>
          Create
          </div>
          <div style={{ width: 100, background: '#DAECD2', color: '#00463' }} className="action w-[50px] d-flex">
            Cancel
          </div>
        </div>
    </form>
  );
};

export default CreateEmployee;
