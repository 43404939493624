import React from 'react';
import { List } from 'antd';

const UserProfile = ({ user }) => {
    const data = [
        { title: 'Email', value: user.email },
        { title: 'Phone', value: user.phone },
        { title: 'Role', value: user.role || 'user' },
        { title: 'Address', value: user.address || 'nil' },
        { title: 'Gender', value: user.gender || 'nil' },
        { title: 'Date of Birth', value: user.date_of_birth ? new Date(user.date_of_birth).toLocaleDateString() : 'nil' },
        { title: 'Joined', value: new Date(user.created_at).toLocaleDateString() },
        { title: 'Facebook Handle', value: user.facebook_handle ? <a href={`https://${user.facebook_handle}`} target="_blank" rel="noopener noreferrer">{user.facebook_handle}</a> : 'nil' },
        { title: 'Instagram Handle', value: user.instagram_handle ? <a href={`https://${user.instagram_handle}`} target="_blank" rel="">{user.instagram_handle}</a> : 'nil' },
        { title: 'TikTok Handle', value: user.tiktok_handle ? <a href={`https://${user.tiktok_handle}`} target="_blank" rel="noopener noreferrer">{user.tiktok_handle}</a> : 'nil' },
        { title: 'YouTube Handle', value: user.youtube_handle ? <a href={`https://${user.youtube_handle}`} target="_blank" rel="noopener noreferrer">{user.youtube_handle}</a> : 'nil' },
        { title: 'Influencer Approved', value: user.influencer_approved ? 'Yes' : 'No' },
        { title: 'Influencer Requested', value: user.influencer_requested ? 'Yes' : 'No' },
        { title: 'Account Name', value: user.account_name || 'nil' },
        { title: 'Account Number', value: user.account_number || 'nil' },
        { title: 'Balance', value: user.balance ? `$${user.balance}` : 'nil' },
        { title: 'Bank Code', value: user.bank_code || 'nil' },
        { title: 'Bank Name', value: user.bank_name || 'nil' },
    ];

    return (
        <div className="container mx-auto p-6 bg-white rounded-lg">
            <div className="flex items-center mb-6">
                <img
                    src={user.image || require('../assets/images/userimg.png')}
                    alt="User Avatar"
                    className="w-24 h-24 rounded-full shadow-md"
                />
                <div className="ml-6">
                    <h2 className="text-2xl text-darkgreen font-bold">{user.username + " " + user.name}</h2>
                    <p className="text-gray-600">@{user.username}</p>
                    <p className="text-gray-600">{user.nick_name || 'No nickname provided'}</p>
                </div>
            </div>
            <div className="text-darkgreen grid grid-cols-2 gap-6">
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={<span className="text-lg font-semibold">{item.title}</span>}
                                description={<span className="font-normal">{item.value}</span>}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
};

export default UserProfile;
