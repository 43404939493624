import { Select } from "antd"

const SelectInput=({data=[], style,mode,defaultValue,type,className,disabled,onChange,placeholder,color})=>{
    return(
        <Select
     style={style}
        defaultValue={defaultValue}
        showSearch
        disabled={disabled}
        mode={mode&&mode}
        className={className}
        bordered={false}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={onChange}
      >

        {color?data?.map((option) => (
  <Select.Option
  key={option.id}
  value={type=='id'?option.id:option.name}
     
    className={`w-full h-8 mb-3 rounded-full mr-2 cursor-pointer`}
    style={{ backgroundColor: option.name }}
  ></Select.Option>
)) :data?.map((item) => (
          <Select.Option key={item.id} value={type=='id'?item.id: type=='code'? item.code:item.name} label={item.name}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    )
}

export default SelectInput