import { Avatar } from "antd";
import React from "react";
import bin from '../assets/svgs/bin.svg'
import edit from '../assets/svgs/edit.svg'
import { Button, Modal, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import InfoDrawer from "../components/InfoDrawer";
import EditDropdown from "./Editmenu";
import { timeAgo } from "../function";
const { confirm } = Modal;
const AvailListRow = ({ data }) => {

  const [open, setOpen] = React.useState(false);
    console.log(data);

    
    const showDeleteConfirm = (id) => {
        confirm({
          title: 'Are you sure delete this task?',
          icon: <ExclamationCircleFilled />,
          content: 'Some descriptions',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk() {
            alert(id)
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };
    return (
       <div key={data.id}   className="tred"> 
      
       
          <div className="flex-item w-100" style={{ whiteSpace: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>{data.vendor.username}</div>

        <div className="flex-item">{data.day}</div>
        <div className="flex-item">{data.opening_time}</div>
          <div className="flex-item">{data.closing_time}</div>
        <div className="flex-item">{timeAgo(data.updated_at)}</div>
        <div className="flex-item d-flex edit">
        <img onClick={()=>showDeleteConfirm(data.id)} src={bin}  />
        <img onClick={()=>setOpen(!open)} src={edit} style={{ width: 30, marginLeft: 10 }} />
        </div>   
        <InfoDrawer keys={['opening_time',"closing_time",,'day',"opened"]} scope={"OpeningHour"} userData={data} open={open} setOpen={setOpen}/>
    
        </div>
    );
};
export default AvailListRow ;